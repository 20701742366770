import { Box, Button, Grid, IconButton, InputLabel, TextField } from '@material-ui/core';

import {
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
  PlusIconTwoTone,
  TrashErrorIconTwoTone,
} from '@room-match/shared-ui-components';

import { ContactInput } from 'shared/elements';
import { useStyles } from './Industry.styles';

const Industry = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Add 3 Industry Reference</Typography>
      </Grid>
      <Grid item className={classes.form}>
        {Array.from({ length: 3 }, () => (
          <Grid item className={classes.oneRow}>
            <Grid item className={classes.oneRowField}>
              <InputLabel id="representationStatus">Type</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[
                  { key: 'Casting', value: 'casting' },
                  // { key: 'Non-Represented', value: 'Non-Represented' },
                ]}
                IconComponent={SelectArrowDownIcon}
                name="representation"
              />
            </Grid>
            <Grid item className={classes.oneRowField}>
              <TextInput label={'Company Name'} placeholder="Enter Company Name..." />
            </Grid>
            <Grid item className={classes.oneRowField}>
              <TextInput label={'Contact Person'} placeholder="Enter Contact Person..." />
            </Grid>
            <Grid item className={classes.oneRowField}>
              <TextInput label={'Email (messaging)'} placeholder="Enter Email Address..." />
            </Grid>
            <Grid item>
              <InputLabel id="representationStatus" style={{ marginBottom: '-9px' }}>
                Phone (messaging)
              </InputLabel>
              <ContactInput
                country={'us'}
                name="contact_no"
                variant="outlined"
                placeholder="Type here..."
                dropdownStyle={{ height: '50px' }}
                showLabel={false}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Industry;
