import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ClockIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="kvagndugba">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M9.703 4.852v3.857l2.56 2.56a.703.703 0 1 1-.995.994L8.503 9.497A.703.703 0 0 1 8.297 9V4.852a.703.703 0 1 1 1.406 0zM11.766 0H6.234a6.213 6.213 0 0 0-4.088 1.529V.703a.703.703 0 1 0-1.406 0V3.47c0 .388.318.703.703.703H4.21a.703.703 0 1 0 0-1.406h-1.33a4.845 4.845 0 0 1 3.355-1.36h5.532a4.834 4.834 0 0 1 4.828 4.828v5.532a4.834 4.834 0 0 1-4.828 4.828H6.234a4.834 4.834 0 0 1-4.828-4.828V9A.703.703 0 1 0 0 9v2.766A6.241 6.241 0 0 0 6.234 18h5.532A6.241 6.241 0 0 0 18 11.766V6.234A6.241 6.241 0 0 0 11.766 0z"
        fill="url(#kvagndugba)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ClockIconTwoTone;
