import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    expandedDiv: {
      backgroundColor: '#0e1319',
      padding: theme.spacing(2),
      border: '1px solid #2d343e',
      borderRadius: 8,
    },
    expandedDiv__leftContainer: {},
    expandedDiv__videoPlayerContainer: {
      height: 550,
      width: '100%',
    },
    expandedDiv__sceneActionContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    expandedDiv__actionContainer: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
    },
    rightContainer: {},
    expandedDiv__talentContainer: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
    expandedDiv__imageContainer: {
      width: 150,
      height: 80,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    expandedDiv__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    expandedDiv__detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    expandedDiv__iconsContainer: {
      display: 'flex',
      gap: 12,
    },
    expandedDiv__iconItem: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    expandedDiv__iconItem__hidden: {
      opacity: 0.4,
    },
    expandedDiv__icon: {
      fontSize: 16,
    },
    expandedDiv__commentSectionContainer: {
      marginTop: theme.spacing(4),
    },
    expandedDiv__commentSection__content: {},
    buttonIcon: {
      fontSize: '16px !important',
    },
    actionButton: {
      backgroundColor: '#1b232d',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.customBlack.dark,
      },
    },
  }),
);
