import { Box, Button, Grid, IconButton, InputLabel, TextField } from '@material-ui/core';

import {
  Input,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  SocialMediaSelect,
  TextInput,
  Typography,
  PlusIconTwoTone,
  TrashErrorIconTwoTone,
} from '@room-match/shared-ui-components';
import { Fragment, useEffect, useState } from 'react';

import { useStyles } from './Division.styles';
import { useDebounce } from 'use-debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage } from '@room-match/shared-utils';
import { ContactInput } from 'shared/elements';
import FileUpload from 'components/FileUpload/FileUpload';

type Props = {
  form?: any;
};

const Division: React.FC<Props> = ({ form }) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Add Your Divisions</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item className={classes.oneRow}>
          <Grid item className={classes.oneRowField}>
            <TextInput label={'Division Name'} placeholder="Enter Division Name..." />
          </Grid>
          <Grid item className={classes.oneRowField}>
            <TextInput label={'Email Address'} placeholder="Enter Email Address..." />
          </Grid>
          <Grid item className={classes.oneRowField}>
            <InputLabel id="representationStatus" style={{ marginBottom: '-9px' }}>
              Phone Number
            </InputLabel>
            <ContactInput
              country={'us'}
              name="contact_no"
              variant="outlined"
              placeholder="Type here..."
              dropdownStyle={{ height: '50px' }}
              showLabel={false}
            />
          </Grid>
          <Grid item style={{ marginTop: 29 }}>
            <IconButton className={classes.iconButton}>
              <TrashErrorIconTwoTone className={classes.trashIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            fullWidth
            className={classes.buttonCls}
            // onClick={addLocation}
          >
            <PlusIconTwoTone style={{ width: '16px' }} /> &nbsp;Add a New Division
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Division;
