export enum SelfTapesTab {
  NEW = 'new',
  REJECTED = 'rejected',
  FOR_REVIEW = 'for-review',
  APPROVED = 'approved',
  REQUESTED = 'requested',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  NO_RESPONSe = 'no-response',
}
