import { styled, Grid, Button, DialogContent } from '@material-ui/core';
import { Checkbox, StyledDialog } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '791px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px!important',
  background: '#0d1117',
}));

export const Header = styled(Grid)(({ theme }) => ({
  gap: 15,
  alignItems: 'center',
}));

export const Form = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  gap: 5,

  '& .MuiOutlinedInput-root': {
    height: '50px!important',
    fontSize: 14,
    background: '#0e1319',
  },
}));

export const TalentInformation = styled(Grid)(({ theme }) => ({
  '& .textareaCls::placeholder': {
    color: COLORS.MAIN_GRAY,
    opacity: 0.5,
    letterSpacing: '1px',
  },
}));

export const AddButton = styled(Button)(({ theme }) => ({
  height: 50,
  marginTop: 15,
  fontSize: 16,
  fontWeight: 'normal',
}));
