import { IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'shared/elements';
import { DownloadIcon, Typography } from '@room-match/shared-ui-components';
import { onOpenSizeCard } from 'shared/redux/slicers/talentProfile.slicer';
import { useStyles } from './TalentDetails.styles';
type Props = {
  profile: any;
};
const TalentDetails: React.FC<Props> = ({ profile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleOpenSizeCard = () => {
    dispatch(onOpenSizeCard());
  };

  return (
    <div className={classes.talentDetailsContainer}>
      <div className={classes.talentDetail}>
        <Typography
          className={classes.talentDetail__name}
        >{`${profile.talent.first_name} ${profile.talent.last_name}`}</Typography>
        <Typography className={classes.talentDetail__location}>SAG/AFTRA - Los Angeles</Typography>
        <div className={classes.talentDetail__statistics}>
          <Typography className={classes.talentDetail__title}>
            Height : <span className={classes.talentDetail__content}>{profile.talent.statistics?.height || 'N/A'}</span>
          </Typography>
          <Typography className={classes.talentDetail__statistics__separator}>|</Typography>
          <Typography className={classes.talentDetail__title}>
            Weight : <span className={classes.talentDetail__content}>{profile.talent.statistics?.weight || 'N/A'}</span>
          </Typography>
          <Typography className={classes.talentDetail__statistics__separator}>|</Typography>
          <Typography className={classes.talentDetail__title}>
            Hair Colour :{' '}
            <span className={classes.talentDetail__content}>{profile.talent.statistics?.hair_color || 'N/A'}</span>
          </Typography>
          <Typography className={classes.talentDetail__statistics__separator}>|</Typography>
          <Typography className={classes.talentDetail__title}>
            Eye Colour :{' '}
            <span className={classes.talentDetail__content}>{profile.talent.statistics?.eye_color || 'N/A'}</span>
          </Typography>
          <Typography className={classes.talentDetail__statistics__separator}>|</Typography>
          <div style={{ cursor: 'pointer' }} onClick={() => handleOpenSizeCard()}>
            <Typography color="primary">View Size Sheet</Typography>
          </div>

          <Typography className={classes.talentDetail__statistics__separator}>|</Typography>
          <IconButton size="small">
            <DownloadIcon fill="red" fontSize="small" viewBox="0 0 22 22" />
          </IconButton>
        </div>
      </div>
      <div className={classes.talentLogo}>
        <img src="/SMITH_LOGO.png" />
      </div>
    </div>
  );
};

export default TalentDetails;
