import { createStyles, makeStyles, styled } from '@material-ui/core';

export const HistoryDetail = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 8,
  [theme.breakpoints.down('lg')]: {
    '& p': {
      fontSize: '13px !important',
    },
  },
}));
