import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      gap: '1.5em',
      margin: '1em 0',
    },
    button: {
      background: '#10181f',
      border: '1px solid #3e8bff',
      color: '#fff',
      height: 50,
      width: 218,
      padding: '5px 30px',

      '&:last-child': {
        borderColor: '#2d343e',

        '&:hover': {
          borderColor: '#3e8bff',
        },
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '&:hover': {
        background: '#3e8bff',

        '& .MuiSvgIcon-root': {
          '& path': {
            fill: '#fff',
          },
        },
      },
    },
  }),
);
