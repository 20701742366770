import { Grid, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import DetailComment from 'components/ProjectItemDetail/DetailComment/DetailComment';
import DetailFavorite from 'components/ProjectItemDetail/DetailFavorite/DetailFavorite';
import DotIcon from 'components/ProjectItemDetail/DotIcon/DotIcon';
import MessageInput from 'components/ProjectItemDetail/MessageInput/MessageInput';
import NoteIcon from 'components/ProjectItemDetail/NoteIcon/NoteIcon';
import Rating from 'components/ProjectItemDetail/Rating/Rating';
import TalentDescription from 'components/ProjectItemDetail/TalentDescription/TalentDescription';
import TalentHistory from 'components/ProjectItemDetail/TalentHistory/TalentHistory';
import TalentMedia from 'components/ProjectItemDetail/TalentMedia/TalentMedia';
import { SelfTapesExpandedDiv } from 'components/SelfTapesExpandedDiv';
import React, { useState } from 'react';
import { ACTION_TAB_LIST, REQUEST_TAB_LIST } from 'shared/constants/SELF_TAPES_TABS';
import { SelfTapesTab } from 'shared/enums/SelfTapesTab';
import { Tab, TabCard, TabCardContent, TabDetail, TabInformation, TabLeft, useStyles } from './SelfTapesTabs.styles';
import SelfTapesTabsActions from './SelfTapesTabsActions/SelfTapesTabsActions';

type Props = {
  tab: SelfTapesTab;
  isShowVideoItem?: boolean;
};
const SelfTapesTabs: React.FC<Props> = ({ tab, isShowVideoItem }) => {
  const [isShowVideoExpanded, setIsShowVideoExpanded] = useState<boolean>(false);
  const handleOpenShowVideoExpanded = () => setIsShowVideoExpanded(true);
  const handleCloseShowVideoExpanded = () => setIsShowVideoExpanded(false);

  const IS_REQUEST_TAB = REQUEST_TAB_LIST.includes(tab);
  const IS_ACTION_TAB = ACTION_TAB_LIST.includes(tab);

  const classes = useStyles();
  return (
    <Tab>
      <div
        className={clsx(classes.tab__contentWrapper, {
          [classes.tab__contentWrapper__showExpandedDiv]: isShowVideoExpanded,
        })}
      >
        {/* Card */}
        <TabCard>
          {/* Card Content */}
          <TabCardContent>
            <Grid
              container
              className={clsx({
                [classes.tab__contentContainer]: isShowVideoExpanded,
              })}
            >
              {/* Left Content */}
              <TabLeft>
                {/* Action */}
                <SelfTapesTabsActions tab={tab} />
                {/* Details */}
                <TabDetail>
                  {/* Video Player */}
                  <TalentMedia isVideo={true} />
                  {/* Information */}
                  <TabInformation
                    className={clsx({
                      [classes.tab__info__unFW]: !IS_ACTION_TAB,
                    })}
                  >
                    {/* Description */}
                    <TalentDescription
                      isShowVideoExpanded={isShowVideoExpanded}
                      handleCloseShowVideoExpanded={handleCloseShowVideoExpanded}
                      handleOpenShowVideoExpanded={handleOpenShowVideoExpanded}
                    />
                    {/* History */}
                    <div
                      className={clsx(classes.tab__history, {
                        [classes.tab__history__unFW]: IS_ACTION_TAB,
                      })}
                    >
                      <MessageInput style={{ height: 40 }} />
                      <TalentHistory />
                    </div>
                    <NoteIcon />
                    <IconButton aria-label="more" size="small">
                      <MoreVert style={{ width: '24px', height: '24px', color: '#6e767f' }} />
                    </IconButton>
                  </TabInformation>
                </TabDetail>
              </TabLeft>
            </Grid>
            {/* Expanded Div Container */}
          </TabCardContent>
        </TabCard>
        {isShowVideoExpanded && (
          <div className={classes.tab__expandedDivContainer}>
            {/* Expanded Div */}
            <SelfTapesExpandedDiv />
          </div>
        )}
      </div>
    </Tab>
  );
};

export default SelfTapesTabs;
