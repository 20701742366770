import { useMutation, useQuery } from 'react-query';
import { projectSideDao } from 'shared/dao/projectSideDao';
import { IProjectSideResponsePayload } from 'shared/interfaces/IProjectSide';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { getProjectSides: getProjectSidesDao, attachSides: attachSidesDao } = projectSideDao();
export const projectSideService = () => {
  const getProjectSides = (projectId: string) => {
    return useQuery<IProjectSideResponsePayload, IAxiosErrorResponse>(['projectSlides', projectId], () =>
      getProjectSidesDao(projectId),
    );
  };

  const attachSides = () => {
    return useMutation<IProjectSideResponsePayload, IAxiosErrorResponse, { projectId: string; mediumIds: string[] }>(
      ({ projectId, mediumIds }) => attachSidesDao(projectId, mediumIds),
    );
  };

  return {
    getProjectSides,
    attachSides,
  };
};
