import { styled } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
}));
