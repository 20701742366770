import { IconButton } from '@material-ui/core';
import {
  AllIconTwoTone,
  CheckedCalendarIcon,
  CheckedCircleIcon,
  ClockCircleIcon,
  DeclineIconTwoTone,
  InformationIconTwoTone,
  MessageRoundIcon,
  PriceTagIcon,
  Typography,
} from '@room-match/shared-ui-components';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { COLORS } from 'shared/constants/COLORS';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import List from '../List/List';
import { ActionButton, ActionsContainer, StatusTabs, Tab, useStyles } from './ActionItems.styles';

const mockData = [
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Callback',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Forward To Talent',
    action_time: '',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Callback',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Forwarded',
    action_time: '2h 45m ago',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Callback',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Talent Reschedule Request',
    action_time: '',
    message: 'Work commitments and other personal issues',
    message_status: 'warning',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Callback',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Request Forwarded to CD',
    action_time: '',
    message: 'Work commitments and other personal issues',
    message_status: 'warning',
    remarks: 'Talent has work commitments and other personal issues',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Studio Audition',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Confirmed',
    action_time: '',
    message: 'Work commitments and other personal issues',
    message_status: 'warning',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Studio Audition',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Talent Declined',
    action_time: '',
    message: 'I am sick in bed',
    message_status: 'danger',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Studio Audition',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Decline Forwarded',
    action_time: '',
    message: 'I am sick in bed',
    message_status: 'danger',
    remarks: 'Talent sick in bed',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Studio Audition',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Late 15M+',
    action_time: '',
    message: '',
    remarks: '',
    late_mins: 25,
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Confirmed Submission',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Talent Declined Request',
    action_time: '',
    message: 'I am sick right now',
    message_status: 'danger',
    remarks: 'Talent is sick right now',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Decline forwarded',
    action_time: '',
    message: 'I am sick right now',
    message_status: 'danger',
    remarks: 'Talent is sick right now',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Review Self Tape',
    action_time: '',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Avail Request',
    audition_time: '',
    audition_date: '',
    status: 'Pending Confirmation',
    action_time: '',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Avail Request',
    audition_time: '',
    audition_date: '',
    status: 'Confirmed',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Avail Request',
    audition_time: '',
    audition_date: '',
    status: 'Declined',
    action_time: '',
    message: '',
    remarks: 'Recently booked a TV Series',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Booking Request',
    audition_time: '',
    audition_date: '',
    status: 'Pending Confirmation',
    action_time: '',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Booking Request',
    audition_time: '',
    audition_date: '',
    status: 'Pending Confirmation',
    action_time: '1',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Booking Request',
    audition_time: '',
    audition_date: '',
    status: 'Confirmed',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Booking Request',
    audition_time: '',
    audition_date: '',
    status: 'Declined',
    action_time: '',
    message: '',
    remarks: 'Sorry, this is not what I want',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'Released',
    audition_time: '',
    audition_date: '',
    status: 'Confirmed',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'TH Info Request',
    audition_time: '',
    audition_date: '',
    status: 'Provide Information',
    action_time: '',
    message: '',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'TH Info Request',
    audition_time: '',
    audition_date: '',
    status: 'Submitted',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
];

const tabs = [
  {
    name: 'all',
    header: 'All',
    number: 9,
    icon: <AllIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'new',
    header: 'New',
    number: 9,
    icon: <AllIconTwoTone />,
    component: <div>qwe</div>,
  },
  {
    name: 'avails',
    header: 'Avails',
    number: 1,
    icon: <AllIconTwoTone className="orange" />,
    component: <div>All</div>,
  },
  {
    name: 'bookings',
    header: 'Bookings',
    number: 1,
    icon: <CheckedCalendarIcon className="primary" />,
    component: <div>All</div>,
  },
  {
    name: 'declined',
    header: 'Declined',
    number: 1,
    icon: <DeclineIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'reschedules',
    header: 'Reschedules',
    number: 1,
    icon: <ClockCircleIcon />,
    component: <div>All</div>,
  },
  {
    name: 'no_responses',
    header: 'No Responses',
    number: 1,
    icon: <InformationIconTwoTone className="orange" />,
    component: <div>All</div>,
  },
  {
    name: 'late',
    header: 'Late 15+',
    number: 1,
    icon: <ClockCircleIcon className="red" />,
    component: <div>All</div>,
  },
  {
    name: 'released',
    header: 'Released',
    number: 1,
    icon: <DeclineIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'info_request',
    header: 'Info Request',
    number: 2,
    icon: <InformationIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'completed',
    header: 'Completed',
    number: 1,
    icon: <CheckedCircleIcon className="green" />,
    component: <div>All</div>,
  },
];

type Props = {
  tab: string;
};

const ActionItems: React.FC<Props> = ({ tab }) => {
  const { childTab } = useParams() as { childTab: string };
  const history = useHistory();
  const classes = useStyles();

  const getActiveTab = useMemo(() => {
    return tabs.find((_tab) => _tab.name === childTab)?.component;
  }, [childTab]);

  const handleRequestTabChange = (tab: string, childTab: string) => {
    history.push(navigateToRoute.toWorksheetChildTab(tab, childTab));
  };

  return (
    <div>
      <StatusTabs>
        {tabs.map((i) => {
          return (
            <Tab className={i.name !== childTab ? classes.inactive : ''}>
              <IconButton onClick={() => handleRequestTabChange(tab, i.name)}>{i.icon}</IconButton>
              <Typography>{i.number}</Typography>
              <Typography color="mainGrey" fontSize={12}>
                {i.header}
              </Typography>
            </Tab>
          );
        })}
      </StatusTabs>
      <ActionsContainer>
        <Typography fontSize={18}>0 Talent Selected</Typography>
        <ActionButton color="secondary" startIcon={<MessageRoundIcon />} className={classes.inactive}>
          Message
        </ActionButton>
        <ActionButton color="secondary" startIcon={<PriceTagIcon className="primary" />} className={classes.inactive}>
          Tab
        </ActionButton>
        <ActionButton color="secondary" className={classes.inactive}>
          Clear Selected
        </ActionButton>
        <ActionButton color="secondary">Clear All Completed</ActionButton>
      </ActionsContainer>
      <List data={mockData} tab={tab} />
    </div>
  );
};

export default ActionItems;
