import { Link } from 'shared/elements';
import React from 'react';
import { RolesFor, RolesForItem, TextContainer, TitleContainer } from './ProjectDetailsSummary.styles';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { Typography } from '@room-match/shared-ui-components';

type Props = {
  project: IProjectAttributes | undefined;
};

const RolesForList: string[] = ['Adult', 'Minor', 'Models', 'Stunt', 'Voice', 'Sports', 'Influencer'];

const ProjectDetailsSummary: React.FC<Props> = ({ project }) => {
  return (
    <div style={{ marginBottom: -20 }}>
      <TextContainer>
        <Typography fontSize={20}>Number of Roles: </Typography>
        <Typography fontSize={20} color="primary">
          20
        </Typography>
      </TextContainer>
      {/* <TextContainer> */}
      <Typography fontSize={14}>Roles For:</Typography>
      {/* </TextContainer> */}
      <RolesFor>
        {RolesForList.map((i) => (
          <RolesForItem key={i}>{i}</RolesForItem>
        ))}
      </RolesFor>
      <TitleContainer>
        <Typography fontSize={25}>Summary</Typography>
      </TitleContainer>
      <TextContainer>
        <Typography fontSize={15} style={{ paddingRight: '30px' }}>
          {project?.additional_provisions ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales facilisis massa vitae porttitor. Curabitur consectetur leo eget eros molestie, sodales iaculis orci vestibulum. Donec vulputate, nibh et sodales ultricies, nisi lorem interdum enim, sed porttitor augue libero blandit nibh. Fusce consequat felis ut est ultrices convallis. Ut sit amet orci lacus.'}
        </Typography>
      </TextContainer>
    </div>
  );
};

export default ProjectDetailsSummary;
