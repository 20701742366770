import { styled, Button, DialogContent, createStyles, makeStyles, TextareaAutosize } from '@material-ui/core';
import { Checkbox, StyledDialog, TextInput, Typography } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    textInputCls: {
      '& .MuiOutlinedInput-multiline': {
        padding: 14,
        borderRadius: 5,
      },
      '& textarea': {
        fontSize: '14px!important',
      },
    },
    text__orange: {
      color: '#ffa63e',
    },
  }),
);

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '643px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 40px!important',
  background: '#0d1117',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: 20,
}));

export const SendButton = styled(Button)(({ theme }) => ({
  maxWidth: 81,
  height: 36,
}));

export const HistoryLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 20,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 0 1px',
  paddingBottom: 10,
  marginBottom: 10,
}));

export const MessagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const Message = styled('div')(({ theme }) => ({
  background: '#1e2530',
  borderRadius: 8,
  padding: '10px 15px',
}));
