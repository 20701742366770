import { DialogContent, IconButton } from '@material-ui/core';
import { ITab } from '@room-match/shared-ui-components';
import React, { useMemo, useState } from 'react';
import { StyledDialog, StyledTab, StyledTabs, Typography, CloseIcon } from '@room-match/shared-ui-components';
import ImageGallery from './ImageGallery/ImageGallery';
import { useStyles } from './ResumeMediaGallery.styles';
import VideoGallery from './VideoGallery/VideoGallery';

const tabs: ITab[] = [
  {
    name: 'images',
    header: 'Images',
    component: <ImageGallery />,
  },
  {
    name: 'videos',
    header: 'Videos (200)',
    component: <VideoGallery />,
  },

  {
    name: 'audio',
    header: 'Audio (100)',
    component: <h1>Hello</h1>,
  },
];
type Props = {
  open: boolean;
  onClose: () => void;
};
const ResumeMediaGallery: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('images');

  const getActiveTab = useMemo(() => {
    if (!tab) {
      return tabs[0].component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth style={{ maxHeight: '95%', top: 20 }}>
      <DialogContent>
        {/* Resume Description */}
        <div className={classes.resumeDescription}>
          <div>
            <Typography>2010 - The Wannabes</Typography>
            <Typography fontSize={13} color="mainGrey">
              Andrew, Ian Brown
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 13 }} />
          </IconButton>
        </div>
        <div className={classes.tabContainer}>
          <StyledTabs value={!tab ? 'resume' : tab} onChange={handleTabChange} aria-label="simple tabs example">
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={tab.header}
                value={tab.name}
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            ))}
          </StyledTabs>
          <div className={classes.activeTabContainer}>{getActiveTab}</div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default ResumeMediaGallery;
