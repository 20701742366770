export const readableAggregates = (aggregates: string) => {
  switch (aggregates) {
    case 'new':
      return 'New';
    case 'viewed':
      return 'Viewed';
    case 'selected':
      return 'Selected';
    case 'auditioned':
      return 'Auditioned';
    case 'requested':
      return 'Requested';
    case 'for_review':
      return 'CD Approval';
    case 'for_cd_review':
      return 'For Review';
    case 'approved':
      return 'Approved';
    case 'called_back':
      return 'Callback';
    case 'selects':
      return 'Selects';
    case 'on_avail':
      return 'On Avail';
    case 'released':
      return 'Released';
    case 'booked':
      return 'Booked';
    case 'th':
      return 'TH';
    default:
      break;
  }
};
