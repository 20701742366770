import { CheckInIcon, DragIcon } from '@room-match/shared-ui-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { useSetHeaderTitle } from 'shared/hooks/useSetHeader';
import Item from './Item/Item';
import { Body, Column, Header, ListContainer } from './List.styles';

type Props = {
  data: any;
  tab: string;
};

const List: React.FC<Props> = ({ data, tab }) => {
  // Temporary Static Header
  return (
    <Body>
      {tab === 'action_items' && (
        <Header>
          <Column>
            <DragIcon /> <span>Type</span>
          </Column>
          <Column>
            <DragIcon /> <span>Status</span>
          </Column>
          <Column>
            <DragIcon /> <span>Job/Role Info</span>
          </Column>
          <Column>
            <DragIcon /> <span>Talent Information</span>
          </Column>
          <Column>
            <DragIcon /> <span>Talent Response / Message</span>
          </Column>
          <Column>
            <DragIcon /> <span>Agency Response / Messages</span>
          </Column>
        </Header>
      )}
      <ListContainer>
        {data.map((i: any) => (
          <Item data={i} />
        ))}
      </ListContainer>
    </Body>
  );
};

export default List;
