import { useMutation } from 'react-query';
import { uploadDao } from 'shared/dao/uploadDao';
import { IUploadCreateResponsePayload } from 'shared/interfaces/IUpload';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const { uploadMedia: uploadMediaDao } = uploadDao();
export const uploadService = () => {
  const uploadMedia = () => {
    return useMutation<
      IUploadCreateResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgres?: IOnProgress }
    >(({ formData, onProgres }) => uploadMediaDao(formData, onProgres));
  };

  return {
    uploadMedia,
  };
};
