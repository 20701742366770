import { Grid } from '@material-ui/core';
import { CheckInIcon } from '@room-match/shared-ui-components';
import { GridView } from 'pages/MyProjects/GridView';
import { ListView } from 'pages/MyProjects/ListView';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { useSetHeaderTitle } from 'shared/hooks/useSetHeader';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { ButtonCont, CheckInButton, MainCont } from './ProjectList.styles';

type Props = {
  view: string;
  projects: any;
  handleOpenProjectDetailModel: (project: IProjectAttributes) => void;
};

const ProjectList: React.FC<Props> = ({ view, projects, handleOpenProjectDetailModel }) => {
  const history = useHistory();

  return (
    <>
      {view === 'list' ? (
        <>
          {projects.map((data: any, index: any) => (
            <ListView key={index} data={data} handleOpenProjectDetailModel={handleOpenProjectDetailModel} />
          ))}
        </>
      ) : (
        <Grid container spacing={2}>
          {projects.map((data: any, index: any) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <GridView key={index} data={data} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ProjectList;
