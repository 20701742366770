import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tab: {},
    tab__contentWrapper: {
      marginTop: theme.spacing(3),
      backgroundColor: '#0f151c',
      border: `1px solid #2d343e`,
      borderRadius: 8,
    },
    tab__contentWrapper__showExpandedDiv: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
      borderImageSlice: '1',
      backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderRadius: '8px !important',
    },
    tab__contentContainer: {
      borderBottom: `1px solid #2d343e`,
    },
    tab__history: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginLeft: 30,
      gap: 10,

      // flex: 1,
      '& > div:first-child': {
        maxWidth: 308,
        marginRight: 30,
      },
    },
    tab__history__unFW: {
      width: '100%',
      marginLeft: 'auto',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 0,
      maxWidth: 600,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 300,
      },
    },
    tab__info__unFW: {
      '& > button:last-child': {
        marginLeft: 'auto',
      },
    },
    tab__expandedDivContainer: {
      padding: theme.spacing(2, 3),
      backgroundColor: '#0b0f14',
      overflow: 'hidden',
      borderRadius: 8,
    },
  }),
);

export const Tab = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const TabCard = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 8,
    },
  },
}));

export const TabCardContent = styled('div')(({ theme }) => ({
  overflow: 'hidden',
}));

export const TabLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
}));

export const TabDetail = styled('div')(({ theme }) => ({
  padding: '14px 16px',
  backgroundColor: '#0b0f14',
  flex: 1,
  display: 'flex',
  gap: 8,
  borderBottomRightRadius: 8,
  borderTopRightRadius: 8,
  [theme.breakpoints.down('lg')]: {
    padding: 12,
  },
}));

export const TabInformation = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 24,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    gap: 16,
  },
}));
