import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog__container: {
      '& .MuiPaper-root': {
        minWidth: '60%',
        maxWidth: '95%',
        minHeight: '90%',
        width: 1202,
      },

      '& .MuiDialogContent-root': {
        padding: '30px 40px',

        [theme.breakpoints.down(730)]: {
          padding: '20px',
        },
      },
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      marginBottom: 40,

      '& > div:first-child': {
        display: 'flex',
        gap: '1em',
        '& > div:last-child > p:first-child': {
          marginBottom: -5,
        },

        '& > div:last-child > p:last-child': {
          display: 'flex',
          gap: 7,
        },
      },
    },

    content: {
      gap: '2em',
      '& > div:not(:nth-last-child(-n+2))': {
        width: '100%',
        display: 'flex',
        gap: '2em',
        flexDirection: 'column',

        '& > div > div > div': {
          display: 'flex',
          gap: '.5em',
          flexDirection: 'column',
        },
      },
    },
  }),
);
