import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function NotificationAlertIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.417 0c.345 0 .625.28.625.625v1.908a5.842 5.842 0 0 1 5.208 5.8v2.324c0 1.649.723 3.205 1.982 4.27a1.458 1.458 0 0 1-.94 2.573l-3.813.001A3.13 3.13 0 0 1 9.417 20a3.13 3.13 0 0 1-3.062-2.499L2.542 17.5a1.46 1.46 0 0 1-.949-2.567 5.585 5.585 0 0 0 1.99-4.276V8.333a5.842 5.842 0 0 1 5.209-5.8V.625c0-.345.28-.625.625-.625zm1.767 17.5H7.65c.258.728.953 1.25 1.768 1.25.814 0 1.51-.522 1.767-1.25zM9.417 3.75a4.588 4.588 0 0 0-4.584 4.583v2.324a6.83 6.83 0 0 1-2.425 5.225.208.208 0 0 0 .134.368h13.75a.21.21 0 0 0 .208-.208.205.205 0 0 0-.073-.159A6.832 6.832 0 0 1 14 10.657V8.333A4.588 4.588 0 0 0 9.417 3.75zm6.177-2.636a10.145 10.145 0 0 1 2.99 7.22.625.625 0 0 1-1.25 0 8.901 8.901 0 0 0-2.624-6.336.625.625 0 1 1 .884-.884zm-11.47.001a.625.625 0 0 1 0 .884A8.895 8.895 0 0 0 1.5 8.333a.625.625 0 0 1-1.25 0 10.14 10.14 0 0 1 2.99-7.218.625.625 0 0 1 .884 0z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default NotificationAlertIcon;
