import { Grid, IconButton, ImageList, ImageListItem } from '@material-ui/core';
import { PlayIconTwoTone, Typography } from '@room-match/shared-ui-components';
import { ellipseText } from '@room-match/shared-utils';

import { MiniAudioPlayer } from 'components/MiniAudioPlayer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { onOpenImageGallery, onOpenVideoGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { useStyles } from './TalentProfileMedia.styles';
type Props = {
  profile: any;
};
const TalentProfileMedia: React.FC<Props> = ({ profile }) => {
  const dispatch = useDispatch();
  const handleOpenImageGallery = () => {
    dispatch(onOpenImageGallery());
  };

  const handleOpenVideoGallery = () => {
    dispatch(onOpenVideoGallery());
  };

  const classes = useStyles();
  return (
    <div className={classes.talentProfileMediaContainer}>
      {/* Primary Image */}
      <div className={classes.primaryImage}>
        <img
          src={profile.primary_medium_attachment?.modified_attachment || '/sample_person.jpg'}
          className={classes.primaryImage__img}
        />
      </div>
      {/* Image List */}
      <div className={classes.imageListContainer}>
        <ImageList rowHeight={127} cols={3} gap={12} className={classes.imageList}>
          {Array.from({ length: 9 }).map((_, index) => (
            <ImageListItem
              key={index}
              classes={{ item: classes.imageListItem }}
              onClick={() => handleOpenImageGallery()}
            >
              <img src="/sample_person2.jpg" style={{ borderRadius: 4, objectFit: 'contain' }} />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      {/* Video List */}
      <div className={classes.videoListContainer}>
        <Typography fontWeight={500}>Video Files (5)</Typography>
        <Grid container spacing={2} style={{ marginTop: 12 }}>
          {Array.from({ length: 6 }).map((_, i) => (
            <Grid item lg={6} key={i}>
              <div className={classes.videoPlayerContainer}>
                <img src="/sample_person.jpg" className={classes.videoPlayerThumbnail} />
                <IconButton className={classes.videoPlayerButton} onClick={() => handleOpenVideoGallery()}>
                  <PlayIconTwoTone style={{ fontSize: 30 }} />
                </IconButton>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      {/* Audio List */}
      <div className={classes.audioListContainer}>
        <Typography fontWeight={500}>Audio Files (5)</Typography>
        {/* Audio List Grid */}
        <Grid container style={{ marginTop: 12 }} spacing={2}>
          {/* Audio List Item */}
          {Array.from({ length: 4 }).map((_, i) => (
            <Grid item lg={12} md={12}>
              <MiniAudioPlayer />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default TalentProfileMedia;
