import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 1129,
      height: 'auto',
      background: 'rgba(11, 15, 20, 0.8)',
      border: '1px solid #2d343e',
      borderRadius: 8,
      padding: '10px 30px 30px',
    },
    header: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderBottom: '1px solid #2d343e',
      alignItems: 'center',
      paddingBottom: 10,
      margin: '10px 0 70px',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        paddingBottom: 70,
        textAlign: 'center',
      },

      [theme.breakpoints.down(692)]: {
        paddingBottom: 100,
      },

      '&::before': {
        content: '""',
        background: '#3e8bff',
        height: '1px',
        position: 'absolute',
        width: '50px',
        bottom: '-1px',
      },
    },
    stepperContainer: {
      [theme.breakpoints.down('md')]: {
        marginTop: 15,
      },
    },
    body: {
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',

      alignItems: 'center',
    },
    onlyBody: {
      height: 775,
    },

    buttonCls: {
      marginTop: 25,
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      textDecoration: 'none!important',
      maxWidth: 390,
      fontSize: 16,
    },
    backIconButton: {
      height: 50,
      width: 50,
      position: 'absolute',
      left: 0,
      top: 30,
    },
    footer: {
      position: 'relative',
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0',
    },
    success: {
      justifyContent: 'center',
    },
  }),
);
