import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    profileDialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialogContent-root': {
        padding: '30px 30px 10px 30px',
      },
    },

    contentContainer: {
      marginTop: 12,
    },
  }),
);
