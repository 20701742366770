import { styled, Button } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({}));

export const Header = styled('div')(({ theme }) => ({
  height: 39,
  background: '#0f151c',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& > div': {
    '&:first-child': {
      marginLeft: 45,
      width: 135,
    },
    '&:nth-child(2)': {
      width: 115,
    },
    '&:nth-child(3)': {
      width: 215,
    },
    '&:nth-child(4)': {
      width: 245,
    },
    '&:nth-child(5)': {
      width: 490,
    },
  },
}));

export const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 11,
  textTransform: 'uppercase',
  '& svg': {
    height: 18,
    marginRight: 5,
  },
  '& span': {
    marginTop: 2,
  },
}));

export const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& > div:not(:last-child)': {
    marginBottom: 10,
  },
}));
