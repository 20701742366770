import { createStyles, makeStyles } from '@material-ui/core';

export const useReportPreviewDialog = makeStyles((theme) =>
  createStyles({
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '20px',
      position: 'relative',

      '&::before': {
        content: '""',
        background: '#2d343e',
        height: 1,
        position: 'absolute',
        width: '102%',
        marginLeft: '-1%',
        bottom: '-2px',

        // [theme.breakpoints.down('md')]: {
        //   width: '106.2%',
        //   marginLeft: '-3.1%',
        // },
      },
    },

    two__column: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '2em',

      '& > div': {
        flex: '50%',

        [theme.breakpoints.down('md')]: {
          flex: '100%',
        },
      },

      '& > p:first-child': {
        width: 150,
      },
    },

    one__column: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '.5em',
      flexDirection: 'column',
    },

    span: {
      color: '#3e8bff',
      fontWeight: 500,
    },
  }),
);
