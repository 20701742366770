import { Link } from 'shared/elements';
import React from 'react';
import { ActionsContainer, CustomButton } from './ProjectDetailsActions.styles';
import { CheckedCircleIcon, DeclineIconTwoTone } from '@room-match/shared-ui-components';

const ProjectDetailsActions = () => {
  return (
    <ActionsContainer>
      <CustomButton
        fullWidth
        variant="contained"
        color="secondary"
        className="confirm"
        startIcon={<CheckedCircleIcon />}
      >
        Confirm
      </CustomButton>
      <CustomButton
        fullWidth
        variant="contained"
        color="secondary"
        className="decline"
        startIcon={<DeclineIconTwoTone />}
      >
        Decline
      </CustomButton>
    </ActionsContainer>
  );
};

export default ProjectDetailsActions;
