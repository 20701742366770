import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AppState {
  headerTitle: string;
  companyName: string;
}

const initialState: AppState = {
  headerTitle: '',
  companyName: '',
};

export const appSlicer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeaderTitle: (state: AppState, action: PayloadAction<{ headerTitle: string }>) => {
      const { headerTitle } = action.payload;
      const cleanedHeaderTitle = headerTitle.replace('null ', '');
      state.headerTitle = cleanedHeaderTitle;
    },
    setCompanyName: (state: AppState, action: PayloadAction<{ companyName: string }>) => {
      const { companyName } = action.payload;
      state.companyName = companyName;
    },
  },
});

export const selectAppState = (state: RootState) => state.app;
export const { setHeaderTitle, setCompanyName } = appSlicer.actions;

export default appSlicer.reducer;
