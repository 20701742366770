import React, { useState } from 'react';
import { Checkbox, CloseIcon, Typography } from '@room-match/shared-ui-components';
import {
  ApplyButton,
  CastingDirectorsListContainer,
  CastingDirectorsListWrap,
  CheckboxContainer,
  ClearButton,
  CloseButton,
  CustomDialog,
  CustomDialogContent,
  FilterSelection,
  FilterSelectionItemActive,
  FilterSelectionItemInactive,
  FilterSelectionItemsContainer,
  HeadingWithBorderStyle,
  HeadingWithoutBorderStyle,
  Row,
  Section,
} from './CastingsFilterModal.styles';
import { FlashOnOutlined } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

const rolesForList = [
  'All',
  'Adult',
  'Minor',
  'Models',
  'Stunt',
  'Voice',
  'Sports',
  'Influencer',
  'Extra',
  'Presenter/Host',
  'Other',
];

const unionList = ['All', 'SAG/AFTRA', 'Non Union'];

const typeList = ['All', 'Commercial', 'Theatrical', 'Television', 'Voice', 'Print/Fashion', 'Extras', 'Other'];

type Props = {
  open: boolean;
  onClose: (instance: boolean) => void;
};
const CastingsFilterModal: React.FC<Props> = ({ open, onClose }) => {
  const divisionList = ['My Talent', 'Commercial', 'Print', 'Voice-Over'];

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Section>
          <HeadingWithoutBorderStyle>
            <Typography fontSize={20}>Casting Filters</Typography>
          </HeadingWithoutBorderStyle>
          <FilterSelection>
            <Row>
              <Typography fontSize={14}>Roles For</Typography>
              <FilterSelectionItemsContainer>
                {rolesForList.map((i) => (
                  <FilterSelectionItemInactive>{i}</FilterSelectionItemInactive>
                ))}
              </FilterSelectionItemsContainer>
            </Row>
            <Row>
              <Typography fontSize={14}>Union</Typography>
              <FilterSelectionItemsContainer>
                {unionList.map((i) => (
                  <FilterSelectionItemActive>{i}</FilterSelectionItemActive>
                ))}
              </FilterSelectionItemsContainer>
            </Row>
          </FilterSelection>
        </Section>
        <Section>
          <HeadingWithBorderStyle>
            <Typography fontSize={20}>Type</Typography>
          </HeadingWithBorderStyle>
          <Row style={{ marginTop: 20 }}>
            {typeList.map((i) => (
              <Checkbox labelPlacement="end" label={i} />
            ))}
          </Row>
        </Section>
        <Section>
          <HeadingWithBorderStyle>
            <Typography fontSize={20}>Casting Directors</Typography>
          </HeadingWithBorderStyle>
          <CastingDirectorsListContainer>
            <CastingDirectorsListWrap>
              <Grid item style={{ width: '100%', margin: '2px 0 7px' }}>
                <Checkbox labelPlacement="end" label={'All'} />
              </Grid>
              {Array.from({ length: 70 }, (_, i) => (
                <CheckboxContainer>
                  <Checkbox labelPlacement="end" label={'Justin Wagner Casting'} />
                </CheckboxContainer>
              ))}
            </CastingDirectorsListWrap>
          </CastingDirectorsListContainer>
        </Section>
        <Section>
          <ApplyButton variant="contained" color="primary" fullWidth>
            Apply
          </ApplyButton>
          <ClearButton variant="contained" color="primary" fullWidth>
            Clear Filters
          </ClearButton>
        </Section>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default CastingsFilterModal;
