import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '250px',
      width: '250px',
      borderRadius: '4px',
      border: theme.palette.type === 'dark' ? 'dashed 1px #2d343e' : 'dashed 1px #FFFFFF',
      background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
    },
    uploaderContainer: {
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#6e767f',
      '& p': {
        fontSize: '13px',
      },
      '& > *:not(:last-child)': {
        marginBottom: '15px',
      },
    },
    uploaderHeader: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      gap: 6,
    },
    img: {
      height: 248,
      width: 248,
      borderRadius: 5,
      objectFit: 'cover',
    },
  }),
);
