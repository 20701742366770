import React, { useState } from 'react';
import { CloseIcon, MessageRoundIcon, TextInput, Typography } from '@room-match/shared-ui-components';
import {
  AddButton,
  CloseButton,
  CustomDialog,
  CustomDialogContent,
  Form,
  Header,
  TalentInformation,
} from './MessageTalentModal.styles';
import { MessageRounded } from '@material-ui/icons';

type Props = {
  open: boolean;
  onClose: (instance: boolean) => void;
};
const MessageTalentModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Header container>
          <MessageRoundIcon style={{ width: '32px', height: '32px' }} />
          <TalentInformation>
            <Typography fontSize={25}>Message Talent</Typography>
            <Typography fontSize={14}>25 Talent Selected</Typography>
          </TalentInformation>
        </Header>
        <Form container>
          <TextInput placeholder="Type subject..." />
          <TextInput
            className={'textareaCls'}
            placeholder="Type message here..."
            textArea={true}
            textAreaMinRows={11}
          />
        </Form>
        <AddButton fullWidth variant="contained" color="primary">
          Send Message
        </AddButton>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default MessageTalentModal;
