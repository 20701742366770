import { Grid, Button } from '@material-ui/core';
import { EmailIconTwoTone } from '@room-match/shared-ui-components';

import React, { useState } from 'react';

import { useStyles } from './Actions.styles';

type Props = {
  onClose: () => void;
};
const Actions: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.container}>
      <Button variant="contained" className={classes.button} startIcon={<EmailIconTwoTone style={{ fontSize: 17 }} />}>
        Send
      </Button>
      <Button variant="contained" className={classes.button} onClick={onClose}>
        Go Back
      </Button>
    </Grid>
  );
};

export default Actions;
