import { Typography } from '@room-match/shared-ui-components';
import React, { useState } from 'react';
import { Body, Container } from './QuestionsAnswers.styles';

const mockData = [
  {
    question: 'What is your vaccination status?',
    answer: 'Double vaccinated and Booster',
  },
  {
    question: 'Do you have any homeware project conflicts?',
    answer: 'Double vaccinated and Booster',
  },
  {
    question: 'What is your vaccination status?',
    answer: 'Double vaccinated and Booster',
  },
];

const QuestionsAnswers = () => {
  return (
    <Body>
      {mockData.map((i) => (
        <Container>
          <Typography color="primary">{i.question}</Typography>
          <Typography>{i.answer}</Typography>
        </Container>
      ))}
    </Body>
  );
};

export default QuestionsAnswers;
