import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const PauseIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="quad27m1wa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M253.3 31.182a1.3 1.3 0 0 0 1.3-1.3v-9.88a1.3 1.3 0 1 0-2.6 0v9.88a1.3 1.3 0 0 0 1.3 1.3zm5.2 0a1.3 1.3 0 0 0 1.3-1.3v-9.88a1.3 1.3 0 1 0-2.6 0v9.88a1.3 1.3 0 0 0 1.3 1.3z"
        transform="translate(-252 -18.35)"
        fill="url(#quad27m1wa)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default PauseIconTwoTone;
