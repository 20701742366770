import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  FilterIconTwoTone,
  InputSearchTwoTone,
  MinusCircleIcon,
  PlusIconTwoTone,
  SearchIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { AddTalentModal, AddTalentToModal, AssignTalentToDivisionModal } from 'components';
import { TalentProfile } from 'components/TalentProfile';
import React, { useState } from 'react';
import { COLORS } from 'shared/constants/COLORS';
import {
  Body,
  ColumnOne,
  ColumnTwo,
  CommonSmallWidth,
  CustomInputAdornment,
  Row,
  HalfWidth,
  useStyles,
  MediumWidth,
  CustomButton,
  Matching,
  MatchingButtons,
  MatchingButtonsContainer,
  ChipsContainer,
  IsolatedButtonsContainer,
  CustomIsolatedButton,
  IsolatedRow,
  Chips,
} from './Filters.styles';

const mockTags = ['cool', 'real', 'demo', 'Basketball (Exp/Pro)'];

const talentData = {
  talent: {
    first_name: 'Natalia',
    last_name: 'Ashley M.',
    statistics: {},
  },
  primary_medium_attachment: {
    modified_attachment: '',
  },
};

type Props = {
  tabName: string;
  setBeingAssignedToDivision: (isBeingAssignedToDivision: boolean) => void;
  isBeingAssignedToDivision: boolean;
};

const Filters: React.FC<Props> = ({ tabName, setBeingAssignedToDivision, isBeingAssignedToDivision }) => {
  const classes = useStyles();
  const [addTalentModalOpen, setAddTalentModalOpen] = useState(false);

  return (
    <Body>
      <Row>
        <ColumnOne>
          <Autocomplete
            options={[]}
            getOptionLabel={(option: any) => option.name}
            fullWidth
            noOptionsText={null}
            onChange={(e, value) => {}}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                name="search"
                placeholder={'Search by name...'}
                fullWidth
                margin={'normal'}
                InputProps={{
                  ...params.InputProps,
                  tabIndex: 1,
                  startAdornment: (
                    <InputSearchTwoTone style={{ fontSize: 24, marginTop: 5, marginLeft: 5, fill: COLORS.PRIMARY }} />
                  ),
                  endAdornment: '',
                }}
              />
            )}
          />
        </ColumnOne>
        <ColumnTwo className={classes.bypassSelectCls}>
          <Select
            variant="outlined"
            fullWidth
            data={[
              {
                key: 'Commercial, Voice-Over',
                value: 'Commercial, Voice-Over',
              },
            ]}
            value={'Commercial, Voice-Over'}
            IconComponent={SelectArrowDownIcon}
            name="representation"
            startAdornment={<CustomInputAdornment position="start">Show Divisions: </CustomInputAdornment>}
          />
        </ColumnTwo>
      </Row>
      <Row>
        <ColumnOne>
          <CommonSmallWidth className={classes.bypassSelectCls}>
            <Select
              variant="outlined"
              fullWidth
              data={[
                {
                  key: 'Actors +2 more',
                  value: 'Actors +2 more',
                },
              ]}
              value={'Actors +2 more'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Type:</CustomInputAdornment>}
            />
          </CommonSmallWidth>
          <CommonSmallWidth className={classes.bypassSelectCls}>
            <Select
              variant="outlined"
              fullWidth
              data={[
                {
                  key: 'Male +1 more',
                  value: 'Male +1 more',
                },
              ]}
              value={'Male +1 more'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Gender: </CustomInputAdornment>}
            />
          </CommonSmallWidth>
          <CommonSmallWidth className={classes.bypassSelectCls}>
            <Select
              variant="outlined"
              fullWidth
              data={[
                {
                  key: 'Actors +2 more',
                  value: 'Actors +2 more',
                },
              ]}
              value={'Actors +2 more'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Type: </CustomInputAdornment>}
            />
          </CommonSmallWidth>
          <CommonSmallWidth className={classes.bypassSelectCls}>
            <HalfWidth>
              <Select
                variant="outlined"
                fullWidth
                data={[
                  {
                    key: '2y',
                    value: '2y',
                  },
                ]}
                value={'2y'}
                IconComponent={SelectArrowDownIcon}
                name="representation"
                startAdornment={<CustomInputAdornment position="start">From: </CustomInputAdornment>}
              />
            </HalfWidth>
            <HalfWidth>
              <Select
                variant="outlined"
                fullWidth
                data={[
                  {
                    key: '110y',
                    value: '110y',
                  },
                ]}
                value={'110y'}
                IconComponent={SelectArrowDownIcon}
                name="representation"
                startAdornment={<CustomInputAdornment position="start">To: </CustomInputAdornment>}
              />
            </HalfWidth>
          </CommonSmallWidth>
        </ColumnOne>
        <ColumnTwo className={classes.bypassSelectCls}>
          <HalfWidth style={{ maxWidth: 312 }}>
            <Select
              variant="outlined"
              fullWidth
              data={[
                {
                  key: 'SAG/Aftra +2 more',
                  value: 'SAG/Aftra +2 more',
                },
              ]}
              value={'SAG/Aftra +2 more'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Union Status: </CustomInputAdornment>}
            />
          </HalfWidth>
          <HalfWidth style={{ maxWidth: 379, width: '100%' }}>
            <Select
              variant="outlined"
              fullWidth
              data={[
                {
                  key: 'Los Angeles, CA +1 more',
                  value: 'Los Angeles, CA +1 more',
                },
              ]}
              value={'Los Angeles, CA +1 more'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Location:</CustomInputAdornment>}
            />
          </HalfWidth>
        </ColumnTwo>
      </Row>
      <Row>
        <ColumnOne>
          <MediumWidth>
            <Autocomplete
              options={[]}
              getOptionLabel={(option: any) => option.name}
              fullWidth
              noOptionsText={null}
              onChange={(e, value) => {}}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="search"
                  placeholder={'Search or create a tag…'}
                  fullWidth
                  margin={'normal'}
                  InputProps={{
                    ...params.InputProps,
                    tabIndex: 1,

                    endAdornment: '',
                  }}
                />
              )}
            />
          </MediumWidth>
          <MediumWidth>
            <HalfWidth>
              <CustomButton fullWidth>
                <FilterIconTwoTone />
                Advanced Filters
              </CustomButton>
            </HalfWidth>
            <HalfWidth>
              <CustomButton fullWidth style={{ color: COLORS.PRIMARY }}>
                Reset All Tags
              </CustomButton>
            </HalfWidth>
          </MediumWidth>
        </ColumnOne>

        <ColumnTwo>
          <Matching>
            <Typography fontSize={14}>Filtering Talent Matching:</Typography>
            <MatchingButtonsContainer>
              <MatchingButtons className={classes.active} fullWidth>
                ALL Filters
              </MatchingButtons>
              <MatchingButtons className={classes.disabled} fullWidth>
                ANY of the Filters
              </MatchingButtons>
            </MatchingButtonsContainer>
          </Matching>
        </ColumnTwo>
      </Row>
      <IsolatedRow>
        <ChipsContainer>
          {mockTags.map((i) => (
            <Chips>
              {i} <MinusCircleIcon />
            </Chips>
          ))}
        </ChipsContainer>
        {tabName !== 'new' && tabName !== 'uncategorized' && (
          <IsolatedButtonsContainer>
            {isBeingAssignedToDivision ? (
              <CustomIsolatedButton onClick={() => setBeingAssignedToDivision(false)}>
                <MinusCircleIcon className={classes.cancelIconCls} />
                Cancel
              </CustomIsolatedButton>
            ) : (
              <CustomIsolatedButton onClick={() => setBeingAssignedToDivision(true)}>
                <PlusIconTwoTone />
                Assign Talent to Divisions
              </CustomIsolatedButton>
            )}

            <CustomIsolatedButton onClick={() => setAddTalentModalOpen(true)}>
              <PlusIconTwoTone />
              Add Talent
            </CustomIsolatedButton>
          </IsolatedButtonsContainer>
        )}
      </IsolatedRow>
      <AddTalentModal open={addTalentModalOpen} data={[]} onClose={() => setAddTalentModalOpen(false)} />
    </Body>
  );
};

export default Filters;
