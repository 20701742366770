import { styled, Grid, Button, createStyles, makeStyles, InputAdornment } from '@material-ui/core';
import { Checkbox, Select } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    bypassSelectCls: {
      '& .MuiSelect-root': {
        color: COLORS.PRIMARY,
      },
      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '18px',
      },

      '& .MuiSelect-iconOpen': {
        top: '0',
        right: '13px',
      },
      '& .MuiFormControl-root': {
        margin: '0!important',
      },
    },
  }),
);

export const Body = styled(Grid)(({ theme }) => ({
  gap: 15,
  justifyContent: 'space-around',
}));

export const NewHeader = styled(Grid)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  gap: 15,
  flexWrap: 'nowrap',

  '& > div:first-child': {
    width: '85.5%',
  },
  '& > div:last-child': {
    width: '14.5%',
  },
}));

export const MarkPageAsViewed = styled(Button)(({ theme }) => ({
  height: 40,
  '& .MuiSvgIcon-root': {
    marginRight: 10,

    '& path': {
      fill: COLORS.PRIMARY,
    },
  },
}));

export const CustomInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& p': {
    fontSize: 14,
    color: '#fff',
  },
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
  '&.MuiInputBase-root': {
    height: '40px !important',
  },
}));
