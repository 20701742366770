import { Grid, Button } from '@material-ui/core';

import React, { useState } from 'react';

import { useStyles } from './Signature.styles';

import { ReportPreviewDialog } from '../../ReportPreviewDialog';
import { Typography } from '@room-match/shared-ui-components';

type Props = {
  key?: string;
};
const Signature: React.FC<Props> = ({ key }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.container}>
      <Grid item>
        <Typography fontSize={25} color="primary">
          Signature
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={25} color="primary">
          Date
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Signature;
