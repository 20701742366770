import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function VideoPlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.795 16.242h-5.98A2.706 2.706 0 0 1 .11 13.538V3.03A2.706 2.706 0 0 1 2.814.326H14.71a2.706 2.706 0 0 1 2.703 2.703v6.657a.676.676 0 1 1-1.352 0V3.029c0-.745-.606-1.351-1.351-1.351H2.814c-.745 0-1.351.606-1.351 1.351v10.51c0 .744.606 1.35 1.351 1.35h5.981a.676.676 0 1 1 0 1.353zm-4.73-3.38a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm2.703 0a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm2.703 0a.676.676 0 1 0-1.351 0 .676.676 0 0 0 1.351 0zM4.065 3.705a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm2.703 0a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm2.703 0a.676.676 0 1 0-1.351 0 .676.676 0 0 0 1.351 0zm2.704 0a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm2.703 0a.676.676 0 1 0-1.352 0 .676.676 0 0 0 1.352 0zm-1.841 13.014 3.603-2.074c.462-.266.738-.743.738-1.276 0-.532-.276-1.01-.738-1.276l-3.603-2.074a1.464 1.464 0 0 0-1.478.002c-.46.266-.736.743-.736 1.274v4.148c0 .532.275 1.008.736 1.275a1.474 1.474 0 0 0 1.478.001zm-.675-5.528 3.603 2.074c.041.023.062.058.062.104 0 .046-.02.081-.062.105l-3.603 2.074a.12.12 0 0 1-.127 0 .114.114 0 0 1-.06-.105v-4.148c0-.045.02-.08.06-.104a.127.127 0 0 1 .064-.018c.021 0 .042.006.063.018z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default VideoPlayIcon;
