import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { ellipseText, secondsToTime } from '@room-match/shared-utils';
import { PauseIconTwoTone, PlayIconTwoTone, Typography } from '@room-match/shared-ui-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './MiniAudioPlayer.styles';

const SRC = '/audio1.mp3';
const MiniAudioPlayer = () => {
  const classes = useStyles();
  const audioElement = new Audio(SRC);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const audioRef = useRef(new Audio(SRC));
  const intervalRef = useRef<any>();
  const [duration, setDuration] = useState<number>(0);

  const onLoadMetaData = () => {
    setDuration(audioRef.current.duration);
  };

  const onEnded = () => {
    setIsPlaying(false);
    setTrackProgress(0);
    audioRef.current.currentTime = 0;
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 1000);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    audio.addEventListener('loadedmetadata', onLoadMetaData);
    audio.addEventListener('ended', onEnded);
  });

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audio.pause();
      clearInterval(intervalRef.current);
      audio.removeEventListener('loadedmetadata', onLoadMetaData);
      audio.removeEventListener('ended', onEnded);
    };
  }, []);

  const getCircularValue = useMemo(() => {
    return (trackProgress / duration) * 100;
  }, [trackProgress, duration]);

  return (
    <Box className={classes.miniAudioPlayer}>
      <Box className={classes.miniAudioPlayer__description}>
        <Typography fontSize={12}>{ellipseText('Sound Advice- Foxtel Radio', 20)}</Typography>
        {!isPlaying ? (
          <Typography fontSize={11} color="darkGrey">
            {secondsToTime(duration)}
          </Typography>
        ) : (
          <Typography fontSize={11} color="primary">
            Playing:{` `}
            <span className={classes.miniAudioPlayer__trackerPlaying}>{`${secondsToTime(
              trackProgress,
            )} / ${secondsToTime(duration)}`}</span>
          </Typography>
        )}
      </Box>
      {!isPlaying ? (
        <IconButton size="small" onClick={() => handlePlay()}>
          <PlayIconTwoTone />
        </IconButton>
      ) : (
        <Box className={classes.miniAudioPlayer__pauseContainer}>
          <CircularProgress
            variant="determinate"
            color="secondary"
            value={100}
            size={28}
            className={classes.circular__background}
          />
          <CircularProgress
            variant="determinate"
            color="primary"
            value={getCircularValue}
            size={28}
            className={classes.circular__foreground}
          />
          <Box className={classes.miniAudioPlayer__pauseIconContainer}>
            <IconButton size="medium" onClick={() => handleStop()}>
              <PauseIconTwoTone style={{ fontSize: 10 }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MiniAudioPlayer;
