import { Button, styled } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: '60px 0 25px',
  paddingTop: 20,
  gap: 15,
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'inline-block',
    height: 1,
    width: '102%',
    background: '#2d343e',
    position: 'absolute',
    top: -10,
    marginLeft: -10,
    marginRight: -10,
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  height: 40,
  border: '1px solid',
  borderRadius: 5,
  maxWidth: 153,
  background: COLORS.DARK,

  '& svg': {
    height: 16,
  },

  '&.confirm': {
    borderColor: COLORS.GREEN,

    '& svg path': {
      fill: COLORS.GREEN,
    },
  },
  '&.decline': {
    borderColor: COLORS.DANGER,
  },
}));
