import { styled, Button, Grid } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Filters = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  alignItems: 'center',
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  background: COLORS.BUTTON_LIGHT_GRAY,
  border: '1px solid',
  borderColor: COLORS.BUTTON_LIGHT_GRAY,
  height: 27,
  minWidth: 'unset',
  padding: '0 15px',
}));

export const FilterButtonActive = styled(Button)(({ theme }) => ({
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.PRIMARY,
  height: 27,
  minWidth: 'unset',
  padding: '0 15px',
}));
