import React from 'react';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import { ContactFlex, Container, TitleContainer, useStyles } from './ProjectDetailsAdditional.styles';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { formatBytes } from '@room-match/shared-utils';
import { DocumentFileIcon, DownloadIcon } from '@room-match/shared-ui-components';

type Props = {
  project: IProjectAttributes | undefined;
};
const ProjectDetailsAdditional: React.FC<Props> = ({ project }) => {
  const classes = useStyles();
  return (
    <Box style={{ marginTop: '20px' }}>
      <TitleContainer>
        <Typography variant="h5">Additional Details</Typography>
      </TitleContainer>
      <Container>
        <Typography variant="body2" style={{ paddingRight: '30px' }}>
          {project?.additional_details
            ? project?.additional_details
            : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales facilisis massa vitae porttitor. Curabitur consectetur leo eget eros molestie, sodales iaculis orci vestibulum. Donec vulputate, nibh et sodales ultricies, nisi lorem interdum enim, sed porttitor augue libero blandit nibh. Fusce consequat felis ut est ultrices convallis. Ut sit amet orci lacus. Praesent non consequat ligula. Fusce erat quam, sollicitudin eu tellus quis, ullamcorper euismod elit. Integer in metus non est elementum hendrerit ut quis urna.'}
        </Typography>
        <Grid container spacing={3} style={{ marginTop: '15px' }}>
          {project?.medium_attachments &&
            project?.medium_attachments.length > 0 &&
            project?.medium_attachments.map((medium) => (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ContactFlex>
                  <DocumentFileIcon />
                  <Box>
                    <Typography variant="body2">{medium.medium.file_name}</Typography>
                    <Typography variant="body2" className={classes.darkText}>
                      {formatBytes(medium.medium.file_size)}
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      window.open(medium.attachment);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </ContactFlex>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProjectDetailsAdditional;
