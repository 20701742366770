import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './ImageCarousel.css';
import { useStyles } from './ImageCarousel.styles';
import { Box } from '@material-ui/core';

import clsx from 'clsx';
import DecoratedComponent from './DecoratedComponent';
import { BackIcon, NextIcon } from 'components/Icons';
const BOX_HEIGHT = 100;
type Props = {
  images: {
    src: string;
    image: string;
    width: number;
    height: number;
  }[];
};
const ImageCarousel: React.FC<Props> = ({ images }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={images.length}
        visibleSlides={1}
        currentSlide={0}
        isIntrinsicHeight={true}
        infinite={true}
      >
        <Slider>
          {images.map((image, i) => (
            <Fragment>
              {image.width > image.height ? (
                <Slide index={i} classNameHidden={classes.imageHidden}>
                  <Image hasMasterSpinner={true} style={{ borderRadius: '8', objectFit: 'cover' }} src={image.src} />
                </Slide>
              ) : (
                <Slide index={i} classNameHidden={classes.imageHidden}>
                  <Image hasMasterSpinner={true} style={{ borderRadius: '8', objectFit: 'cover' }} src={image.src} />
                </Slide>
              )}
            </Fragment>
          ))}
        </Slider>
        <ButtonBack className={clsx(classes.button, classes.buttonBack)}>
          <BackIcon />
        </ButtonBack>
        <ButtonNext className={clsx(classes.button, classes.buttonNext)}>
          <NextIcon />
        </ButtonNext>
        <DecoratedComponent images={images} />
      </CarouselProvider>
      <Box className={classes.blurRight} />
      <Box className={classes.blurLeft} />
    </Box>
  );
};

export default ImageCarousel;
