import { Button, createStyles, makeStyles, styled } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    inactive: {
      opacity: 0.5,
    },
  }),
);

export const StatusTabs = styled('div')(({ theme }) => ({
  background: '#0b0f14',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  padding: '15px 20px 15px 35px',
  display: 'flex',
  gap: 45,

  '& > div': {
    cursor: 'pointer',
    '&:nth-child(5)': {
      marginLeft: 100,
    },
  },
}));

export const Tab = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 50,
  gap: 3,

  '& button': {
    padding: '5px 3px 0',
    '& .primary path': {
      fill: COLORS.PRIMARY,
    },
    '& .orange path': {
      fill: '#de7c30',
    },
    '& .red path': {
      fill: COLORS.DANGER,
    },
    '& .green path': {
      fill: COLORS.GREEN,
    },
  },

  '& svg': {
    height: 20,
    marginBottom: 5,
  },
}));

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
  gap: 15,

  '& .primary path': {
    fill: COLORS.PRIMARY,
  },

  '& button': {
    '& svg': {
      height: 17,
    },
    '&:nth-child(6)': {
      marginLeft: 'auto',
    },
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  color: '#fff',
  height: 42,
  padding: '0 25px',
}));
