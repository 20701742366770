import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    darkText: {
      color: '#6e767f',
    },
  }),
);

export const Container = styled('div')(({ theme }) => ({
  marginTop: '30px',
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'ecnter',
  padding: '20px 10px',
  borderBottom: 'solid 1px #2d343e',
  marginLeft: '-10px',
  marginRight: '-10px',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  margin: '20px 0px',
}));

export const ContactFlex = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  padding: '10px 20px',
  background: '#151b24',
  borderRadius: '8px',
  '& > *:first-child': {
    marginRight: '20px',
  },
  '& svg': {
    width: '30px',
    height: '30px',
  },
}));
