import { Button, createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    darkText: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      color: '#6e767f',
    },
    blueText: {
      color: '#3e8bff',
    },
  }),
);

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
}));

export const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  flexGrow: 1,
}));

export const ProjectDetails = styled('div')(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: '10px',
  minWidth: '200px',
  '& > *:last-child': {
    color: '#6e767f',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  background: '#10181f',
  height: '55px',
  border: 'solid 1px #3e8bff',
  width: '210px',
  marginLeft: 'auto',
  '& svg': {
    width: '20px',
  },
}));
