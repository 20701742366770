import { styled, Button, createStyles, makeStyles } from '@material-ui/core';
import { Typography } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    active: {
      opacity: '1!important',
      borderColor: COLORS.PRIMARY + '!important',
    },
    textInputCls: {
      '& .MuiOutlinedInput-multiline': {
        padding: 14,
        borderRadius: 5,
      },
      '& textarea': {
        fontSize: '14px!important',
      },
    },
    text__orange: {
      color: '#ffa63e',
    },
  }),
);

export const ExpandedContainer = styled('div')(({ theme }) => ({
  width: '100%',
  background: '#0b0f14',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  marginTop: -10,
  padding: '30px 20px 20px',
  zIndex: 1,
  position: 'relative',
  display: 'flex',
  gap: 15,

  [theme.breakpoints.down(1600)]: {
    flexDirection: 'column',

    '& > div': {
      width: '100%',
    },
  },
}));

export const LeftContainer = styled('div')(({ theme }) => ({
  width: '70%',
  height: 'auto',
}));

export const RightContainer = styled('div')(({ theme }) => ({
  width: '30%',
  background: '#0f151c',
  padding: 20,
  borderRadius: 8,
}));

export const TabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  marginBottom: 15,
}));

export const TabButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  border: '1px solid #454e5b',
  borderRadius: 5,
  height: 49,
  opacity: 0.5,
  fontSize: 16,
}));

export const TabContainer = styled('div')(({ theme }) => ({
  background: '#0f151c',
  width: '100%',
  padding: 20,
  borderRadius: 8,
}));

export const SendButton = styled(Button)(({ theme }) => ({
  maxWidth: 81,
  height: 36,
}));

export const HistoryLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 20,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 0 1px',
  paddingBottom: 10,
  marginBottom: 10,
}));

export const MessagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const Message = styled('div')(({ theme }) => ({
  background: '#1e2530',
  borderRadius: 8,
  padding: '10px 15px',
}));

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  marginTop: 15,

  '& > div': {
    width: '50%',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  height: 57,
  borderRadius: 8,
  color: '#fff',
  '&.reject': {
    background: COLORS.DANGER,
  },
  '&.submit': {
    background: COLORS.SUCCESS,
  },
}));
