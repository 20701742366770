import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const TwitterOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="17" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m18.94 2.83-1.707-.224.446-1.664-2.001.536A4.137 4.137 0 0 0 13.562.9a4.163 4.163 0 0 0-4.159 4.159v.539a9.967 9.967 0 0 1-7.295-3.78l-.465-.585-.425.615a4.2 4.2 0 0 0-.425.78 4.139 4.139 0 0 0-.18 2.661 4.21 4.21 0 0 0 .701 1.447l-.659.159.13.539a4.166 4.166 0 0 0 2.745 2.99l-.648.76.421.36a4.18 4.18 0 0 0 2.258.976 6.681 6.681 0 0 1-4.913 1.227l-.633-.09v.933l.238.166a11.061 11.061 0 0 0 6.341 1.983c2.972 0 5.766-1.157 7.868-3.258a11.053 11.053 0 0 0 3.259-7.868v-.554c0-.196-.014-.392-.041-.585l1.26-1.643zm-2.39 1.61c.04.202.062.41.062.619v.554c0 5.524-4.494 10.017-10.018 10.017a9.98 9.98 0 0 1-3.891-.783 7.795 7.795 0 0 0 4.83-2.656l.333-.39-.608-.608-.32.101a3.082 3.082 0 0 1-2.46-.272L5.69 9.603l-1.085-.085a3.059 3.059 0 0 1-2.564-1.816l1.569-.376-.881-.708a3.084 3.084 0 0 1-.947-3.476 11.064 11.064 0 0 0 8.176 3.58h.554V5.059a3.053 3.053 0 0 1 4.763-2.524l.21.143.625-.168-.276 1.03 1.058.14-.394.512.051.249z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default TwitterOutlinedIcon;
