import { Box, DialogContent, IconButton } from '@material-ui/core';
import { CloseIcon, CustomVideoPlayer, PlayIconTwoTone } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';
import { StyledDialog, Typography } from '@room-match/shared-ui-components';
import { useStyles } from './VideoGallery.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};
const VideoGallery: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <StyledDialog open={open} maxWidth="lg" fullWidth style={{ maxHeight: '95%', top: 20 }}>
      <DialogContent>
        {/* Header */}
        <Box className={classes.header}>
          {/* Title */}
          <Box>
            <Typography fontSize={13}>OLAY TV SPOT</Typography>
            <Typography color="mainGrey" fontSize={12}>
              02:35
            </Typography>
          </Box>
          {/* Artist */}
          <Box className={classes.header__artist}>
            <Typography fontSize={13}>Leonardo DiCaprio</Typography>
            <Typography color="mainGrey" fontSize={12}>
              Video 1 of 12
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 12 }} />
          </IconButton>
        </Box>

        {/* Video */}
        <Box className={classes.videoContainer}>
          <CustomVideoPlayer
            url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            startTime={10}
            endTime={20}
            thumbnail="/sample_person2.jpg"
            playIcon={<PlayIconTwoTone style={{ fontSize: 48 }} />}
          />
        </Box>

        {/* Video List */}
        <Box className={classes.videoList}>
          {Array.from({ length: 12 }).map((_, i) => (
            <Box className={classes.videoList__item} key={i}>
              <img
                src="/sample_person2.jpg"
                className={clsx(classes.videoList__img, {
                  [classes.videoList__img__selected]: i === 3,
                })}
              />

              <IconButton className={classes.videoList__playButton}>
                <PlayIconTwoTone fontSize="large" />
              </IconButton>
              <Box>
                <Typography fontSize={13} fontWeight={500}>
                  OLAY TV SPOT
                </Typography>
                <Typography fontSize={12} color="mainGrey">
                  02:35
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default VideoGallery;
