import { styled, Button, DialogContent, createStyles, makeStyles, TableHead, Table, TableRow } from '@material-ui/core';
import { StyledDialog } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    textInputCls: {
      '& .MuiOutlinedInput-multiline': {
        padding: 14,
        borderRadius: 5,
      },
      '& textarea': {
        fontSize: '14px!important',
      },
    },
    text__orange: {
      color: '#ffa63e',
    },
    tableContainerCls: {
      '&.MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    tableCellCls: {
      display: 'flex',
      gap: 5,
    },
    event: {
      display: 'flex',
      gap: 7,
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& svg': {
        height: 20,
      },
      '& .orange svg path': {
        fill: '#ffa63e',
      },
      '& .yellow svg path': {
        fill: COLORS.WARNING,
      },
      '& .green path': {
        fill: COLORS.SUCCESS,
      },
    },
    small__icon: {
      height: 14,
      marginRight: 2,
    },
  }),
);

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '1277px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 40px 50px!important',
  background: '#0d1117',
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  marginBottom: 20,
}));

export const TalentImage = styled('img')(({ theme }) => ({
  height: 59,
  width: 43,
  borderRadius: 5,
  objectFit: 'cover',
}));

export const HeaderInfo = styled('div')(({ theme }) => ({
  '& > p': {
    '&:first-child': {
      marginBottom: -5,
    },
    '&:last-child': {
      marginTop: 5,
    },
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  background: '#0d1117',
  borderTop: '1px solid #2c3645',
  borderRadius: 0,
  '& .MuiTableCell-root': {
    borderBottom: 0,
  },
}));

export const CustomTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    padding: '12px 24px 12px 16px',
  },
}));

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: '1px solid #2c3645',
  '& td': {
    padding: '10px 24px 10px 16px',
  },
}));
