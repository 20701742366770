import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { FilterButton, FilterButtonActive, Filters } from './Castings.styles';
import List from './List/List';

type Props = {
  view: string;
  projects: any;
  handleOpenProjectDetailModel: (project: IProjectAttributes) => void;
};

const FilterList: string[] = ['All', 'New', 'Revised', 'Viewed', 'Submitted', 'Dismissed'];

const Castings: React.FC<Props> = ({ view, projects, handleOpenProjectDetailModel }) => {
  const history = useHistory();
  const [activeFilter, setActiveFilter] = useState(['All']);

  const filter = (i: string) => {
    if (activeFilter.includes(i)) {
      const newFilters = activeFilter.filter((v) => v !== i);
      setActiveFilter(newFilters);
    } else setActiveFilter((k) => [...k, i]);
  };

  return (
    <>
      <Filters>
        Show:{' '}
        {FilterList.map((i) => (
          <>
            {activeFilter.includes(i) ? (
              <FilterButtonActive onClick={() => filter(i)}>{i}</FilterButtonActive>
            ) : (
              <FilterButton onClick={() => filter(i)}>{i}</FilterButton>
            )}
          </>
        ))}
      </Filters>
      {view === 'list' ? (
        <>
          {projects.map((data: any, index: any) => (
            <List key={index} data={data} handleOpenProjectDetailModel={handleOpenProjectDetailModel} />
          ))}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Castings;
