import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DotsIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="5" height="18" viewBox="0 0 5 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="lbfaw4m14a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M0 9a2.08 2.08 0 0 0 2.077 2.077A2.08 2.08 0 0 0 4.154 9a2.08 2.08 0 0 0-2.077-2.077A2.08 2.08 0 0 0 0 9zm0 6.923A2.08 2.08 0 0 0 2.077 18a2.08 2.08 0 0 0 2.077-2.077 2.08 2.08 0 0 0-2.077-2.077A2.08 2.08 0 0 0 0 15.923zM0 2.077a2.08 2.08 0 0 0 2.077 2.077 2.08 2.08 0 0 0 2.077-2.077A2.08 2.08 0 0 0 2.077 0 2.08 2.08 0 0 0 0 2.077z"
        fill="url(#lbfaw4m14a)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default DotsIconTwoTone;
