import { Box, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  ShareIcon,
  PriceTagIcon,
  FlagIcon,
  CloseIcon,
  StarGradientIconTwoTone,
  HeartIcon,
  MessageRoundIcon,
  Typography,
} from '@room-match/shared-ui-components';
import React from 'react';
import { StyledPaginationItem } from '../StyledElements/StyledPaginationItem';
import { useStyles } from './TalentProfileHeader.styles';

type Props = {
  onClose: () => void;
};
const TalentProfileHeader: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.headerContainer}>
      <Grid container spacing={4} alignItems="stretch">
        {/* Left */}
        <Grid item lg={2} md={3}>
          <Box className={classes.headerContainer} style={{ height: '100%' }}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth
              size="large"
              style={{ height: '100%' }}
            >
              Previous Talent
            </Button>
          </Box>
        </Grid>
        {/* Center */}
        <Grid item lg={8} md={6}>
          <Box className={classes.header__middle} style={{ height: '100%' }}>
            {/* Left */}
            <Box className={classes.shareProfileContainer}>
              <Tooltip title="Share Profile" arrow>
                <IconButton>
                  <ShareIcon width={12} height={12} style={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
              <Typography fontSize={15} style={{ color: '#677482' }}>
                Share Profile
              </Typography>
            </Box>

            {/* Right */}
            <Box className={classes.rightIconsContainer}>
              <IconButton>
                <PriceTagIcon width={12} height={12} style={{ fontSize: 16 }} />
              </IconButton>
              <IconButton>
                <MessageRoundIcon width={12} height={12} style={{ fontSize: 16 }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {/* Right */}
        <Grid item lg={2} md={3}>
          <Box display="flex" alignItems="center" gridGap={8} style={{ height: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              fullWidth
              style={{ height: '100%' }}
            >
              Next Talent
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalentProfileHeader;
