import React from 'react';
import {
  AllIconTwoTone,
  CheckedCircleIcon,
  ClockCircleIcon,
  CloseIcon,
  ResetToLeftIcon,
  SmallDetailIcon,
  Typography,
} from '@room-match/shared-ui-components';
import {
  CloseButton,
  CustomDialog,
  CustomDialogContent,
  CustomTable,
  CustomTableHead,
  CustomTableRow,
  Header,
  HeaderInfo,
  TalentImage,
  useStyles,
} from './JobLogModal.styles';
import { COLORS } from 'shared/constants/COLORS';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const mockLog = [
  {
    date: '15 June 22',
    time: '04:53 PM',
    name: 'James Smith',
    agency: 'Smith Agency',
    message: '',
    event: 'On Avail',
  },
  {
    date: '14 June 22',
    time: '04:53 PM',
    name: 'John Frank',
    agency: 'Casting Associate',
    message: '',
    event: 'Called Back',
  },
  {
    date: '13 June 22',
    time: '04:53 PM',
    name: 'Bruno Mark',
    agency: 'Casting Director',
    message: '',
    event: 'Auditioned',
  },
  {
    date: '13 June 22',
    time: '04:53 PM',
    name: 'Bruno Mark',
    agency: 'Casting Director',
    message: 'From Farmer to Farmer’s Friend',
    event: 'Role Change',
  },
  {
    date: '13 June 22',
    time: '04:53 PM',
    name: 'James Smith',
    agency: 'Smith Agency',
    message: 'Can he come tmrw any time after 3PM instead?',
    event: 'Reschedule Request',
  },
];

type Props = {
  data: any;
  open: boolean;
  onClose: (instance: boolean) => void;
};

const JobLogModal: React.FC<Props> = ({ data, open, onClose }) => {
  const classes = useStyles();
  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Header>
          <TalentImage src="/sample_person2.jpg" />
          <HeaderInfo>
            <Typography fontSize={24}>Job Log</Typography>
            <Typography fontSize={15}>
              {data.name} (SAG/AFTRA) - <span style={{ color: COLORS.PRIMARY }}>{data.role}</span>
            </Typography>
          </HeaderInfo>
        </Header>
        <TableContainer component={Paper} className={classes.tableContainerCls}>
          <CustomTable size="small" aria-label="a dense table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <Typography fontSize={15}>Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={15}>Actioned By</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={15}>Details / Messages</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography fontSize={15}>Event</Typography>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {mockLog.map((row) => (
                <CustomTableRow>
                  <TableCell scope="row">
                    <Typography fontSize={15} color="mainGrey" className={classes.tableCellCls}>
                      {row.date} - {row.time}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" className={classes.tableCellCls}>
                    <Typography fontSize={15} color="primary">
                      {row.name}
                    </Typography>
                    <Typography fontSize={15} color="mainGrey">
                      ({row.agency})
                    </Typography>
                  </TableCell>
                  <TableCell scope="row">
                    <Typography
                      fontSize={15}
                      className={row.event === 'Reschedule Request' ? classes.text__orange : ''}
                    >
                      {row.event === 'Reschedule Request' && <SmallDetailIcon className={classes.small__icon} />}
                      {row.message}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="right">
                    <Typography fontSize={15} className={classes.event}>
                      {row.event === 'On Avail' ? (
                        <AllIconTwoTone className="orange" />
                      ) : row.event === 'Called Back' ? (
                        <ResetToLeftIcon className="yellow" />
                      ) : row.event === 'Reschedule Request' ? (
                        <ClockCircleIcon className="yellow" />
                      ) : (
                        <CheckedCircleIcon className="green" />
                      )}
                      {row.event}
                    </Typography>
                  </TableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default JobLogModal;
