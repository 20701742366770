import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.customBlack.dark,
    },
    cardFullHeight: {
      height: 250,
    },
    contentContainer: {
      marginTop: 12,
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textPrimary: {
      color: theme.palette.primary.main,
    },
    socialIconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    socialIcon: {
      fontSize: 18,
    },
    cardsContainer: {
      marginTop: '1.25rem',

      '& .MuiCardContent-root': {
        paddingBottom: 16,
      },
    },
    resumeContainer: {
      marginTop: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resumeItemContainer: {
      padding: theme.spacing(0.9, 0),
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    skillsContainer: {
      marginTop: 8,
      display: 'flex',
      // justifyContent: 'space-around',
      gap: 32,
      // flexWrap: 'wrap',
    },
    skillsItemContainer: {
      // width: '100%',
      padding: theme.spacing(0.5, 1),
      borderRadius: 4,
      backgroundColor: theme.palette.customBlack.light,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 24,
    },
  }),
);
