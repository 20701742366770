import { Button, DialogContent, Grid, IconButton } from '@material-ui/core';
import React from 'react';
import { StyledDialog, Typography, CloseIcon } from '@room-match/shared-ui-components';
import { useStyles } from './SizeCard.styles';

type Props = {
  profile: any;
  open: boolean;
  onClose: () => void;
};
const SizeCard: React.FC<Props> = ({ profile, open, onClose }) => {
  const classes = useStyles();
  return (
    <StyledDialog open={open} onClose={onClose} className={classes.dialog} maxWidth="md" fullWidth>
      <DialogContent>
        {/* Container */}
        <div className={classes.sizeCardContainer}>
          {/* Image */}
          <div className={classes.imageContainer}>
            <img src="/sample_person2.jpg" className={classes.image} />
          </div>
          {/* Details */}
          <div className={classes.details}>
            {/* Header */}
            <div className={classes.details__header}>
              {/* Text */}
              <div>
                <Typography variant="heading" fontWeight={500} style={{ lineHeight: 1 }}>
                  {`${profile.talent.first_name} ${profile.talent.last_name}`}
                </Typography>
                <Typography color="mainGrey">SAG/AFTRA - Los Angeles</Typography>
              </div>
              {/* Close Button */}
              <IconButton onClick={onClose} size="small">
                <CloseIcon style={{ fontSize: 12 }} />
              </IconButton>
            </div>
            {/* Agency */}
            <div className={classes.details__agencyContainer}>
              <Typography>Smith Agency LA</Typography>
            </div>
            {/* Description */}
            <div className={classes.details__description}>
              {/* Division */}
              <div className={classes.description__division}>
                <Typography fontSize={14} color="primary">
                  Commercial Divison
                </Typography>
                <Typography fontSize={12}>(310) 345-6789</Typography>
                <Typography fontSize={12}>Los Angeles, CA</Typography>
              </div>
              {/* Rep */}
              <div className={classes.description__rep}>
                <Typography fontSize={14} color="primary">
                  Rep: Tom Jones
                </Typography>
                <Typography fontSize={12}>(310) 345-6789</Typography>
                <Typography fontSize={12}>tomj@smith.com</Typography>
              </div>
              {/* Logo */}
              <div className={classes.description__logo}>
                <img src="/SMITH_LOGO.png" />
              </div>
            </div>
            {/* Statistics */}
            <div className={classes.details__statistics}>
              <Grid container spacing={4}>
                {/* Left */}
                <Grid item lg={6}>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Height</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.height || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Weight</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.weight || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Hair Color</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.hair_color || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Eye Color</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.eye_color || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Coat Size</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.coat_size || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Pants</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.pants_size || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Chest</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.chest_size || 'N/A'}</Typography>
                  </div>
                </Grid>

                {/* Right */}
                <Grid item lg={6}>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Waist</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.waist || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Inseam Leg</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.inside_leg || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Outseam Leg</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.outside_leg || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Neck Size</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.neck_size || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Sleeve Size</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.sleeves || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Hat Size</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.hat || 'N/A'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Glove Size</Typography>
                    <Typography fontSize={13}>{profile.talent.statistics?.gloves || 'N/A'}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Generate */}
            <div className={classes.generateContainer}>
              <Button variant="contained" color="primary" fullWidth size="large">
                Download Sizes as PDF
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default SizeCard;
