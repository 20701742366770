import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import React from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { SIDEBAR_ITEMS } from 'shared/constants/SIDEBAR_ITEMS';
import { selectUserState } from 'shared/redux/slicers/user.slicer';
import { toggleTheme } from 'shared/redux/slicers/theme.slicer';
import { tokenService } from 'shared/services/tokenService';
import { Backdrop, CastingAppLogo, MoonTwoTone, SunIconTwoTone } from '@room-match/shared-ui-components';

import { useStyles, CustomSwitch, useMainListeItemStyle } from './Sidebar.styles';
// import { RootState } from 'app/rootReducer';
// import { PrimaryImageState } from 'features/talent/primaryImage/primaryImageTypes';
// import { ProfileState } from 'features/talent/profileTypes';

type Props = {
  isMobileDrawerOpen?: boolean;
  handleMobileDrawer: () => void;
  isDrawerCollapse?: boolean;
  toggleDrawerCollapse: () => void;
};

const { getUser } = tokenService();

const Sidebar: React.FC<Props> = ({
  isMobileDrawerOpen,
  handleMobileDrawer,
  isDrawerCollapse,
  toggleDrawerCollapse,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const listItemStyle = useMainListeItemStyle();
  const [selectedMenu, setSelectedMenu] = React.useState('');
  const { isLoading, user } = useSelector(selectUserState);
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const myAccount: MyAccountState = useSelector((state: RootState) => state.myAccount);
  // const primaryImage: PrimaryImageState = useSelector((state: RootState) => state.primaryImage);
  // const profile: ProfileState = useSelector((state: RootState) => state.profile);

  // const displayImage = () => {
  //   if (primaryImage.model.image.length > 0) {
  //     return primaryImage.model.image;
  //   }

  //   return profile.profiles.find((x) => x.isPrimary)?.primaryImage || '';
  // };

  const handleLogOut = async () => {
    // await dispatch(userLogout());
    // queryClient.clear();
    // history.push(ROUTES.LOGIN);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleNavItemHover = (over: boolean) => {
    // if (over) {
    //   if (isDrawerCollapse) {
    //     setCurrentCollapseState(drawerCollapse);
    //     setDrawerCollapse(false);
    //   }
    // } else {
    //   setDrawerCollapse(currentCollapseState);
    // }
  };

  const drawer = (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <Link to={ROUTES.AGENCY.DASHBOARD}>
          <CastingAppLogo style={{ height: '34px', width: '34px', marginLeft: '24px' }} />
        </Link>
      </div>
      <List
        className={classes.menuList}
        onMouseEnter={() => handleNavItemHover(true)}
        onMouseLeave={() => handleNavItemHover(false)}
      >
        {SIDEBAR_ITEMS.map((item) => {
          return (
            <ListItem
              button
              key={item.name}
              selected={selectedMenu === item.name}
              disabled={!item.link}
              component={Link}
              to={item.link || ''}
              onClick={() => setSelectedMenu(item.name)}
              classes={listItemStyle}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
      </List>
      <div
        className={classes.myAccountContainer}
        onClick={() => {
          setSelectedMenu('');
        }}
      >
        <div>
          <Box className={classes.switchContent}>
            {theme.palette.type === 'dark' ? (
              <MoonTwoTone style={{ marginLeft: '7px' }} />
            ) : (
              <SunIconTwoTone style={{ marginLeft: '7px' }} />
            )}
            <Typography variant="body2">{theme.palette.type === 'dark' ? 'Studio' : 'Daylight'}</Typography>
            <FormControlLabel control={<CustomSwitch />} label="" onClick={() => dispatch(toggleTheme())} />
          </Box>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Hidden xsUp implementation="css">
        <Drawer
          variant="temporary"
          open={isMobileDrawerOpen}
          onClose={handleMobileDrawer}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isMobileDrawerOpen,
            [classes.drawerClose]: !isMobileDrawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isMobileDrawerOpen,
              [classes.drawerClose]: !isMobileDrawerOpen,
            }),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: !isDrawerCollapse,
            [classes.drawerClose]: isDrawerCollapse,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: !isDrawerCollapse,
              [classes.drawerClose]: isDrawerCollapse,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Backdrop isLoading={isLoading} />
    </>
  );
};

export default Sidebar;
