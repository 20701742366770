import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MessagesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="23" height="21" viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.559.542a3.242 3.242 0 0 1 3.233 3.055l.005.184v10.44a3.242 3.242 0 0 1-3.055 3.234l-.183.005-8.874-.031-4.204 3.244a.811.811 0 0 1-1.298-.521l-.01-.114-.022-2.579-1.063.001A3.243 3.243 0 0 1 .87 14.586l-.015-.18-.005-.184V3.78A3.242 3.242 0 0 1 3.905.547l.183-.005h15.47zm0 1.624H4.089c-.842 0-1.534.646-1.609 1.468l-.006.147v10.44c0 .842.646 1.535 1.467 1.609l.147.007h1.868c.411 0 .752.306.805.703l.007.101.014 1.747 3.09-2.382a.812.812 0 0 1 .389-.162l.107-.007h9.19c.841 0 1.534-.647 1.609-1.468l.006-.147V3.78c0-.89-.724-1.615-1.614-1.615z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default MessagesIcon;
