import { Input, InputProps, PaperPlaneIconTwoTone } from '@room-match/shared-ui-components';

import React from 'react';

const MessageInput = (props: InputProps) => {
  return (
    <Input
      name="general_company_email"
      variant="outlined"
      type="email"
      placeholder="Write message here…"
      margin={'normal'}
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{ tabIndex: 1 }}
      InputProps={{ endAdornment: <PaperPlaneIconTwoTone style={{ fontSize: 14 }} /> }}
      style={{ marginTop: 0, height: '20' }}
      size="small"
      {...props}
    />
  );
};

export default MessageInput;
