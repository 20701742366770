import { Box, Button, DialogContent, Grid, IconButton, Link, Snackbar } from '@material-ui/core';
import React from 'react';

import useCopyClipboard from 'react-use-clipboard';

import { useStyles } from './TrainingExperience.styles';
import { useReportPreviewDialog } from 'shared/theme/styles/reportPreviewDialog';
import { Typography } from '@room-match/shared-ui-components';

type Props = {
  key?: string;
};
const TrainingExperience: React.FC<Props> = ({ key }) => {
  const classes = useStyles();
  const sectionClasses = useReportPreviewDialog();

  return (
    <Grid item>
      <Grid item className={sectionClasses.heading}>
        <Typography fontSize={25} color="primary">
          Describe Training/Experience (or provide resume):
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={15}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales facilisis massa vitae porttitor.
          Curabitur consectetur leo eget eros molestie, sodales iaculis orci vestibulum. Donec vulputate, nibh et
          sodales ultricies, nisi lorem interdum enim, sed porttitor augue libero blandit nibh. Fusce consequat felis ut
          est ultrices convallis. Ut sit amet orci lacus. Praesent non consequat ligula. Fusce erat quam, sollicitudin
          eu tellus quis, ullamcorper euismod elit. Integer in metus non est elementum hendrerit ut quis urna.
        </Typography>
      </Grid>
      <Grid item className={sectionClasses.one__column}>
        <Grid item>
          <Typography color="primary" fontSize={18}>
            Link to Internet Listing of Performer Credits:
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={15}>https://examplelink.com</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrainingExperience;
