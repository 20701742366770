export const CONFIG = {
  isDevelopment: process.env.NODE_ENV === 'development',
  AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL || '',
  TALENT_API_URL: process.env.REACT_APP_TALENT_API_URL || '',
  CASTING_API_URL: process.env.REACT_APP_CASTING_API_URL || '',
  AGENCY_API_URL: process.env.REACT_APP_AGENCY_API_URL || '',
  UPLOAD_API_URL: process.env.REACT_APP_UPLOAD_API_URL || '',
  GRAPHQL_API_URL: process.env.REACT_APP_GRAPHQL_API_URL || '',
  APP_IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
  GOOGLE_APP_ID: process.env.REACT_APP_GOOGLE_APP_ID || '',
  FACEBOOK_APP_ID: process.env.REACT_APP_FB_APP_ID || '',
  TALENT_APP_URL: process.env.REACT_APP_TALENT_APP_URL || '',
  AGENCY_APP_URL: process.env.REACT_APP_AGENCY_APP_URL || '',
};
