import { IconButton } from '@material-ui/core';
import {
  AllIconTwoTone,
  CheckedCalendarIcon,
  CheckedCircleIcon,
  ClockCircleIcon,
  DeclineIconTwoTone,
  InformationIconTwoTone,
  LikeIconTwoTone,
  MagnifyingGlassIconTwoTone,
  MessageRoundIcon,
  NoShowIconTwoTone,
  PriceTagIcon,
  Typography,
} from '@room-match/shared-ui-components';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { COLORS } from 'shared/constants/COLORS';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import { ActionButton, ActionsContainer, StatusTabs, Tab, useStyles } from './SelfTapes.styles';
import List from '../List/List';

const mockData = [
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Confirmed Submission',
    action_time: '',
    message: '',
    message_status: 'success',
    remarks: '',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Talent Declined Request',
    action_time: '',
    message: 'I am sick right now',
    message_status: 'danger',
    remarks: 'Talent is sick right now',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Decline forwarded',
    action_time: '',
    message: 'I am sick right now',
    message_status: 'danger',
    remarks: 'Talent is sick right now',
  },
  {
    name: 'Ronald Welch',
    number: '(310) 310-3100',
    email: 'henryfox@gmail.com',
    agency: 'AHC Casting',
    project: 'Coke Cola',
    role: "Farmer's Friend",
    type: 'ST Request',
    audition_time: '3:00PM PST',
    audition_date: '24 Aug 2023',
    status: 'Review Self Tape',
    action_time: '',
    message: '',
    remarks: '',
  },
];

const tabs = [
  {
    name: 'requested',
    header: 'Requested',
    number: 60,
    icon: <AllIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'confirmed',
    header: 'Confirmed',
    number: 45,
    icon: <CheckedCircleIcon className="green" />,
    component: <div>All</div>,
  },
  {
    name: 'declined',
    header: 'Declined',
    number: 5,
    icon: <DeclineIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'no_response',
    header: 'No Response',
    number: 10,
    icon: <InformationIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'for_review',
    header: 'For Review',
    number: 30,
    icon: <MagnifyingGlassIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'rejected',
    header: 'Rejected',
    number: 10,
    icon: <DeclineIconTwoTone />,
    component: <div>All</div>,
  },
  {
    name: 'submitted',
    header: 'Submitted',
    number: 15,
    icon: <LikeIconTwoTone />,
    component: <div>All</div>,
  },
];

type Props = {
  tab: string;
};

const SelfTapes: React.FC<Props> = ({ tab }) => {
  const { childTab } = useParams() as { childTab: string };
  const history = useHistory();
  const classes = useStyles();

  const getActiveTab = useMemo(() => {
    return tabs.find((_tab) => _tab.name === childTab)?.component;
  }, [childTab]);

  const handleRequestTabChange = (tab: string, childTab: string) => {
    history.push(navigateToRoute.toWorksheetChildTab(tab, childTab));
  };

  return (
    <div>
      <StatusTabs>
        {tabs.map((i) => {
          return (
            <Tab className={i.name !== childTab ? classes.inactive : ''}>
              <IconButton onClick={() => handleRequestTabChange(tab, i.name)}>{i.icon}</IconButton>
              <Typography>{i.number}</Typography>
              <Typography color="mainGrey" fontSize={12}>
                {i.header}
              </Typography>
            </Tab>
          );
        })}
      </StatusTabs>
      <ActionsContainer>
        <Typography fontSize={18}>0 Talent Selected</Typography>
        <ActionButton color="secondary" startIcon={<LikeIconTwoTone />} className={classes.inactive}>
          Submit Selected
        </ActionButton>
        <ActionButton color="secondary" startIcon={<DeclineIconTwoTone />} className={classes.inactive}>
          Reject Selected
        </ActionButton>
        <ActionButton color="secondary" startIcon={<LikeIconTwoTone />} className={classes.inactive}>
          Message
        </ActionButton>
        <ActionButton color="secondary" startIcon={<PriceTagIcon className="primary" />} className={classes.inactive}>
          Tab
        </ActionButton>
        <ActionButton color="secondary" className={classes.inactive}>
          Clear Selected
        </ActionButton>
        <ActionButton color="secondary">Clear All Completed</ActionButton>
      </ActionsContainer>
      <List data={mockData} tab={tab} />
    </div>
  );
};

export default SelfTapes;
