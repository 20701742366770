import React, { useState } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { BackIcon, Typography } from '@room-match/shared-ui-components';

import { useStyles } from './RegistrationForm.styles';

import { Stepper } from 'components/Stepper';
import { ProfileSelection } from './ProfileSelection';
import { Success } from './Success';

import { CompanyInformation } from './CompanyInformation';
import { Division } from './Division';
import { Staff } from './Staff';
import { Industry } from './Industry';

const RegistrationForm = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(7);
  const [profileType, setProfileType] = useState('adult');

  const next = () => {
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid className={classes.container}>
      {profileType === 'manager' && activeStep > 4 ? (
        ''
      ) : activeStep > 4 ? (
        ''
      ) : (
        <Grid item className={classes.header}>
          <Grid item>
            <Typography fontSize={18}>You have been verified, please continue the registration below</Typography>
          </Grid>
          <Grid item className={classes.stepperContainer}>
            <Stepper steps={6} active={activeStep} />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        className={`${classes.body} ${
          profileType === 'adult' && activeStep > 3 ? classes.onlyBody : activeStep > 4 ? classes.onlyBody : ''
        } ${activeStep > 4 ? classes.success : ''}`}
      >
        {activeStep === 0 ? (
          <ProfileSelection setSteps={setSteps} setProfileType={setProfileType} profileType={profileType} />
        ) : activeStep === 1 ? (
          <CompanyInformation />
        ) : activeStep === 2 ? (
          <Division />
        ) : activeStep === 3 ? (
          <Staff />
        ) : activeStep === 4 ? (
          <Industry />
        ) : (
          <Success setActiveStep={setActiveStep} activeStep={activeStep} />
        )}
      </Grid>
      <>
        {activeStep > 4 ? (
          ''
        ) : (
          <Grid item className={classes.footer}>
            {activeStep > 0 && activeStep < 5 && (
              <IconButton className={classes.backIconButton} onClick={prev}>
                <BackIcon />
              </IconButton>
            )}
            {activeStep < 5 && (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                className={classes.buttonCls}
                onClick={() => {
                  next();
                }}
              >
                Continue
              </Button>
            )}
          </Grid>
        )}

        {/* <Backdrop isLoading={isLoading ? isLoading : isLoadingCreateProfile ? isLoadingCreateProfile : false} /> */}
      </>
    </Grid>
  );
};

export default RegistrationForm;
