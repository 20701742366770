import { DialogContent, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './TalentProfile.styles';
import TalentProfileHeader from './TalentProfileHeader/TalentProfileHeader';
import TalentProfileMedia from './TalentProfileMedia/TalentProfileMedia';
import TalentDetails from './TalentDetails/TalentDetails';
import TalentSkillList from './TalentSkillList/TalentSkillList';
import TalentProfileDetails from './TalentProfileDetails/TalentProfileDetails';
import TalentProfileCard from './TalentProfileCard/TalentProfileCard';
import { ResumeMediaGallery } from 'components/ResumeMediaGallery';
import { useDispatch, useSelector } from 'react-redux';
import {
  onCloseImageGallery,
  onCloseResumeGallery,
  onCloseSizeCard,
  onCloseVideoGallery,
  selectTalentProfileState,
} from 'shared/redux/slicers/talentProfile.slicer';
import { useAppSelector } from 'shared/redux/store';
import { SizeCard } from 'components/SizeCard';
import ImageGallery from 'components/TalentGalleries/ImageGallery/ImageGallery';
import VideoGallery from 'components/TalentGalleries/VideoGallery/VideoGallery';
import { StyledDialog } from '@room-match/shared-ui-components';

type Props = {
  data: any;
  open: boolean;
  onClose: () => void;
};
const TalentProfile: React.FC<Props> = ({ data, open, onClose }) => {
  const classes = useStyles();
  const { isResumeGalleryOpen, isSizeCardOpen, isImageGalleryOpen, isVideoGalleryOpen } = useAppSelector(
    ({ talentProfile }) => talentProfile,
  );
  const dispatch = useDispatch();

  const handleResumeGalleryClose = () => {
    dispatch(onCloseResumeGallery());
  };

  const handleCloseSizeCard = () => {
    dispatch(onCloseSizeCard());
  };

  const handleCloseImageGallery = () => {
    dispatch(onCloseImageGallery());
  };

  const handleCloseVideoGallery = () => {
    dispatch(onCloseVideoGallery());
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      // maxWidth="xl"
      // fullWidth
      // className={classes.profileDialog}
      classes={{ paper: classes.profileDialog_Container }}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent className={classes.dialogContaier}>
        {/* Header */}
        <TalentProfileHeader onClose={onClose} />
        {/* Content */}
        <div className={classes.contentContainer}>
          <Grid container spacing={4}>
            {/* Talent Profile Media */}
            <Grid item lg={2} md={3}>
              <TalentProfileMedia profile={data} />
            </Grid>
            {/* Talent Profile Details */}
            <Grid item lg={10} md={9}>
              {/* Talent Details */}
              <TalentDetails profile={data} />

              {/* Cards */}
              <TalentProfileCard />

              {/* Profile Details */}
              <TalentProfileDetails />
              {/* Skills List */}
              <TalentSkillList />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      {/* Resume Media Gallery */}
      <ResumeMediaGallery open={isResumeGalleryOpen} onClose={handleResumeGalleryClose} />
      <SizeCard profile={data} open={isSizeCardOpen} onClose={handleCloseSizeCard} />
      <ImageGallery open={isImageGalleryOpen} onClose={handleCloseImageGallery} />
      <VideoGallery open={isVideoGalleryOpen} onClose={handleCloseVideoGallery} />
    </StyledDialog>
  );
};

export default TalentProfile;
