import { Typography } from '@room-match/shared-ui-components';
import React, { useState } from 'react';
import {
  LeftContainer,
  TalentImage,
  Body,
  RightContainer,
  HeadContainer,
  DownloadButton,
  AdditionalInfoContainer,
  FlexColumn,
  AgencyLogo,
  CustomTable,
  CustomTableRow,
  FlexRow,
} from './SizeCard.styles';

const SizeCard = () => {
  return (
    <Body>
      <LeftContainer>
        <TalentImage src="/sample_person2.jpg" />
      </LeftContainer>
      <RightContainer>
        <HeadContainer>
          <Typography fontSize={25} style={{ marginBottom: -5 }}>
            Ronald Welch
          </Typography>
          <Typography fontSize={15} color="mainGrey">
            SAG/AFTRA - Los Angeles
          </Typography>
          <DownloadButton fullWidth variant="contained" color="primary">
            Download Sizes as PDF
          </DownloadButton>
        </HeadContainer>
        <Typography>Smith Agency LA</Typography>
        <AdditionalInfoContainer>
          <FlexColumn>
            <Typography fontSize={15} color="primary">
              Commercial Division
            </Typography>
            <Typography fontSize={15}>(310) 345-6789</Typography>
            <Typography fontSize={15}>Los Angeles, CA</Typography>
          </FlexColumn>
          <FlexColumn>
            <Typography fontSize={15} color="primary">
              Rep: Tom Jones
            </Typography>
            <Typography fontSize={15}>(310) 345-6789</Typography>
            <Typography fontSize={15}>tomj@smith.com</Typography>
          </FlexColumn>
          <AgencyLogo src="/SMITH_LOGO.png" />
        </AdditionalInfoContainer>
        <FlexRow>
          <CustomTable>
            <CustomTableRow>
              <Typography fontSize={13}>Height</Typography>
              <Typography fontSize={13}>5' 9</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Weight</Typography>
              <Typography fontSize={13}>170 lbs</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Hair Color</Typography>
              <Typography fontSize={13}>Brown</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Eye Color</Typography>
              <Typography fontSize={13}>Blue</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Coat Size</Typography>
              <Typography fontSize={13}>120</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Pants</Typography>
              <Typography fontSize={13}>100</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Chest</Typography>
              <Typography fontSize={13}>50</Typography>
            </CustomTableRow>
          </CustomTable>
          <CustomTable>
            <CustomTableRow>
              <Typography fontSize={13}>Waist</Typography>
              <Typography fontSize={13}>60</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Inseam Leg</Typography>
              <Typography fontSize={13}>70</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Outseam Leg</Typography>
              <Typography fontSize={13}>20</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Neck Size</Typography>
              <Typography fontSize={13}>30</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Sleeve Size</Typography>
              <Typography fontSize={13}>40</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Hat Size</Typography>
              <Typography fontSize={13}>50</Typography>
            </CustomTableRow>
            <CustomTableRow>
              <Typography fontSize={13}>Glove Size</Typography>
              <Typography fontSize={13}>25</Typography>
            </CustomTableRow>
          </CustomTable>
        </FlexRow>
      </RightContainer>
    </Body>
  );
};

export default SizeCard;
