import { Grid, Tab } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  ActiveIndicatorIcon,
  Avatar,
  EditIconTwoTone,
  ITab,
  ProjectDetailsIcon,
  StyledMenu,
  Typography,
} from '@room-match/shared-ui-components';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import { Summary } from '../Tabs';
import {
  ActiveText,
  Body,
  CustomMenuItem,
  CustomMenuItemChildContainer,
  CustomTabs,
  FlexRow,
  ItemHeader,
  LightBulb,
  MoreIcon,
  ProjectDetails,
  ProjectDetailsButton,
} from './ListView.styles';

const tabs: ITab[] = [
  {
    name: 'summary',
    header: 'Summary',
    component: <div>Hello</div>,
    disabled: false,
  },
  {
    name: 'roles',
    header: 'Roles',
    component: <div />,
    disabled: false,
  },
  {
    name: 'in_studio',
    header: 'In Studio',
    component: <div />,
    disabled: false,
  },
  {
    name: 'self_tapes',
    header: 'Self-Tapes',
    component: <div />,
    disabled: false,
  },
  {
    name: 'worksheet',
    header: 'Worksheet',
    component: <div />,
    disabled: false,
  },
];

type Props = {
  data: any;
  handleOpenProjectDetailModel: (project: IProjectAttributes) => void;
};

const ListView: React.FC<Props> = ({ data, handleOpenProjectDetailModel }) => {
  const [tab, setTab] = useState<string>('summary');
  const history = useHistory();
  const [menuEl, setMenuEl] = useState<Element | null>(null);

  const handleCloseMenu = () => setMenuEl(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setMenuEl(event.currentTarget);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(navigateToRoute.toProjectTab(data.id, newValue));
    setTab(newValue);
  };

  const handleRedirectEditProject = () => {
    // history.push(navigateToRoute.toEditProject(data.id));
  };

  const getActiveTab = useMemo(() => {
    const currentTab = tabs.find((_tab) => _tab.name === tab);
    if (currentTab?.name === 'summary') {
      currentTab.component = <Summary data={data} />;
      return currentTab?.component;
    }

    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab, data]);

  return (
    <Body>
      <ItemHeader>
        <FlexRow style={{ width: 350 }}>
          <Avatar title={data.name} style={{ height: 50, width: 50, minWidth: 50, minHeight: 50 }} />

          <ProjectDetails>
            <Typography fontSize={15}>{data.name}</Typography>
            <Typography fontSize={13} color="mainGrey">
              {data.sag_aftra_contract_type}
            </Typography>
            <Typography fontSize={13} color="primary">
              {data.agency}
            </Typography>
          </ProjectDetails>
        </FlexRow>
        <CustomTabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.header}
              disabled={tab.disabled}
              value={tab.name}
              style={{ textTransform: 'capitalize', fontSize: '15px' }}
            />
          ))}
        </CustomTabs>
        <FlexRow style={{ marginLeft: 'auto' }}>
          <ProjectDetailsButton onClick={() => handleOpenProjectDetailModel(data)}>
            <ProjectDetailsIcon />
            <Typography fontSize={14}>Project Details</Typography>
          </ProjectDetailsButton>
          <LightBulb>
            <ActiveIndicatorIcon />
            <ActiveText fontSize={14}>Active</ActiveText>
          </LightBulb>
          <MoreIcon onClick={handleOpenMenu}>
            <MoreVert style={{ color: '#6e767f' }} />
          </MoreIcon>
          <StyledMenu
            id="simple-menu"
            anchorEl={menuEl}
            open={Boolean(menuEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            getContentAnchorEl={null}
          >
            <CustomMenuItem onClick={() => handleRedirectEditProject()}>
              <CustomMenuItemChildContainer>
                <EditIconTwoTone style={{ fontSize: 16 }} />
                <Typography style={{ fontSize: 13 }}>Edit Project</Typography>
              </CustomMenuItemChildContainer>
            </CustomMenuItem>
          </StyledMenu>
        </FlexRow>
      </ItemHeader>
      <Grid item>{getActiveTab}</Grid>
    </Body>
  );
};

export default ListView;
