import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},

    textTitle: {
      marginBottom: '10px',
      fontWeight: 'bold',
      color: '#3e8bff',
    },
  }),
);

export const TitleContainer = styled('div')(({ theme }) => ({
  padding: '20px 10px',
  borderBottom: 'solid 1px #2d343e',
  marginLeft: '-10px',
  marginRight: '-10px',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  margin: '20px 0px',
}));
