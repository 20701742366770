import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      gap: '5em',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
      },

      '& > div': {
        width: 290,

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '5em',
        position: 'relative',

        '&::before': {
          content: '""',
          background: '#2d343e',
          height: 1,
          position: 'absolute',
          width: '104%',
          marginLeft: '-2%',
          bottom: '-2px',

          [theme.breakpoints.down('md')]: {
            width: '102%',
            marginLeft: '-1%',
          },
        },
      },
    },
  }),
);
