import { Box, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import {
  CheckBox,
  CheckMarkIconTwoTone,
  DeclineIconTwoTone,
  DragIcon,
  InformationIconTwoTone,
  LikeIconTwoTone,
  RequestedIconTwoTone,
  Typography,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';
import { SelfTapesTab } from 'shared/enums/SelfTapesTab';
import { useStyles } from './SelfTapesTabsAction.styles';

type Props = {
  tab: SelfTapesTab;
};
const SelfTapesTabsActions: React.FC<Props> = ({ tab }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <Box
      className={clsx(classes.requests__action, {
        [classes.requests__action__approvedAction]: true,
      })}
    >
      {tab === SelfTapesTab.NEW && (
        <Box className={classes.requests__action__new}>
          <CheckBox size="small"></CheckBox>
        </Box>
      )}

      {tab === SelfTapesTab.FOR_REVIEW && (
        <Box className={classes.requests__action__review}>
          <CheckBox size="small"></CheckBox>
        </Box>
      )}

      {tab === SelfTapesTab.REJECTED && (
        <Box className={classes.requests__action__approved}>
          <Box className={classes.requests__action__approved__dragIcon}>
            <IconButton size="small">
              <DragIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.requests__action__approved__likeIcon}>
            <IconButton size="small">
              <LikeIconTwoTone fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}

      {tab === SelfTapesTab.APPROVED && (
        <Box className={classes.requests__action__rejected}>
          <IconButton size="small" style={{ border: '1px solid #43ba01', padding: '8px ', borderRadius: 4 }}>
            <LikeIconTwoTone style={{ fontSize: isLargeScreen ? 18 : 14 }} />
          </IconButton>
        </Box>
      )}

      {tab === SelfTapesTab.REQUESTED && (
        <Box className={classes.requests__action__item}>
          <RequestedIconTwoTone fontSize="small" />
          <Typography fontSize={14}>4h 23m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.CONFIRMED && (
        <Box className={classes.requests__action__item}>
          <CheckMarkIconTwoTone fontSize="small" />
          <Typography fontSize={14}>15m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.DECLINED && (
        <Box className={classes.requests__action__item}>
          <DeclineIconTwoTone fontSize="small" />
          <Typography fontSize={14}>16m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.NO_RESPONSe && (
        <Box className={classes.requests__action__item}>
          <InformationIconTwoTone fontSize="small" />
          <Typography fontSize={14}>4h 23m</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SelfTapesTabsActions;
