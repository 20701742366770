import React, { useEffect, useState } from 'react';
import { InputAdornment, Box, Button, FormControlLabel } from '@material-ui/core';
import {
  Input,
  InputPassword,
  CheckBox,
  Backdrop,
  EmailIconTwoTone,
  PasswordIconTwoTone,
} from '@room-match/shared-ui-components';
import { FormikProps, useFormik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FrontLayout } from 'components';

import { ISignInRequestPayload } from 'shared/interfaces/IUser';
import { autoLogin, selectUserState, setIsLoading, userLogin } from 'shared/redux/slicers/user.slicer';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import * as yup from 'yup';
import { ROUTES } from 'shared/constants/ROUTES';

import { useStyles } from './Login.styles';
import { parse } from 'query-string';

interface LoginState {
  userName: string;
  password: string;
  rememberMe: boolean;
}

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isAutoLoginLoading, setIsAutoLoginLoading] = useState<boolean>(false);
  const { isLoading: loading, isLoggedIn } = useSelector(selectUserState);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);

  const asyncDispatchAutoLogin = (token: string) => Promise.resolve(dispatch(autoLogin(token)));

  useEffect(() => {
    setIsAutoLoginLoading(true);
    const queryParams = location.search;
    if (queryParams) {
      const token = parse(queryParams)?.token as string;
      if (token) {
        (async (token: string) => {
          await asyncDispatchAutoLogin(token);
          setIsAutoLoginLoading(false);
        })(token);
      }
    } else {
      setIsAutoLoginLoading(false);
    }

    return () => setIsAutoLoginLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loading || isAutoLoginLoading;

  useEffect(() => {
    if (isLoggedIn) {
      history.push(ROUTES.AGENCY.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const initialValues: ISignInRequestPayload = {
    user: {
      email: 'casting123@test.com',
      password: 'Test123!@#',
    },
  };

  const signInValidationSchema: yup.SchemaOf<ISignInRequestPayload> = yup.object().shape({
    user: yup.object({
      email: yup.string().email('Email must be a valid email').required('Email is required'),
      password: yup.string().required('Password is required'),
    }),
  });

  const asnycLogin = (values: ISignInRequestPayload, isRememberMe?: boolean): Promise<any> =>
    Promise.resolve(dispatch(userLogin(values, isRememberMe)));
  const handleLoginSubmit = async (values: ISignInRequestPayload) => {
    const response = await asnycLogin(values, isRememberMe);
    if (response) {
    }
  };

  const form: FormikProps<ISignInRequestPayload> = useFormik({
    initialValues,
    validationSchema: signInValidationSchema,
    onSubmit: (values) => handleLoginSubmit(values),
  });

  const [loginState, setLoginState] = React.useState<LoginState>({
    userName: '',
    password: '',
    rememberMe: false,
  });

  // const handleCheckBoxChange = (prop: keyof LoginState) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setLoginState({ ...loginState, [prop]: event.target.checked });
  // };

  const handleLogin = () => {};

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <FrontLayout type="login">
      <Box className={classes.boxContainer}>
        <Input
          name="user.email"
          variant="outlined"
          placeholder="Enter Email..."
          errorMessage={getErrorMessage(form.touched.user?.email, form.errors.user?.email)}
          fullWidth
          margin={'normal'}
          autoFocus
          inputProps={{ tabIndex: 1 }}
          onChange={form.handleChange}
          onKeyPress={handleInputKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIconTwoTone style={{ marginTop: '8px' }} />
              </InputAdornment>
            ),
          }}
          value={form.values.user.email}
        />
        <InputPassword
          fullWidth
          errorMessage={getErrorMessage(form.touched.user?.password, form.errors.user?.password)}
          name="user.password"
          variant="outlined"
          placeholder="Enter Password..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIconTwoTone style={{ marginTop: '5px' }} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ tabIndex: 2 }}
          onChange={form.handleChange}
          value={form.values.user.password}
          onKeyPress={handleInputKeyPress}
          style={{ marginTop: '15px' }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </Box>
      <Box className={classes.extraContainer}>
        <Box>
          <FormControlLabel
            control={
              <CheckBox
                name="remmeberMe"
                value={isRememberMe}
                onChange={(e) => setIsRememberMe(e.target.checked)}
                // onChange={handleCheckBoxChange('rememberMe')}
                // value={loginState.rememberMe}
              />
            }
            label="Remember Me"
          />
        </Box>
        <Box>
          <Link to="/forgot_password" className={classes.link}>
            Forgot Password?
          </Link>
        </Box>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          style={{ marginTop: '5px' }}
          className={classes.buttonCls}
          onClick={() => form.handleSubmit()}
          disabled={isLoading}
        >
          Log In
        </Button>
      </Box>
      <div className={classes.loginFooter}>
        <span>Don't have an account? </span>
        <Link to="/signup" className={classes.link}>
          &nbsp; Register today!
        </Link>
      </div>
      <Backdrop isLoading={isLoading} />
    </FrontLayout>
  );
};

export default Login;
