import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  Checkbox,
  CheckMarkIconTwoTone,
  ClockCircleIcon,
  DeclineIconTwoTone,
  GradientLogIconTwoTone,
  StyledMenu,
  Typography,
} from '@room-match/shared-ui-components';
import { AgencyMessageModal, JobLogModal, TalentMessageModal, TalentNoteModal } from 'components';
import { ProjectDetails } from 'components/ProjectDetails';
import { ReportPreviewDialog } from 'components/WorksheetTabs/TH/ReportPreviewDialog';
import React, { useState } from 'react';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import ExpandedDiv from '../ExpandedDiv/ExpandedDiv';
import {
  Body,
  CheckContainer,
  InformationContainer,
  LeftInfo,
  StatusContainer,
  StatusIndicator,
  TalentImage,
  TalentInfo,
  TalentInfoContainer,
  TypeContainer,
  useStyles,
  SmallButton,
  ResponseContainer,
  ResponseButtonsContainer,
  MessageContainer,
  MiniActionsContainer,
  MoreIcon,
  AgencyResponseContainer,
  ActionButtons,
  CustomMenuItem,
  CustomMenuItemChildContainer,
} from './Item.styles';

type Props = {
  data: any;
};

const mockProject = {};

const Item: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const [selectedProject, setSelectedProject] = useState<IProjectAttributes | undefined>(undefined);
  const [menuEl, setMenuEl] = useState<Element | null>(null);

  const [isTalentNoteModalOpen, setIsTalentNoteModalOpen] = useState<boolean>(false);
  const [isAgencyMessageModalOpen, setIsAgencyMessageModalOpen] = useState<boolean>(false);
  const [isTalentMessageModalOpen, setIsTalentMessageModalOpen] = useState<boolean>(false);
  const [isProjectDetailsModalOpen, setIsProjectDetailsModalOpen] = useState<boolean>(false);
  const [isThInfoModalOpen, setIsThInfoModalOpen] = useState<boolean>(false);
  const [isJobLogModalOpen, setIsJobLogModalOpen] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleCloseMenu = () => setMenuEl(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setMenuEl(event.currentTarget);

  const handleOpenTalentNoteModal = () => {
    setIsTalentNoteModalOpen(true);
  };

  const handleCloseTalentNoteModal = () => {
    setIsTalentNoteModalOpen(false);
  };

  const handleOpenAgencyMessageModal = () => {
    setIsAgencyMessageModalOpen(true);
  };

  const handleCloseAgencyMessageModal = () => {
    setIsAgencyMessageModalOpen(false);
  };

  const handleOpenTalentMessageModal = () => {
    setIsTalentMessageModalOpen(true);
  };

  const handleCloseTalentMessageModal = () => {
    setIsTalentMessageModalOpen(false);
  };

  const handleOpenProjectDetailsModal = () => {
    setIsProjectDetailsModalOpen(true);
  };

  const handleCloseProjectDetailsModal = () => {
    setIsProjectDetailsModalOpen(false);
  };

  const handleOpenThModal = () => {
    setIsThInfoModalOpen(true);
  };

  const handleCloseThModal = () => {
    setIsThInfoModalOpen(false);
  };

  const handleOpenJobLogModal = () => {
    setIsJobLogModalOpen(true);
  };

  const handleCloseJobLogModal = () => {
    setIsJobLogModalOpen(false);
  };

  const handleOpenExpandedDiv = () => {
    setIsExpanded(true);
  };

  const handleCloseExpandedDiv = () => {
    setIsExpanded(false);
  };

  return (
    <div>
      <Body>
        <StatusIndicator
          className={
            data.status === 'Forward To Talent'
              ? 'gradient'
              : data.status === 'Confirmed' || data.status === 'Submitted' || data.status === 'Confirmed Submission'
              ? 'success'
              : 'warning'
          }
        ></StatusIndicator>
        <CheckContainer>
          <Checkbox value={false} />
        </CheckContainer>
        <TypeContainer>
          <Typography fontSize={14}>{data.type}</Typography>
          {data.type !== 'Avail Request' && data.type !== 'Booking Request' && data.type !== 'TH Info Request' ? (
            data.type !== 'Released' && (
              <>
                <Typography fontSize={12} color="mainGrey">
                  {data.audition_date}
                </Typography>
                <Typography fontSize={12} color="mainGrey">
                  {data.audition_time}
                </Typography>
              </>
            )
          ) : (
            <SmallButton
              color="secondary"
              variant="contained"
              fullWidth
              className="primary"
              style={{ marginTop: 5 }}
              onClick={() => (data.type === 'TH Info Request' ? handleOpenThModal() : handleOpenProjectDetailsModal())}
            >
              View
            </SmallButton>
          )}
        </TypeContainer>
        <StatusContainer>
          <Typography
            fontSize={13}
            className={
              data.status.includes('Declined')
                ? classes.text__danger
                : data.status.includes('Forward') || data.status.includes('Self Tape')
                ? classes.text__primary
                : data.status.includes('Confirmed') || data.status.includes('Submitted')
                ? classes.text__success
                : classes.text__warning
            }
          >
            {data.status}
          </Typography>
          {data.status === 'Forwarded' && <Typography fontSize={13}>{data.action_time}</Typography>}
        </StatusContainer>
        <InformationContainer>
          <LeftInfo>
            <Typography fontSize={14} color="mainGrey">
              {data.agency}
            </Typography>
            <Typography fontSize={14}>{data.project}</Typography>
            <Typography fontSize={14} color="primary">
              {data.role}
            </Typography>
          </LeftInfo>
          <TalentInfoContainer>
            <TalentImage src="/sample_person2.jpg" />
            <TalentInfo>
              <Typography fontSize={15}>{data.name}</Typography>
              <Typography fontSize={14} color="mainGrey">
                {data.number}
              </Typography>
              <Typography fontSize={14} color="mainGrey" className={classes.limit__characters}>
                {data.email}
              </Typography>
            </TalentInfo>
          </TalentInfoContainer>
          <ResponseContainer>
            <ResponseButtonsContainer>
              {data.status === 'Forward To Talent' && (
                <SmallButton variant="contained" color="secondary" fullWidth className="borderGradient">
                  Forward
                </SmallButton>
              )}
              {data.type === 'ST Request' && (
                <>
                  {isExpanded ? (
                    <SmallButton
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className="gradient"
                      onClick={handleCloseExpandedDiv}
                    >
                      Hide ST
                    </SmallButton>
                  ) : (
                    <SmallButton
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className="borderGradient"
                      onClick={handleOpenExpandedDiv}
                    >
                      View ST
                    </SmallButton>
                  )}
                </>
              )}
              <SmallButton
                variant="contained"
                color="secondary"
                fullWidth
                className="primary"
                onClick={handleOpenAgencyMessageModal}
              >
                Message
              </SmallButton>
            </ResponseButtonsContainer>
            {data.message_status && (
              <MessageContainer>
                {data.message_status === 'warning' ? (
                  <ClockCircleIcon />
                ) : data.message_status === 'danger' ? (
                  <DeclineIconTwoTone />
                ) : (
                  <CheckMarkIconTwoTone />
                )}
                <Typography fontSize={14} className={classes.limit__characters__long}>
                  {data.message}
                </Typography>
              </MessageContainer>
            )}
          </ResponseContainer>
          <MiniActionsContainer>
            <IconButton size="small" disableRipple disableFocusRipple edge="end" onClick={handleOpenTalentNoteModal}>
              <GradientLogIconTwoTone style={{ fontSize: 18 }} />
            </IconButton>
            <MoreIcon onClick={handleOpenMenu}>
              <MoreVert style={{ color: '#6e767f' }} />
            </MoreIcon>
            <StyledMenu
              id="simple-menu"
              anchorEl={menuEl}
              open={Boolean(menuEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
            >
              <CustomMenuItem onClick={handleOpenJobLogModal}>
                <CustomMenuItemChildContainer>
                  <Typography style={{ fontSize: 13 }}>Job Log</Typography>
                </CustomMenuItemChildContainer>
              </CustomMenuItem>
            </StyledMenu>
          </MiniActionsContainer>
        </InformationContainer>
        <AgencyResponseContainer>
          <ActionButtons>
            {data.status.includes('Late') ? (
              <Typography fontSize={13} className={classes.text__danger}>
                Late {data.late_mins} Minutes
              </Typography>
            ) : (
              <>
                <IconButton size="small" disableRipple disableFocusRipple>
                  <ClockCircleIcon />
                </IconButton>
                <IconButton size="small" disableRipple disableFocusRipple>
                  <DeclineIconTwoTone />
                </IconButton>
                <IconButton size="small" disableRipple disableFocusRipple>
                  <CheckMarkIconTwoTone />
                </IconButton>
              </>
            )}
          </ActionButtons>
          <SmallButton
            variant="contained"
            color="secondary"
            fullWidth
            className="primary"
            onClick={handleOpenTalentMessageModal}
          >
            Message
          </SmallButton>
          {data.remarks && (
            <Typography fontSize={14} className={classes.limit__characters__long}>
              {data.remarks}
            </Typography>
          )}
        </AgencyResponseContainer>
        <ProjectDetails
          open={isProjectDetailsModalOpen}
          onClose={handleCloseProjectDetailsModal}
          worksheet={true}
          project={selectedProject}
        />
        <JobLogModal open={isJobLogModalOpen} onClose={handleCloseJobLogModal} data={data} />
        <ReportPreviewDialog open={isThInfoModalOpen} onClose={handleCloseThModal} />
        <TalentNoteModal open={isTalentNoteModalOpen} onClose={handleCloseTalentNoteModal} data={data} />
        <AgencyMessageModal open={isAgencyMessageModalOpen} onClose={handleCloseAgencyMessageModal} data={data} />
        <TalentMessageModal open={isTalentMessageModalOpen} onClose={handleCloseTalentMessageModal} data={data} />
      </Body>
      {isExpanded && <ExpandedDiv />}
    </div>
  );
};

export default Item;
