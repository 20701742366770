import { combineReducers } from '@reduxjs/toolkit';
import themeReducer from './slicers/theme.slicer';
import userReducer from './slicers/user.slicer';
import appSlicer from './slicers/app.slicer';
import talentProfileReducer from './slicers/talentProfile.slicer';

const rootReducer = combineReducers({
  app: appSlicer,
  theme: themeReducer,
  user: userReducer,
  talentProfile: talentProfileReducer,
});

export default rootReducer;
