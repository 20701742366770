import { Button, styled } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 10,
}));

export const LeftContainer = styled('div')(({ theme }) => ({
  height: 440,
  width: '100%',
  maxWidth: 320,
  borderRadius: 8,
  objectFit: 'cover',
  marginRight: 20,
}));

export const TalentImage = styled('img')(({ theme }) => ({
  height: 440,
  width: '100%',
  maxWidth: 320,
  borderRadius: 5,
  objectFit: 'cover',
}));

export const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const HeadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  position: 'relative',
  width: '100%',
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  maxWidth: 250,
  height: 50,
}));

export const AdditionalInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
  '& > div:first-child': {
    width: '100%',
    maxWidth: 180,
  },
}));

export const FlexColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const AgencyLogo = styled('img')(({ theme }) => ({
  height: 38,
  marginLeft: 'auto',
}));

export const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 30,
  margin: '10px 0 30px',
}));

export const CustomTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 255,
}));

export const CustomTableRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
  borderBottom: '1px solid #202936',
}));
