import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    submissionsAuditions: {
      borderRadius: '4px',
      minHeight: '110px',
      overflow: 'hidden',
      background: '#0e1319',
      height: '100%',
    },
    subAudHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#3e8bff',
      textTransform: 'uppercase',
      paddingTop: '3px',
    },
    subAudBody: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      minHeight: 'calc(110px - 19.91px)',
      '& .MuiTypography-body1': {
        color: '#fff',
      },
      [theme.breakpoints.down(820)]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '& > *': {
          margin: '10px',
        },
      },
    },
    overlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 1,
    },
    statistics: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        height: '18px',
        width: '18px',
        marginBottom: '8px',
      },
      '& *': {
        color: '#6e767f',
      },
      '&:hover svg path': {
        fill: '#3e8bff',
      },
      '&:hover > *': {
        color: '#FFFFFF',
      },
    },

    empty: {
      background: '#131921',
      color: '#6e767f',
    },
  }),
);
