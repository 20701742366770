import { Box, Typography, Grid } from '@material-ui/core';
import { PhonelinkLock } from '@material-ui/icons';
import { ArrowDownIcon, EmailIconTwoTone, UserCircleIconTwoTone } from '@room-match/shared-ui-components';
import React from 'react';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { ContactFlex, Container, TitleContainer, useStyles } from './ProjectDetailsCCI.styles';

type Props = {
  project: IProjectAttributes | undefined;
};
const ProjectDetailsCCI: React.FC<Props> = ({ project }) => {
  const classes = useStyles();
  return (
    <div>
      <TitleContainer>
        <Typography variant="h5">Casting Contact Information</Typography>
        <ArrowDownIcon style={{ width: '16px' }} />
      </TitleContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">John Travolta</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Casting Director
                </Typography>
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">Bryan Maxim</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Casting Associate
                </Typography>
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">John, Kristeen, Jamie</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Casting Assistant
                </Typography>
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <PhonelinkLock />
              <Box>
                <Typography variant="body2">{project?.contact_number || '+1 (310) 234-56789'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Contact Phone Number
                </Typography>
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <EmailIconTwoTone />
              <Box>
                <Typography variant="body2">{project?.contact_email || 'contact@example.com'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Contact Email Address
                </Typography>
              </Box>
            </ContactFlex>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProjectDetailsCCI;
