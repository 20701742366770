import {
  DocumentFileIcon,
  DocumentIcon,
  MicrophoneIcon,
  PlayIconTwoTone,
  Typography,
} from '@room-match/shared-ui-components';
import React, { useState } from 'react';
import {
  AddMediaButton,
  Container,
  CustomIcon,
  CustomImage,
  LabelContainer,
  MediaContainer,
  MediaItem,
  MediaListContainer,
  PlayButton,
} from './SelfTapeFiles.styles';

const mockData = [
  {
    type: 'image',
    url: '/sample_person2.jpg',
    label: 'Audition Selfie',
  },
  {
    type: 'image',
    url: '/sample_person2.jpg',
    label: 'Full Body Image',
  },
  {
    type: 'video',
    url: '/sample_person2.jpg',
    label: 'Slate',
    duration: '02:45',
  },
  {
    type: 'video',
    url: '/sample_person2.jpg',
    label: 'Take 1',
    duration: '02:45',
  },
  {
    type: 'video',
    url: '/sample_person2.jpg',
    label: 'Take 2',
    duration: '02:45',
  },
  {
    type: 'other',
    label: 'Resume',
    file: 'document',
    icon: <DocumentFileIcon />,
  },
  {
    type: 'other',
    label: 'Audio 1',
    file: 'audio',
    icon: <MicrophoneIcon />,
  },
];

const SelfTapeFiles = () => {
  return (
    <div>
      <AddMediaButton fullWidth variant="contained" color="primary">
        Add Media
      </AddMediaButton>
      <Container>
        <div>
          <Typography color="primary" fontSize={20}>
            Images
          </Typography>
          <MediaListContainer>
            {mockData
              .filter((v) => v.type === 'image')
              .map((i) => {
                return (
                  <MediaItem>
                    <MediaContainer>
                      <CustomImage src={i.url} />
                    </MediaContainer>
                    <Typography fontSize={14}>{i.label}</Typography>
                  </MediaItem>
                );
              })}
          </MediaListContainer>
        </div>
        <div>
          <Typography color="primary" fontSize={20}>
            Videos
          </Typography>
          <MediaListContainer>
            {mockData
              .filter((v) => v.type === 'video')
              .map((i) => {
                return (
                  <MediaItem>
                    <MediaContainer>
                      <CustomImage src={i.url} />
                      <PlayButton>
                        <PlayIconTwoTone />
                      </PlayButton>
                    </MediaContainer>
                    <LabelContainer>
                      <Typography fontSize={14}>{i.label}</Typography>
                      <Typography fontSize={14} color="mainGrey">
                        {i.duration}
                      </Typography>
                    </LabelContainer>
                  </MediaItem>
                );
              })}
          </MediaListContainer>
        </div>
        <div>
          <Typography color="primary" fontSize={20}>
            Other Files
          </Typography>
          <MediaListContainer>
            {mockData
              .filter((v) => v.type === 'other')
              .map((i) => {
                return (
                  <MediaItem>
                    <MediaContainer>
                      <CustomIcon>{i.icon}</CustomIcon>
                    </MediaContainer>
                    <LabelContainer>
                      <Typography fontSize={14}>{i.label}</Typography>
                      <Typography fontSize={14} color="mainGrey">
                        {i.duration}
                      </Typography>
                    </LabelContainer>
                  </MediaItem>
                );
              })}
          </MediaListContainer>
        </div>
      </Container>
    </div>
  );
};

export default SelfTapeFiles;
