import { styled, Button, createStyles, makeStyles, MenuItem } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';
import { convertCompilerOptionsFromJson } from 'typescript';

export const useStyles = makeStyles(() =>
  createStyles({
    text__warning: {
      color: COLORS.WARNING,
    },
    text__danger: {
      color: COLORS.DANGER,
    },
    text__success: {
      color: COLORS.SUCCESS,
    },
    text__primary: {
      color: COLORS.PRIMARY,
    },
    limit__characters: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 120,
    },
    limit__characters__long: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 180,
    },
  }),
);

export const Body = styled('div')(({ theme }) => ({
  background: '#121921',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  height: 90,
  maxHeight: 90,
  overflowX: 'auto',
  overflowY: 'hidden',
  zIndex: 2,
  position: 'relative',
  // '& *': {
  //   zIndex: 2,
  // },
}));

export const StatusIndicator = styled('span')(({ theme }) => ({
  height: 90,
  width: 3,
  '&.gradient': {
    background: 'linear-gradient(197deg, #d51bff, #00d4ff)',
  },
  '&.warning': {
    background: COLORS.WARNING,
  },
  '&.success': {
    background: COLORS.SUCCESS,
  },
}));

export const CheckContainer = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 1px 0 0',
  height: '100%',
  display: 'flex',

  '& .MuiFormControlLabel-root': {
    margin: '0',

    '& span > span > span': {
      height: 22,
      width: 22,
    },
  },
}));

export const TypeContainer = styled('div')(({ theme }) => ({
  background: '#0f151c',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 1px 0 0',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 137,
  minWidth: 137,
}));

export const SmallButton = styled(Button)(({ theme }) => ({
  height: 24,
  maxWidth: 82,
  fontSize: 12,
  textTransform: 'uppercase',

  '&.primary': {
    border: '1px solid',
    borderColor: COLORS.PRIMARY,
    background: '#0b0f14',
  },

  '&.borderGradient': {
    background: '#0b0f14',
    border: '1px solid transparent',
    boxShadow: '2px 1000px 1px #0b0f14 inset',
    backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '5px !important',
  },

  '&.gradient': {
    backgroundImage: 'linear-gradient(to right, #00d4ff 0%, #d51bff 100%)',
  },
}));

export const StatusContainer = styled('div')(({ theme }) => ({
  background: '#0f151c',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 1px 0 0',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 115,
  minWidth: 115,
  textAlign: 'center',
  padding: '0 10px',
}));

export const InformationContainer = styled('div')(({ theme }) => ({
  background: '#0C0f14',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderWidth: '0 1px 0 0',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 15,
  width: '100%',
  maxWidth: 950,
}));

export const LeftInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 200,
  minWidth: 200,
}));

export const TalentInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  width: '100%',
  maxWidth: 250,
  minWidth: 250,
}));

export const TalentInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TalentImage = styled('img')(({ theme }) => ({
  width: 49,
  height: 67,
  borderRadius: 5,
  objectFit: 'cover',
}));

export const ResponseContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
}));

export const ResponseButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const MessageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,

  '& svg': {
    height: 24,
  },
}));

export const MiniActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  gap: 5,
}));

export const MoreIcon = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  marginLeft: 10,

  '& .MuiSvgIcon-root': {
    width: 24,
    height: 24,

    '& path': {
      fill: COLORS.MAIN_GRAY,
    },
  },
}));

export const AgencyResponseContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  padding: 20,
  alignItems: 'center',
}));

export const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  width: 140,
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: '4px 0',
  transition: 'none !important',
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
}));

export const CustomMenuItemChildContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
}));
