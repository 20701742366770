import { styled, Button, DialogContent, createStyles, makeStyles } from '@material-ui/core';
import { Checkbox, StyledDialog } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    text__warning: {
      color: COLORS.WARNING,
    },
    text__danger: {
      color: COLORS.DANGER,
    },
    text__success: {
      color: COLORS.SUCCESS,
    },
    text__primary: {
      color: COLORS.PRIMARY,
    },
  }),
);

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '643px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 40px!important',
  background: '#0d1117',
}));

export const Header = styled('div')(({ theme }) => ({
  gap: 15,
  display: 'flex',
  marginBottom: 15,
}));

export const TalentImage = styled('img')(({ theme }) => ({
  height: 100,
  width: 73,
  borderRadius: 5,
  objectFit: 'cover',
}));

export const TalentInformation = styled('div')(({ theme }) => ({
  '& > p': {
    '&:first-child': {
      marginBottom: -5,
    },
    '&:last-child': {
      marginTop: 5,
    },
  },
}));

export const LabelsContainer = styled('div')(({ theme }) => ({
  margin: '10px 0 5px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
}));

export const AddButton = styled(Button)(({ theme }) => ({
  height: 50,
  marginTop: 15,
  fontSize: 14,
  fontWeight: 'normal',
}));
