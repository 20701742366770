import { Button, IconButton, styled } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({}));

export const AddMediaButton = styled(Button)(({ theme }) => ({
  background: '#131c26',
  border: '1px dashed' + COLORS.PRIMARY,
  marginBottom: 15,
}));

export const MediaListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  marginTop: 10,
}));

export const MediaItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const MediaContainer = styled('div')(({ theme }) => ({
  background: '#1e2935',
  borderRadius: 8,
  width: 200,
  height: 113,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CustomImage = styled('img')(({ theme }) => ({
  width: 200,
  height: 113,
  borderRadius: 8,
  objectFit: 'cover',
}));

export const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 10,
  bottom: 10,
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.5)',
  padding: 0,
  height: 30,
  width: 30,
  '& svg': {
    height: 30,
    width: 30,
  },
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const LabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CustomIcon = styled('div')(({ theme }) => ({
  '& svg path': {
    fill: COLORS.PRIMARY,
  },
}));
