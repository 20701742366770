import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},
    two__column__fix: {
      [theme.breakpoints.down('md')]: {
        flex: '100%',
        flexDirection: 'column',
      },
    },
  }),
);
