import { Box, Grid, IconButton, Popover } from '@material-ui/core';
import {
  ImageIcon,
  FlagGreenIconTwoTone,
  YoutubeIcon,
  StyledPopover,
  Typography,
} from '@room-match/shared-ui-components';
import React from 'react';
import {
  RequestsDescription,
  RequestsDescriptionItemInformation,
  useStyles,
  Container,
} from './TalentDescription.styles';

type Props = {
  isShowVideoExpanded: boolean;
  handleOpenShowVideoExpanded: () => void;
  handleCloseShowVideoExpanded: () => void;
};
const TalentDescription: React.FC<Props> = ({
  isShowVideoExpanded,
  handleCloseShowVideoExpanded,
  handleOpenShowVideoExpanded,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Container>
      <RequestsDescription>
        <Grid item>
          <IconButton size="small">
            <ImageIcon fontSize="small" classes={{ root: classes.requests__description__icon }} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            onClick={() => {
              if (!isShowVideoExpanded) {
                handleOpenShowVideoExpanded();
              } else {
                handleCloseShowVideoExpanded();
              }
            }}
          >
            <YoutubeIcon fontSize="small" classes={{ root: classes.requests__description__icon }} />
          </IconButton>
        </Grid>
      </RequestsDescription>
      <Grid item>
        <RequestsDescriptionItemInformation>
          <Grid item>
            <Typography fontSize={15}>Henry Fox</Typography>
            <IconButton size="small">
              <FlagGreenIconTwoTone fontSize="small" style={{ fontSize: 16 }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography fontSize={14} color="mainGrey">
              SAG/AFTRA
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontSize={14}
              color="primary"
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e: any) => handlePopoverOpen(e)}
              onMouseLeave={(e: any) => handlePopoverClose()}
            >
              Farmer’s Friend
            </Typography>
            <StyledPopover
              id="mouse-over-popover"
              className={classes.popover}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Grid container spacing={0} className={classes.popoverContainer}>
                <Grid item>
                  <Typography color="primary" className={classes.popoverHeading}>
                    Smith Agency LA
                  </Typography>
                  <Typography fontSize={14}>Commercial Divison</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={14}>(310) 345-6789</Typography>
                </Grid>
                <Grid item>
                  <Typography color="primary" className={classes.popoverHeading}>
                    Rep: Tom Jones
                  </Typography>
                  <Typography fontSize={14}>(310) 345-6777</Typography>
                  <Typography fontSize={14}>tomj@smith.com</Typography>
                </Grid>
              </Grid>
            </StyledPopover>
          </Grid>
          {/* <Grid item>
            <Typography fontSize={14} color="mainGrey">
              Smith Agency
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={14} color="mainGrey">
              (310) 310-3100
            </Typography>
          </Grid> */}
        </RequestsDescriptionItemInformation>
      </Grid>
    </Container>
  );
};

export default TalentDescription;
