import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, InputAdornment } from '@material-ui/core';
import { FrontLayout } from 'components';
import {
  Input,
  InputPassword,
  EmailIconTwoTone,
  PasswordIconTwoTone,
  Typography,
  LoadingSpinnerIconTwoTone,
  CheckboxCheckIconTwoTone,
  CloseIcon,
  Backdrop,
} from '@room-match/shared-ui-components';

import { useStyles } from './Signup.styles';
import { getErrorMessage, PasswordPrinciple, validatePassword } from '@room-match/shared-utils';
import { Link, useHistory } from 'react-router-dom';
import { ISignUpRequestPayload } from 'shared/interfaces/IUser';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserState, userSignup, userLogin } from 'shared/redux/slicers/user.slicer';
// import { useEmailValidation } from 'shared/hooks/useEmailValidation';
import { COLORS } from 'shared/constants/COLORS';

import { ISignInRequestPayload } from 'shared/interfaces/IUser';
import { CollectionsBookmarkRounded } from '@material-ui/icons';

// Login

const Signup = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordValidationResult, setPasswordValidationResult] = useState<PasswordPrinciple | null>(null);
  const [password_str, setPasswordStr] = useState<string>('');
  // const { isEmailValidationLoading, isValidEmail, handleEmailChange, emailValue } = useEmailValidation();

  // const [loginValues, setLoginValues] = useState({
  //   user: {
  //     email: '',
  //     password: '',
  //   },
  // });

  // const { isLoading, isLoggedIn, errorMessage } = useSelector(selectUserState);

  // // Forms
  // const initialValues: ISignUpRequestPayload = {
  //   email: '',
  //   password: '',
  //   password_confirmation: '',
  //   user_type: 'talent',
  // };

  // // const loginValues: ISignInRequestPayload = {

  // // };

  // const signUpValidationSchema: yup.SchemaOf<ISignUpRequestPayload> = yup.object({
  //   email: yup.string().email('Must be a valid email format').required('Email is required'),
  //   user_type: yup.string().required('User type is required'),
  //   password: yup
  //     .string()
  //     .required('Password is required')
  //     .min(8, 'Password must be atleast 8 characters')
  //     .test('passwordValidate', 'Invalid password', (value: any) => {
  //       if (value) {
  //         const validatePasswordResult = validatePassword(value);
  //         setPasswordValidationResult(validatePasswordResult);
  //         return validatePasswordResult.IsValid;
  //       }
  //       return false;
  //     }),
  //   password_confirmation: yup
  //     .string()
  //     .required('Password confirmation is required')
  //     .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  // });

  // const asnycLogin = (values: ISignInRequestPayload): Promise<any> => Promise.resolve(dispatch(userLogin(values)));

  // const handleLoginSubmit = async (email: string, password: string) => {
  //   setLoginValues((prev) => ({
  //     ...prev,
  //     email: email,
  //     password: password,
  //   }));

  //   console.log(loginValues);
  //   // await asnycLogin(loginValues);
  // };

  // const handleSignUpSubmit = async (values: ISignUpRequestPayload) => {
  //   dispatch(userSignup(values, history));
  // };

  // const form: FormikProps<ISignUpRequestPayload> = useFormik({
  //   initialValues,
  //   validationSchema: signUpValidationSchema,
  //   validateOnBlur: true,
  //   onSubmit: (values, helpers) => {
  //     if (!isValidEmail) {
  //       helpers.setFieldError('email', 'Invalid Email');
  //     } else {
  //       handleSignUpSubmit(values);
  //     }
  //   },
  // });

  return (
    <FrontLayout
      heading="Register"
      subHeading="Let’s get started with your new access details below"
      containerWidth={480}
      type="register"
    >
      <Box component="div" className={classes.formContainer}>
        <Input
          className={`${classes.input} ${classes.errorMessageFix}`}
          name="email"
          type="email"
          // onChange={(e) => {
          //   return form.handleChange(e);
          // }}
          variant="outlined"
          placeholder="Enter Email..."
          fullWidth
          margin={'normal'}
          autoFocus
          // onBlur={(e) => {
          //   handleEmailChange(e.target.value);
          //   return form.handleBlur(e);
          // }}
          // value={form.values.email}
          InputProps={{
            tabIndex: 1,
            startAdornment: (
              <InputAdornment position="start">
                <EmailIconTwoTone className={classes.startIcon} />
              </InputAdornment>
            ),
            // endAdornment: (
            //   <>
            //     {emailValue && (
            //       <>
            //         {isEmailValidationLoading ? (
            //           <LoadingSpinnerIconTwoTone style={{ fontSize: 40 }} />
            //         ) : (
            //           <>
            //             {isValidEmail ? (
            //               <CheckboxCheckIconTwoTone style={{ fontSize: 12 }} />
            //             ) : (
            //               <CloseIcon style={{ fontSize: 12 }} htmlColor={COLORS.DANGER} />
            //             )}
            //           </>
            //         )}
            //       </>
            //     )}
            //   </>
            // ),
          }}
          inputProps={{ tabIndex: 1 }}
          // errorMessage={getErrorMessage(
          //   form.touched.email,
          //   form.errors.email ? form.errors.email : !isValidEmail && !isEmailValidationLoading ? 'Invalid Email' : '',
          // )}
        />
        <Box component="div">
          <Typography fontSize={14} className={classes.helpText}>
            Email address will be used for audition notifications
          </Typography>
        </Box>
        <InputPassword
          className={classes.input}
          fullWidth
          name="password"
          // onChange={(e) => {
          //   if (form.errors.password && !form.touched.password) {
          //     form.setFieldTouched('password');
          //     form.validateField('password');
          //   }
          //   setPasswordStr(e.target.value || '');
          //   return form.handleChange(e);
          // }}
          // errorMessage={getErrorMessage(form.touched.password, form.errors.password)}
          // value={form.values.password}
          variant="outlined"
          placeholder="Type Password..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIconTwoTone className={classes.startIcon} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ tabIndex: 2 }}
          style={{ marginTop: '15px' }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <InputPassword
          className={classes.input}
          name="password_confirmation"
          fullWidth
          // onChange={(e) => {
          //   if (form.errors.password_confirmation && !form.touched.password_confirmation) {
          //     form.setFieldTouched('password_confirmation');
          //     form.validateField('password_confirmation');
          //   }
          //   return form.handleChange(e);
          // }}
          // errorMessage={getErrorMessage(form.touched.password_confirmation, form.errors.password_confirmation)}
          // value={form.values.password_confirmation}
          variant="outlined"
          placeholder="Confirm Password..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIconTwoTone className={classes.startIcon} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ tabIndex: 2 }}
          style={{ marginTop: '15px' }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <Typography fontSize={14} className={classes.helpText} style={{ marginTop: 10 }}>
          *Password should be at least 8 characters long containing a combination of uppercase, lowercase, numbers and
          special characters
        </Typography>
        <Link to="/send_verification" className={classes.link}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            className={classes.buttonCls}
            // onClick={() => form.handleSubmit()}
          >
            Send Verification Email
          </Button>
        </Link>
      </Box>
      {/* <Backdrop isLoading={isLoading} /> */}
    </FrontLayout>
  );
};

export default Signup;
