import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    ansHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
      '& > *:first-child': {
        color: '#c2ccd8',
      },
      '& > *:last-child': {
        color: '#3e8bff',
      },
    },
    questionContainer2: {
      flexWrap: 'nowrap',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 10px',
      borderRadius: '4px',
      background: '#1d232c',
      marginBottom: '10px',
      '& > *': {
        marginTop: '5px',
        marginBottom: '5px',
      },
    },
    questionList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      maxWidth: '420px',
      width: '100%',
    },
    iconFlex: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '55px',
      marginLeft: '55px',
      '& svg': {
        width: '18px',
      },
      [theme.breakpoints.down(1598)]: {
        marginLeft: 'unset',
      },
    },
    blackButton: {
      border: 'solid 1px #3e8bff',
      textTransform: 'capitalize',
      background: '#0e1319',
      height: '40px',
    },
  }),
);
