import { Box, Button, DialogContent, Grid, IconButton, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseIcon, CopyIconTwoTone, StyledDialog, Typography } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';

import useCopyClipboard from 'react-use-clipboard';

import { useStyles } from './SelfTapesQRModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};
const SelfTapesQRModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [isCopiedLinkQR, setIsCopiedLinkQR] = useCopyClipboard(
    'https://example.link/?=self_tape_demo_link_example123',
    { successDuration: 1000 },
  );
  const [isCopiedSelfTapeLink, setIsCopiedSelfTapeLink] = useCopyClipboard(
    'https://AHC.SUBMITYOURSELFTAPE.com/target/23rdt',
    { successDuration: 1000 },
  );

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent classes={{ root: classes.qrModal__dialogContent }}>
        <Box className={classes.qrModal__container}>
          {/* Header  */}
          <Box className={classes.qrModal__header}>
            <Typography fontSize={25}>Self Tape Link / QR Code & Instructions</Typography>
            <IconButton size="small" onClick={() => onClose()}>
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
          {/* Content */}
          <Box className={classes.qrModal__content}>
            {/* Qr */}
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3}>
                <Box className={classes.qrModal__qrContainer}>
                  <Box className={classes.qrModal__imageContainer}>
                    <img src="/sample_person2.jpg" className={classes.qr__image} />
                  </Box>
                  <Button variant="contained" color="primary" fullWidth>
                    Download QR Code
                  </Button>
                </Box>
              </Grid>
              {/* Right Content */}
              <Grid item xl={9} lg={9}>
                <Box className={classes.qrModal__rightContent}>
                  {/* Link  */}
                  <Box className={clsx(classes.qrModal__linkContainer, classes.qrModal__link)}>
                    <IconButton size="small" onClick={() => setIsCopiedLinkQR()}>
                      <CopyIconTwoTone style={{ fontSize: 14 }} />
                    </IconButton>
                    <Link href="https://example.link/?=self_tape_demo_link_example123">
                      https://example.link/?=self_tape_demo_link_example123
                    </Link>
                  </Box>
                  {/* Text Detail */}
                  <Box className={classes.qrModal__centerTextContainer}>
                    <Typography fontSize={14}>
                      If you are sharing this link with talent to complete their self tape here is some suggested text
                      below:
                    </Typography>
                  </Box>
                  <Box className={clsx(classes.qrModal__linkContainer, classes.qrModal__detailContainer)}>
                    <IconButton size="small" onClick={() => setIsCopiedSelfTapeLink()}>
                      <CopyIconTwoTone style={{ fontSize: 14 }} />
                    </IconButton>
                    <Box className={classes.qrModal__detailTextContainer}>
                      <Typography fontSize={14}>
                        Please click the link below before you take any action for instructions on submitting your self
                        tape and answering relevant questions.
                      </Typography>
                      <Link href="https://AHC.SUBMITYOURSELFTAPE.com/target/23rdt">
                        https://AHC.SUBMITYOURSELFTAPE.com/target/23rdt
                      </Link>
                      <Typography fontSize={14}>
                        and follow the steps to complete your self tape process for this project, including answering
                        questions and checking your profile and submitting media....
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <Snackbar
        open={isCopiedLinkQR || isCopiedSelfTapeLink}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">Copied to Clipboard</Alert>
      </Snackbar>
    </StyledDialog>
  );
};

export default SelfTapesQRModal;
