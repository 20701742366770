import React, { Fragment } from 'react';
import { Box, Typography, Grid, InputLabel, Button } from '@material-ui/core';
import {
  Select as SelectComp,
  Input,
  DragIcon,
  FlagIcon,
  RemoveIconTwoTone,
  PlusIconTwoTone,
} from '@room-match/shared-ui-components';
import { useStyles } from './QuestionForm.styles';

type Props = {
  question?: any;
  handleSetQuestion: (instance: any) => void;
  saveQuestion: () => void;
};

const QuestionForm: React.FC<Props> = ({ question = {}, handleSetQuestion, saveQuestion }) => {
  const classes = useStyles();

  const questionTypes = [
    { key: 'Single', value: 'single' },
    { key: 'Multiple', value: 'multiple' },
    { key: 'Selection', value: 'selection' },
  ];

  return (
    <Box>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={6}>
          <InputLabel id="questionType" shrink>
            Question Type
          </InputLabel>
          <SelectComp
            variant="outlined"
            fullWidth
            data={questionTypes}
            placeHolder="Select Question Type"
            value={question?.type}
            onChange={(e) => handleSetQuestion(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="category" shrink>
            Category
          </InputLabel>
          <SelectComp variant="outlined" fullWidth data={[]} value={question.category} placeHolder="Select Category" />
        </Grid>
      </Grid>
      <Box style={{ marginBottom: '15px', marginTop: '5px' }}>
        <Box id="qQuestion">
          <InputLabel id="question" shrink>
            Question
          </InputLabel>
          <Input
            variant="outlined"
            name="question"
            placeholder="Type here..."
            fullWidth
            margin={'normal'}
            InputLabelProps={{ shrink: true }}
            inputProps={{ tabIndex: 1 }}
            style={{ height: '50px' }}
          />
        </Box>
        {question.type === 'multiple' && (
          <Fragment>
            <Box className={classes.ansHeader} style={{ marginTop: '5px' }}>
              <Typography variant="body2">Answers</Typography>
              <Typography variant="body2">Total: 2</Typography>
            </Box>
            <Box
              id="questionBox"
              className={classes.questionContainer2}
              style={{ height: '50px', background: '#0e1319' }}
            >
              <Box className={classes.questionList}>
                <DragIcon style={{ width: '18px', marginRight: '10px' }} />
                <Box>
                  <Typography variant="body1">Yes</Typography>
                </Box>
              </Box>
              <Box className={classes.iconFlex}>
                <FlagIcon />
                <RemoveIconTwoTone />
              </Box>
            </Box>
            <Box
              id="questionBox"
              className={classes.questionContainer2}
              style={{ height: '50px', background: '#0e1319' }}
            >
              <Box className={classes.questionList}>
                <DragIcon style={{ width: '18px', marginRight: '10px' }} />
                <Box>
                  <Typography variant="body1">Yes</Typography>
                </Box>
              </Box>
              <Box className={classes.iconFlex}>
                <FlagIcon />
                <RemoveIconTwoTone />
              </Box>
            </Box>
            <Button type="button" variant="outlined" fullWidth className={classes.blackButton}>
              <PlusIconTwoTone style={{ width: '16px' }} /> &nbsp;New Answer
            </Button>
          </Fragment>
        )}
        {question.type === 'single' && (
          <Box id="qAnswer" style={{ margin: '5px 0px' }}>
            <InputLabel id="answer" shrink>
              Answer
            </InputLabel>
            <Input
              variant="outlined"
              name="answer"
              placeholder="Type here..."
              fullWidth
              margin={'normal'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ tabIndex: 1 }}
              style={{ height: '50px' }}
            />
          </Box>
        )}
        {question.type === 'selection' && (
          <Fragment>
            <Box className={classes.ansHeader} style={{ marginTop: '5px' }}>
              <Typography variant="body2">Selections</Typography>
              <Typography variant="body2">Total: 3</Typography>
            </Box>
            <Box
              id="questionBox"
              className={classes.questionContainer2}
              style={{ height: '50px', background: '#0e1319' }}
            >
              <Box className={classes.questionList}>
                <DragIcon style={{ width: '18px', marginRight: '10px' }} />
                <Box>
                  <Typography variant="body1">Dancer</Typography>
                </Box>
              </Box>
              <Box className={classes.iconFlex}>
                <FlagIcon />
                <RemoveIconTwoTone />
              </Box>
            </Box>
            <Box
              id="questionBox"
              className={classes.questionContainer2}
              style={{ height: '50px', background: '#0e1319' }}
            >
              <Box className={classes.questionList}>
                <DragIcon style={{ width: '18px', marginRight: '10px' }} />
                <Box>
                  <Typography variant="body1">Singer</Typography>
                </Box>
              </Box>
              <Box className={classes.iconFlex}>
                <FlagIcon />
                <RemoveIconTwoTone />
              </Box>
            </Box>
            <Box
              id="questionBox"
              className={classes.questionContainer2}
              style={{ height: '50px', background: '#0e1319' }}
            >
              <Box className={classes.questionList}>
                <DragIcon style={{ width: '18px', marginRight: '10px' }} />
                <Box>
                  <Typography variant="body1">Musician</Typography>
                </Box>
              </Box>
              <Box className={classes.iconFlex}>
                <FlagIcon />
                <RemoveIconTwoTone />
              </Box>
            </Box>
            <Button type="button" variant="outlined" fullWidth className={classes.blackButton}>
              <PlusIconTwoTone style={{ width: '16px' }} /> &nbsp;New Selection
            </Button>
          </Fragment>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            style={{ height: '50px', textTransform: 'capitalize' }}
            onClick={() => saveQuestion()}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            style={{ height: '50px', textTransform: 'capitalize', background: '#303946' }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionForm;
