import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ExpandSquareIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="5co5v2d7ja">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="i4f51few2b">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M16.115 0H.478A.476.476 0 0 0 0 .478v7.496c0 .266.212.478.478.478a.476.476 0 0 0 .477-.478V.955h14.682v14.682H8.576a.476.476 0 0 0-.478.478c0 .265.212.478.478.478h7.539a.476.476 0 0 0 .478-.478V.478A.476.476 0 0 0 16.115 0z"
          fill="url(#5co5v2d7ja)"
          transform="translate(.111 .644)"
        />
        <path
          d="M6.212 9.902H.478A.476.476 0 0 0 0 10.38v5.735c0 .265.212.478.478.478h5.734a.476.476 0 0 0 .478-.478V10.38a.48.48 0 0 0-.478-.478zm-.477 5.735H.955v-4.78h4.78v4.78z"
          fill="url(#5co5v2d7ja)"
          transform="translate(.111 .644)"
        />
        <path
          d="M12.75 4.518v2.53c0 .265.213.477.478.477a.476.476 0 0 0 .478-.478V3.365a.476.476 0 0 0-.478-.478H9.545a.476.476 0 0 0-.477.478c0 .265.212.477.477.477h2.53L7.518 8.4a.48.48 0 0 0 .336.817c.12 0 .244-.046.336-.141l4.56-4.557z"
          fill="url(#i4f51few2b)"
          transform="rotate(180 10.597 6.374)"
        />
      </g>
    </SvgIcon>
  );
}

export default ExpandSquareIcon;
