import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface TalentProfileState {
  isResumeGalleryOpen: boolean;
  isSizeCardOpen: boolean;
  isImageGalleryOpen: boolean;
  isVideoGalleryOpen: boolean;
}

export const initialState: TalentProfileState = {
  isResumeGalleryOpen: false,
  isSizeCardOpen: false,
  isVideoGalleryOpen: false,
  isImageGalleryOpen: false,
};

export const talentProfileSlicer = createSlice({
  name: 'talentProfile',
  initialState,
  reducers: {
    onOpenResumeGallery(state: TalentProfileState) {
      state.isResumeGalleryOpen = true;
    },
    onCloseResumeGallery(state: TalentProfileState) {
      state.isResumeGalleryOpen = false;
    },
    onOpenSizeCard(state: TalentProfileState) {
      state.isSizeCardOpen = true;
    },
    onCloseSizeCard(state: TalentProfileState) {
      state.isSizeCardOpen = false;
    },
    onOpenImageGallery(state: TalentProfileState) {
      state.isImageGalleryOpen = true;
    },
    onCloseImageGallery(state: TalentProfileState) {
      state.isImageGalleryOpen = false;
    },
    onOpenVideoGallery(state: TalentProfileState) {
      state.isVideoGalleryOpen = true;
    },
    onCloseVideoGallery(state: TalentProfileState) {
      state.isVideoGalleryOpen = false;
    },
  },
});

export const selectTalentProfileState = (state: RootState) => state.talentProfile;

export const {
  onOpenResumeGallery,
  onCloseResumeGallery,
  onOpenSizeCard,
  onCloseSizeCard,
  onOpenImageGallery,
  onCloseImageGallery,
  onOpenVideoGallery,
  onCloseVideoGallery,
} = talentProfileSlicer.actions;

export default talentProfileSlicer.reducer;
