import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IUploadCreateResponsePayload } from 'shared/interfaces/IUpload';
import { IOnProgress } from 'shared/interfaces/utils/IAxios';

const { POST } = useAxios();
export const uploadDao = () => {
  const uploadMedia = async (formData: FormData, onProgress?: IOnProgress) => {
    const response = await POST<IUploadCreateResponsePayload>({
      url: ENDPOINTS.MEDIA,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  return {
    uploadMedia,
  };
};
