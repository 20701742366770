import { MoreVert } from '@material-ui/icons';
import {
  ActiveIndicatorIcon,
  Avatar,
  EditIconTwoTone,
  NotificationAlertIcon,
  PendingLightBulbIcon,
  StyledMenu,
  Typography,
} from '@room-match/shared-ui-components';
import { sumArrayOfNumbers } from '@room-match/shared-utils';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Body,
  CustomMenuItem,
  CustomMenuItemChildContainer,
  GridBody,
  ItemHeader,
  LightBulb,
  MoreIcon,
  ProjectDetails,
  useStyles,
} from './GridView.styles';

type Props = {
  data: any;
};

const GridView: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const [menuEl, setMenuEl] = useState<Element | null>(null);

  const handleCloseMenu = () => setMenuEl(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setMenuEl(event.currentTarget);

  const handleRedirectEditProject = () => {
    // history.push(navigateToRoute.toEditProject(data.id));
  };

  const countTaskAlerts =
    sumArrayOfNumbers(Object.values(data.aggregates.decisions)) +
    sumArrayOfNumbers(Object.values(data.aggregates.self_tapes)) +
    sumArrayOfNumbers(Object.values(data.aggregates.submissions));

  return (
    <Body>
      <ItemHeader>
        <Avatar title={data.name} />
        <ProjectDetails>
          <Typography fontSize={14}>{data.name}</Typography>
          <Typography fontSize={12}>{data.sag_aftra_contract_type}</Typography>
        </ProjectDetails>
        <MoreIcon onClick={handleOpenMenu}>
          <MoreVert style={{ color: '#6e767f' }} />
        </MoreIcon>
        <StyledMenu
          id="simple-menu"
          anchorEl={menuEl}
          open={Boolean(menuEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
        >
          <CustomMenuItem onClick={() => handleRedirectEditProject()}>
            <CustomMenuItemChildContainer>
              <EditIconTwoTone style={{ fontSize: 16 }} />
              <Typography style={{ fontSize: 13 }}>Edit Project</Typography>
            </CustomMenuItemChildContainer>
          </CustomMenuItem>
        </StyledMenu>
      </ItemHeader>
      <GridBody>
        <LightBulb>
          <NotificationAlertIcon className={countTaskAlerts > 0 ? classes.blueFill : ''} />
          <Typography fontSize={13}>{countTaskAlerts} Task Alerts</Typography>
        </LightBulb>
        <LightBulb>
          {data.status === 'active' && <ActiveIndicatorIcon />}
          {data.status === 'pending' && <PendingLightBulbIcon />}
          <Typography fontSize={13} className={data.status === 'active' ? classes.greenText : classes.orangeText}>
            {data.status}
          </Typography>
        </LightBulb>
      </GridBody>
    </Body>
  );
};

export default GridView;
