import { styled, Button, createStyles, makeStyles, InputAdornment } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    filterIconCls: {
      marginRight: 10,
      '& path': {
        fill: COLORS.PRIMARY,
      },
    },
    cancelIconCls: {
      '& path': {
        fill: COLORS.DANGER,
      },
    },
    bypassSelectCls: {
      '& .MuiSelect-root': {
        color: COLORS.PRIMARY,
      },
      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    active: {
      borderColor: `${COLORS.PRIMARY} !important`,
    },
    disabled: {
      opacity: 0.5,
    },
  }),
);

export const Body = styled('div')(({ theme }) => ({}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
}));

export const ColumnOne = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 1035,
  gap: 15,
  alignItems: 'center',
}));

export const ColumnTwo = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 706,
  gap: 15,
  alignItems: 'center',
}));

export const CustomInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& p': {
    fontSize: 14,
    color: '#fff',
  },
}));

export const CommonSmallWidth = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 247.5,
  gap: 15,
  flexWrap: 'nowrap',
}));

export const MediumWidth = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 510,
  gap: 15,
  flexWrap: 'nowrap',
}));

export const HalfWidth = styled('div')(({ theme }) => ({
  width: '50%',
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  height: 50,
  fontSize: 14,

  '& .MuiSvgIcon-root': {
    width: 16,
    marginRight: 10,
  },
}));

export const Matching = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 4,
  padding: '0 20px',
  height: 50,
  justifyContent: 'space-between',
  alignItems: 'center',

  '& > div:first-child': {
    width: '30%',
  },
  '& > div:last-child': {
    width: '70%',
  },
}));

export const MatchingButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'flex-end',
}));

export const MatchingButtons = styled(Button)(({ theme }) => ({
  background: COLORS.DARK_GRAY,
  borderColor: COLORS.DARK_GRAY,
  border: '1px solid',
  maxWidth: 200,
  height: 32,
}));

export const IsolatedRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  marginTop: 10,
  justifyContent: 'space-between',
}));

export const ChipsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
  justifyContent: 'flex-start',
}));

export const Chips = styled('div')(({ theme }) => ({
  display: 'flex',
  background: COLORS.PRIMARY,
  height: 30,
  minWidth: 106,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 13px',
  borderRadius: 5,

  '& .MuiSvgIcon-root': {
    marginLeft: 15,
    width: 16,
  },
}));

export const IsolatedButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
}));

export const CustomIsolatedButton = styled(Button)(({ theme }) => ({
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.PRIMARY,
  height: 50,
  fontSize: 14,
  padding: '0 20px',

  '& .MuiSvgIcon-root': {
    width: 18,
    marginRight: 10,
  },
}));
