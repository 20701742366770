// import { div } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { StyledTab, StyledTabs } from '@room-match/shared-ui-components';
import { useStyles } from './TalentProfileDetail.styles';
import { useParams } from 'react-router';
import { ITab } from '@room-match/shared-ui-components';
import ResumeContent from './TabContent/ResumeContent/ResumeContent';
import AudioContent from './TabContent/AudioContent';
import CompositeCardContent from './TabContent/CompositeCardContent/CompositeCardContent';
import SocialFeedContent from './TabContent/SocialFeedContent/SocialFeedContent';

const tabs: ITab[] = [
  {
    name: 'resume',
    header: 'Resume',
    component: <ResumeContent />,
  },
  {
    name: 'audio',
    header: 'Audio (200)',
    component: <AudioContent />,
  },

  {
    name: 'composite_card',
    header: 'Composite Card',
    component: <CompositeCardContent />,
  },
  {
    name: 'social_feed',
    header: 'Social Feed',
    component: <SocialFeedContent />,
  },
];
const TalentProfileDetails = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('resume');

  const getActiveTab = useMemo(() => {
    if (!tab) {
      return tabs[0].component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.profileDetail}>
      <StyledTabs value={!tab ? 'resume' : tab} onChange={handleTabChange} aria-label="simple tabs example">
        {tabs.map((tab, index) => (
          <StyledTab key={index} label={tab.header} value={tab.name} disabled={tab.disabled} {...a11yProps(index)} />
        ))}
      </StyledTabs>
      <div className={classes.profileDetailTabContainer}>{getActiveTab}</div>
    </div>
  );
};

export default TalentProfileDetails;
