import { styled, Button, DialogContent, Paper, TextField, makeStyles, createStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Select, StyledDialog, TextInput } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    customContactInput: {
      marginTop: -25,
      '& .react-tel-input': {
        '& .flag-dropdown': {
          border: 'none!important',
          borderRadius: '8px!important',
        },
        '& .form-control': {
          borderRadius: '8px!important',
          border: 'none!important',
          background: '#21262d!important',
        },
        '& .selected-flag': {
          borderRadius: '8px!important',
          border: 'none!important',
          background: '#21262d!important',
        },
      },

      '& .MuiSelect-root': {
        borderRadius: 8,
        background: '#21262d',
      },

      '& .MuiInputBase-root': {
        fontSize: 14,
        background: '#21262d!important',
        height: 50,
        borderRadius: 8,
      },

      '& fieldset': {
        borderColor: '#21262d',
        borderRadius: 8,
      },
      '&:hover fieldset': {
        borderColor: COLORS.PRIMARY,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.PRIMARY,
      },
    },
  }),
);

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
  background: 'none',
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  position: 'relative',
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '570px !important',
    minHeight: 757,
  },

  '& .MuiFormControl-root': {
    margin: '0!important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '50px 40px 35px!important',
  backgroundImage: `linear-gradient(0deg, rgba(13, 17, 23, 1) 0%, rgba(13, 17, 23, 1) 82.5%, rgba(13, 17, 23, 0.9) 89%, rgba(13, 17, 23, 0.9) 100%), url('/casting-background.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));

export const Header = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 20,
}));

export const TalentImageContainer = styled('div')(({ theme }) => ({
  height: 108,
  width: 108,
  borderRadius: 10,
  background: '#0d1117',
  border: '1px solid',
  borderColor: COLORS.PRIMARY,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: '0 auto 20px',
}));

export const AddButton = styled(Button)(({ theme }) => ({
  height: 50,
  marginTop: 30,
  fontSize: 14,
  fontWeight: 'normal',
  borderRadius: 8,
}));

export const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 8,
    background: '#21262d',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#21262d',
      borderRadius: 8,
    },
    '&:hover fieldset': {
      borderColor: COLORS.PRIMARY,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.PRIMARY,
    },
  },
}));

export const ListItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: '#21262d',
  width: '100%',
  padding: '10px',
  borderRadius: '5px',
  '& > *:last-child': {
    marginLeft: 'auto',
  },
}));

export const ProfileImage = styled('div')(({ theme }) => ({
  height: '40px',
  width: '40px',
  background: '#808080',
  marginRight: '10px',
  borderRadius: '5px',
}));

export const CustomPaper = styled(Paper)(({ theme }) => ({
  background: 'none',
  position: 'absolute',
  top: '-5px',
  right: '-1px',
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  '& .MuiAutocomplete-option': {
    background: '#21262d',
  },
  '& li:hover, & li': {
    padding: '0 7px 4.5px',
    background: '#21262d',
    borderColor: '#21262d!important',
    borderRadius: 8,
    marginTop: 10,
    border: 'none',
    outline: 'none',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  background: '#3e8bff',
  height: '50px',
  borderRadius: 8,
  marginTop: 20,
}));

export const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
  borderRadius: 'none',
}));

export const ListImage = styled('img')(({ theme }) => ({
  height: '40px',
  width: '40px',
  background: '#808080',
  marginRight: '10px',
  borderRadius: '5px',
  objectFit: 'cover',
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot': {
    height: '50px !important',
  },
  '& input': {
    padding: '7px 8px !important',
    fontSize: 14,
  },
}));

export const LastGridItem = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  maxWidth: 489,
  bottom: 40,
}));

export const Form = styled('div')(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  margin: '15px 0 70px',
  '& .MuiFormControl-root': {
    margin: '10px 0px',
  },
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
  fontSize: 14,
  height: 50,
  '& .MuiSelect-root': {
    borderRadius: 8,
    background: '#21262d',
    minHeight: 'unset',
    height: 13,
  },

  '& fieldset': {
    borderColor: '#21262d',
    borderRadius: 8,
  },
  '&:hover fieldset': {
    borderColor: COLORS.PRIMARY,
  },
  '&.Mui-focused fieldset': {
    borderColor: COLORS.PRIMARY,
  },

  '& .MuiSelect-iconOutlined': {
    right: '0',
    top: '22px',
  },

  '& .MuiSelect-iconOpen': {
    top: '2px',
    right: '13px',
  },
}));

export const TwoColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  width: '100%',
  position: 'relative',

  '& > div': {
    width: '50%',
  },
}));

export const TwoColumnWithIconButton = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  width: '100%',
  position: 'relative',

  '& > div:first-child': {
    width: '87.5%',
  },
  '& > div:last-child': {
    width: '12.5%',
  },
}));

export const CustomTextInput = styled(TextInput)(({ theme }) => ({
  borderRadius: 8,
  background: '#21262d',
  '& .MuiInputBase-root': {
    fontSize: 14,
    height: '50px',
  },

  '& fieldset': {
    borderColor: '#21262d',
    borderRadius: 8,
  },
  '&:hover fieldset': {
    borderColor: COLORS.PRIMARY,
  },
  '&.Mui-focused fieldset': {
    borderColor: COLORS.PRIMARY,
  },
}));

export const CustomDashContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '49.5%',
  top: 15,
}));

export const IconButton = styled(Button)(({ theme }) => ({
  background: '#21262d',
  height: 50,
  borderRadius: 8,
  minWidth: 50,
}));
