import { styled, Button, DialogContent, InputAdornment, createStyles, makeStyles } from '@material-ui/core';
import { Checkbox, StyledDialog } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    bypassSelectCls: {
      '& .MuiFormControl-root': {
        marginTop: 8,
      },
      '& .MuiInputBase-root ': {
        height: 40,
        width: 185,
        fontSize: 14,

        '& fieldset': {
          borderColor: '#21262d',
          borderRadius: 5,
        },
        '&:hover fieldset': {
          borderColor: COLORS.DARK_BORDER,
        },
        '&.Mui-focused fieldset': {
          borderColor: COLORS.DARK_BORDER,
        },
      },
      '& .MuiSelect-root': {
        color: COLORS.PRIMARY,
        paddingBottom: 17,
      },
      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '17px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
  }),
);

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '1128px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 40px!important',
  background: '#0d1117',
}));

export const HeadingWithoutBorderStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  borderBottom: '1px solid #2d343e',
  paddingBottom: 20,
  marginBottom: 15,
}));

export const HeadingWithBorderStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  borderBottom: '1px solid #2d343e',
  paddingBottom: 20,
  marginBottom: 15,
  '&::before': {
    content: '""',
    background: '#3e8bff',
    height: '1px',
    position: 'absolute',
    width: '50px',
    bottom: '-1px',
    left: '0',
  },
}));

export const Section = styled('div')(({ theme }) => ({
  marginBottom: 20,
}));

export const FilterSelection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > p': {
    minWidth: 90,
  },
}));

export const FilterSelectionItemsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

export const FilterSelectionItemActive = styled(Button)(({ theme }) => ({
  minWidth: 70,
  height: 40,
  border: '1px solid',
  borderColor: COLORS.PRIMARY,
  borderRadius: 5,
}));

export const FilterSelectionItemInactive = styled(Button)(({ theme }) => ({
  minWidth: 70,
  height: 40,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  opacity: 0.5,
}));

export const CastingDirectorsListContainer = styled('div')(({ theme }) => ({
  background: '#1d232c',
  maxHeight: 251,
  width: '100%',
  display: 'flex',
  maxWidth: 1070,
  padding: 20,
  borderRadius: 8,
}));

export const CastingDirectorsListWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  maxHeight: 230,
  flexWrap: 'wrap',
  overflow: 'auto',
}));

export const CheckboxContainer = styled('div')(({ theme }) => ({
  width: '25%',
  margin: '2px 0 7px',
  '& .MuiTypography-root': {
    fontSize: 14,
  },
}));

export const ApplyButton = styled(Button)(({ theme }) => ({
  height: 46,
  maxWidth: 177,
  borderRadius: 5,
  marginRight: 15,
}));

export const ClearButton = styled(Button)(({ theme }) => ({
  height: 46,
  maxWidth: 177,
  background: '#536279',
  borderRadius: 5,
  '&:hover': {
    background: COLORS.BUTTON_LIGHT_GRAY,
  },
}));

export const ValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 15,
  marginLeft: 30,
}));

export const CustomInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& p': {
    fontSize: 14,
    color: '#fff',
  },
}));
