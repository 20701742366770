import { styled, Button } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 40,
  padding: 10,
}));

export const FlexRow = styled('div')(({ theme }) => ({
  //   alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  width: 'auto',
}));

export const MiniRow = styled('div')(({ theme }) => ({
  width: 'auto',
}));

export const QuickSubmitButton = styled(Button)(({ theme }) => ({
  background: '#10181f',
  borderRadius: '5px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
  borderImageSlice: '1',
  backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  boxShadow: '2px 1000px 1px #10181f inset',
  height: 32,
  //   width: '100%',
  maxWidth: 160,
  width: 160,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DismissedButton = styled(Button)(({ theme }) => ({
  background: '#10181f',
  borderRadius: '5px',
  border: '1px solid',
  borderColor: '#e83d3d',
  height: 32,
  maxWidth: 160,
  width: 160,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
