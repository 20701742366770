import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function HistoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.99 4.721v3.2l2.124 2.124a.583.583 0 1 1-.825.825L7.995 8.575a.583.583 0 0 1-.17-.412V4.721a.583.583 0 1 1 1.166 0zM10.703.696H6.113c-1.222 0-2.427.43-3.392 1.268V1.28a.583.583 0 1 0-1.166 0v2.294c0 .322.263.583.583.583h2.294a.583.583 0 1 0 0-1.166H3.33a4.02 4.02 0 0 1 2.784-1.128h4.589a4.01 4.01 0 0 1 4.005 4.006v4.588a4.01 4.01 0 0 1-4.005 4.006H6.113a4.01 4.01 0 0 1-4.006-4.006V8.163a.583.583 0 1 0-1.166 0v2.294a5.178 5.178 0 0 0 5.172 5.173h4.589a5.178 5.178 0 0 0 5.172-5.173V5.87A5.178 5.178 0 0 0 10.702.696z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default HistoryIcon;
