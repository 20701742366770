import { styled } from '@material-ui/core';
import { Typography } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const TitleContainer = styled('div')(({ theme }) => ({
  padding: '20px 10px',
  borderBottom: 'solid 1px #2d343e',
  marginLeft: '-10px',
  marginRight: '-10px',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  margin: '20px 0px',
}));

export const RolesFor = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  marginTop: 10,
}));

export const RolesForItem = styled('div')(({ theme }) => ({
  background: COLORS.DARK,
  border: '1px solid',
  color: COLORS.PRIMARY,
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 30,
  minWidth: 70,
  padding: '0 15px',
}));
