import { styled, Button, Tabs, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { StyledAccordion, Typography } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled(Accordion)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  background: theme.palette.type === 'dark' ? '#06090c' : '#FFFFFF',

  '&.MuiAccordion-root': {
    margin: '16px 0',
  },

  '&:not(:last-child)': {
    marginBottom: 20,
  },

  '&::before': {
    display: 'none',
  },

  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },

  '&.Mui-expanded .MuiAccordionSummary-root': {
    borderBottom: 'solid 1px #242b35',
  },

  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}));

export const NotificationBox = styled('div')(({ theme }) => ({
  background: COLORS.DARK,
  borderRadius: '5px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
  borderImageSlice: '1',
  backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  height: 54,
  width: 50,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ItemHeader = styled(AccordionSummary)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: '0 15px',

  '&.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
    cursor: 'default',
  },

  [theme.breakpoints.down(920)]: {
    flexWrap: 'wrap',
    '& > *:last-child': {
      marginLeft: 'auto',
    },
  },
}));

export const Content = styled(AccordionDetails)(({ theme }) => ({
  padding: 10,
}));

export const ProjectDetails = styled('div')(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: '10px',
  // minWidth: '200px',

  '& > *': {
    whiteSpace: 'nowrap',
  },
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  alignItems: 'center',
  '& .MuiTab-root': {
    minWidth: 'unset',
    padding: '6px 25px',
  },
  '& .MuiTabs-indicator': {
    background: 'none',
  },
  '& .Mui-selected span': {
    color: '#3e8bff',
  },
}));

export const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
}));

export const ProjectDetailsButton = styled(Button)(({ theme }) => ({
  background: '#10181f',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  width: 160,
  height: 32,
  '& svg': {
    width: '18px',
    marginRight: '10px',
  },
}));

export const LightBulb = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  marginLeft: 10,
  '& > *:first-child': {
    marginRight: '10px',
  },
  '& svg': {
    width: '18px',
  },
}));

export const ActiveText = styled(Typography)(({ theme }) => ({
  color: COLORS.GREEN,
}));

export const MoreIcon = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  marginLeft: 10,

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,

    '& path': {
      fill: COLORS.MAIN_GRAY,
    },
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: '4px 0',
  transition: 'none !important',
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
}));

export const CustomMenuItemChildContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
}));

export const ToggleButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  marginLeft: 20,

  '& .MuiSvgIcon-root': {
    width: 13,
  },
}));
export const New = styled('div')(({ theme }) => ({
  height: 22,
  width: 89,
  padding: '0 9px',
  borderRadius: 3,
  backgroundImage: 'linear-gradient(to right, #04aa69 0%, #007e54 100%)',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Revised = styled('div')(({ theme }) => ({
  height: 22,
  width: 89,
  padding: '0 9px',
  borderRadius: 3,
  backgroundImage: 'linear-gradient(to right, #a08525 0%, #e6af3c 100%)',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Viewed = styled('div')(({ theme }) => ({
  height: 22,
  width: 89,
  padding: '0 9px',
  borderRadius: 3,
  backgroundImage: 'linear-gradient(to right, #72b3c7 0%, #5495ab 100%)',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Submitted = styled('div')(({ theme }) => ({
  height: 22,
  width: 89,
  padding: '0 9px',
  borderRadius: 3,
  backgroundImage: 'linear-gradient(to right, #d51bff 0%, #00d4ff 100%)',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Dismissed = styled('div')(({ theme }) => ({
  height: 22,
  width: 89,
  padding: '0 9px',
  borderRadius: 3,
  backgroundImage: 'linear-gradient(to right, #f35050 0%, #7e0101 100%)',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
