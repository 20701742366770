import { Box, Button, Grid, IconButton, InputLabel, TextField } from '@material-ui/core';

import {
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
  PlusIconTwoTone,
  TrashErrorIconTwoTone,
  Switch,
} from '@room-match/shared-ui-components';

import { ContactInput } from 'shared/elements';
import { useStyles } from './Staff.styles';

const Staff = () => {
  const classes = useStyles();

  const toggleSwitch = () => {};

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Add Your Staff</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item className={classes.oneRow}>
          <Grid item className={classes.oneRowField} style={{ maxWidth: 150 }}>
            <InputLabel id="representationStatus">Permission</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[
                { key: 'Agency Representation', value: 'agency_representation' },
                { key: 'Non-Represented', value: 'Non-Represented' },
              ]}
              IconComponent={SelectArrowDownIcon}
              name="representation"
            />
          </Grid>
          <Grid item className={classes.oneRowSmallField}>
            <TextInput label={'First Name'} placeholder="Enter First Name..." />
          </Grid>
          <Grid item className={classes.oneRowSmallField}>
            <TextInput label={'Last Name'} placeholder="Enter Last Name..." />
          </Grid>
          <Grid item className={classes.oneRowField} style={{ maxWidth: 180 }}>
            <TextInput label={'Email (messaging)'} placeholder="Enter Email Address..." />
          </Grid>
          <Grid item className={classes.oneRowField}>
            <InputLabel id="representationStatus" style={{ marginBottom: '-9px' }}>
              Phone (messaging)
            </InputLabel>
            <ContactInput
              country={'us'}
              name="contact_no"
              variant="outlined"
              placeholder="Type here..."
              dropdownStyle={{ height: '50px' }}
              showLabel={false}
            />
          </Grid>
          <Grid item>
            <InputLabel id="representationStatus" style={{ marginBottom: '-9px' }}>
              Admin
            </InputLabel>
            <Switch disabled={false} toggle={toggleSwitch} className={classes.switchCls} />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            fullWidth
            className={classes.buttonCls}
            // onClick={addLocation}
          >
            <PlusIconTwoTone style={{ width: '16px' }} /> &nbsp;Add a New Staff
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Staff;
