export const roleActionAggregatesParser = (type: string) => {
  switch (type) {
    case 'new':
      return 'New';
    case 'hidden':
      return 'Hidden';
    case 'cd-approval':
      return 'CD Approval';
    case 'approved':
      return 'Approved';
    default:
      return 'Hidden';
  }
};
