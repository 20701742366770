import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function AirplaneIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.986 1.787a1.564 1.564 0 0 0-.448-1.325 1.565 1.565 0 0 0-1.325-.448 5.713 5.713 0 0 0-3.26 1.614l-1.915 1.915L4.62 1.156a.586.586 0 0 0-.574.15L2.25 3.102a.586.586 0 0 0 .143.933l6.672 3.481-2.8 2.8-3.981-1.103a.586.586 0 0 0-.571.15L.172 10.904a.586.586 0 0 0 .13.928l5.07 2.796 2.796 5.07a.586.586 0 0 0 .928.13l1.54-1.54a.586.586 0 0 0 .151-.572l-1.102-3.981 2.799-2.8 3.48 6.672a.586.586 0 0 0 .935.143l1.795-1.796c.15-.15.208-.37.15-.574l-2.387-8.418 1.915-1.915a5.713 5.713 0 0 0 1.614-3.26zM8.614 13.147a.586.586 0 0 0-.15.571L9.567 17.7l-.75.749-2.5-4.535a.586.586 0 0 0-.23-.23L1.55 11.182l.749-.749 3.982 1.103a.585.585 0 0 0 .57-.15l8.93-8.93a4.535 4.535 0 0 1 2.588-1.28.398.398 0 0 1 .34.115.397.397 0 0 1 .114.34 4.535 4.535 0 0 1-1.28 2.587l-8.93 8.93zm-4.97-9.781.987-.989 7.458 2.115-2.156 2.155-6.29-3.281zm13.979 12.003-.989.988-3.281-6.29 2.155-2.156 2.115 7.458z"
        fill="#FFEF43"
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
}

export default AirplaneIcon;
