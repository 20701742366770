import { IconButton, Typography } from '@material-ui/core';

import React from 'react';
import { Container, CustomButton, DetailsContainer, ProjectDetails, useStyles } from './ProjectDetailsHeader.styles';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { Avatar, CloseIcon } from '@room-match/shared-ui-components';

type Props = {
  onClose: () => void;
  project: IProjectAttributes | undefined;
  worksheet?: boolean;
};
const ProjectDetailsHeader: React.FC<Props> = ({ onClose, project, worksheet }) => {
  const classes = useStyles();
  return (
    <Container>
      <DetailsContainer>
        <Container>
          <Avatar title={project?.name} style={{ height: '60px', width: '60px' }} />
          <ProjectDetails>
            <Typography variant="h5">{project ? project.name : 'Example Project Name'}</Typography>
            <div>
              <Typography variant="body1" className={classes.darkText}>
                Type:
                <Typography variant="body1" className={classes.blueText}>
                  &nbsp;{project ? project.project_type : 'Commercial'}
                </Typography>
                &nbsp;| Project Union:
                <Typography variant="body1" className={classes.blueText}>
                  &nbsp;{project?.union || 'SAG AFTRA'}
                </Typography>
                &nbsp;({project?.sag_aftra_contract_type || 'Commercial'}) | Project Rate:
                <Typography variant="body1" className={classes.blueText}>
                  &nbsp;Scale
                </Typography>
              </Typography>
            </div>
          </ProjectDetails>
        </Container>
        {!worksheet && (
          <CustomButton>
            <Typography variant="body1">Print</Typography>
          </CustomButton>
        )}
      </DetailsContainer>
      <div style={{ marginLeft: '30px' }}>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon style={{ width: '14px' }} />
        </IconButton>
      </div>
    </Container>
  );
};

export default ProjectDetailsHeader;
