import React, { useState } from 'react';
import { Checkbox, CloseIcon, TextInput, Typography } from '@room-match/shared-ui-components';
import {
  AddButton,
  CloseButton,
  CustomDialog,
  CustomDialogContent,
  Header,
  LabelsContainer,
  TalentImage,
  TalentInformation,
  useStyles,
} from './TalentNoteModal.styles';

type Props = {
  data: any;
  open: boolean;
  onClose: (instance: boolean) => void;
};

const TalentNoteModal: React.FC<Props> = ({ data, open, onClose }) => {
  const classes = useStyles();
  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Header>
          <TalentImage src="/sample_person2.jpg" />
          <TalentInformation>
            <Typography fontSize={25}>{data.name}</Typography>
            <Typography fontSize={14}>{data.agency}</Typography>
            <Typography fontSize={14} color="primary">
              {data.role}
            </Typography>
            <Typography fontSize={14}>
              Status:{' '}
              <span
                className={
                  data.status.includes('Declined')
                    ? classes.text__danger
                    : data.status.includes('Forward') || data.status.includes('Self Tape')
                    ? classes.text__primary
                    : data.status.includes('Confirmed') || data.status.includes('Submitted')
                    ? classes.text__success
                    : classes.text__warning
                }
              >
                {data.status}
              </span>
            </Typography>
          </TalentInformation>
        </Header>
        <LabelsContainer>
          <Typography fontSize={18}>Talent Note:</Typography>
        </LabelsContainer>
        <TextInput textArea={true} textAreaMinRows={6} />

        <AddButton fullWidth variant="contained" color="primary">
          Submit
        </AddButton>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default TalentNoteModal;
