import { IconButton } from '@material-ui/core';
import { PaperPlaneIconTwoTone, ChatIconTwoTone, Input, Typography } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';

import { HistoryDetail } from './TalentHistory.styles';

type Props = {
  isGradientIcon?: boolean;
};
const TalentHistory: React.FC<Props> = ({ isGradientIcon }) => {
  return (
    <HistoryDetail>
      <IconButton style={{ padding: 0 }}>
        {isGradientIcon ? (
          <ChatIconTwoTone fontSize="small" style={{ fontSize: 15 }} />
        ) : (
          <ChatIconTwoTone fontSize="small" style={{ fontSize: 15 }} />
        )}
      </IconButton>
      <div>
        <Typography fontSize={14} style={{ lineHeight: 0.8 }}>
          Has a wife thats pregnant
        </Typography>
        <Typography fontSize={14} color="mainGrey" style={{ marginTop: 6 }}>
          Smith Agency - 3:45 23/1/22
        </Typography>
      </div>
    </HistoryDetail>
  );
};

export default TalentHistory;
