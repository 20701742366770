import { FormikProps, useFormik } from 'formik';
import { AuditionType } from 'shared/enums/AuditionType';
import { IRole, IRoleCreatePayload } from 'shared/interfaces/IRole';
import { array, boolean, date, mixed, number, object, SchemaOf, string } from 'yup';
import GENDERS from 'data/Gender.json';

type RoleCreateFormProps = {
  onSubmit: (values: IRoleCreatePayload) => void;
  roles: IRole[];
};
export const useAddNewRoleForm = ({ onSubmit, roles }: RoleCreateFormProps) => {
  const validationSchema: SchemaOf<IRoleCreatePayload> = object().shape({
    name: string()
      .required('Name is required')
      .test('isUnique', 'Name must be unique per type of audition', (value, context) => {
        return !roles.some(
          (role) => role.attributes.name === value && role.attributes.audition_type === context.parent.audition_type,
        );
      }),
    role_type: string(),
    rate_summary: string(),
    requesting_submissions_from: string(),
    internal_name: string(),
    audition_type: string(),
    principal_role_type: string(),
    background_role_type: string(),
    spot_name: string(),
    submission_date_timezone: string(),
    role_is_for: array(),
    genders: array().min(1, 'Genders must have at least 1 item').required('Required'),
    ethnic_appearance: array(),
    age_range_from: number().default(0),
    age_range_to: number().default(100),
    age_range_type: mixed().oneOf(['Year', 'Month']).defined(),
    description: string(),
    rate_information: string(),
    agents_clients_description: string(),
    talent_description: string(),
    submission_notes: string(),
    use_internal_name: boolean().default(false),
    paying_role: boolean().default(false),
    submission_date_due: date(),
    recommended_skills: array(),
    nudity_sexual_situations: array(),
    allowed_sides_viewers: array(),
    publish_to: array(),
    audition: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    callback: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    fitting: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    working: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
  });

  const initialValues: IRoleCreatePayload = {
    name: '',
    role_type: '',
    rate_summary: '',
    requesting_submissions_from: '',
    internal_name: '',
    audition_type: AuditionType.StudioSession,
    principal_role_type: '',
    background_role_type: '',
    spot_name: '',
    submission_date_timezone: '',
    role_is_for: [],
    genders: GENDERS.map((gender) => gender.value),
    ethnic_appearance: [],
    age_range_from: 1,
    age_range_to: 100,
    age_range_type: 'Year',
    description: '',
    rate_information: '',
    agents_clients_description: '',
    talent_description: '',
    submission_notes: '',
    use_internal_name: false,
    paying_role: false,
    submission_date_due: new Date(),
    audition: {
      location: '',
      show_to: [],
      dates: [],
      note: '',
    },
    callback: {
      location: '',
      show_to: [],
      dates: [],
      note: '',
    },
    fitting: {
      location: '',
      show_to: [],
      dates: [],
      note: '',
    },
    working: {
      location: '',
      show_to: [],
      dates: [],
      note: '',
    },
  };
  const form: FormikProps<IRoleCreatePayload> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return {
    form,
  };
};
