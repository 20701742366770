import { Button, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { Datepicker as MobiScrollDatePicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useStyles } from './DatePicker.styles';
import DatePickerComp from './DatePickerComp/DatePickerComp';

type Props = {
  startIcon?: React.ReactNode;
  onChooseDate?: (value: Date[] | Date, valueText?: string) => void;
  type?: 'range' | 'date' | 'preset-range';
  selectMultiple?: boolean;
  label: string;
};
const DatePicker: React.FC<Props> = ({ startIcon, onChooseDate, type, selectMultiple, label }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  // Open Date Picker Modal
  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  return (
    <div>
      <Button variant="contained" startIcon={startIcon} onClick={() => handleOpenDatePicker()}>
        {label}
      </Button>
      <DatePickerComp
        open={isDatePickerOpen}
        onClose={handleCloseDatePicker}
        onChooseDate={onChooseDate}
        type={type}
        selectMultiple={selectMultiple}
      />
    </div>
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  type: 'date',
};
