import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header__artist: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    videoContainer: {
      marginTop: theme.spacing(2),
      height: 550,
      width: 976,
      margin: 'auto',
      backgroundColor: 'red',
    },
    videoList: {
      display: 'flex',
      width: '100%',
      gap: 8,
      overflow: 'auto',
      paddingBottom: theme.spacing(8),
      marginTop: theme.spacing(2),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },
    },
    videoList__item: {
      minWidth: 230,
      maxWidth: 230,
      height: 130,
      position: 'relative',
    },
    videoList__img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      borderRadius: 8,
      border: '2px solid transparent',
    },
    videoList__img__selected: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    videoList__playButton: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
  }),
);
