import { TextInput, Typography } from '@room-match/shared-ui-components';
import React, { useMemo, useState } from 'react';
import {
  ActionsContainer,
  CustomButton,
  ExpandedContainer,
  HistoryLabelContainer,
  LeftContainer,
  Message,
  MessagesContainer,
  RightContainer,
  SendButton,
  TabButton,
  TabContainer,
  TabsContainer,
  useStyles,
} from './ExpandedDiv.styles';
import QuestionsAnswers from './QuestionsAnswers/QuestionsAnswers';
import SelfTapeFiles from './SelfTapeFiles/SelfTapeFiles';
import SizeCard from './SizeCard/SizeCard';

const mockMessages = [
  {
    name: 'Henry Fox',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
  {
    name: 'You',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
  {
    name: 'Henry Fox',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
];

const tabs = [
  {
    name: 'self_tape_files',
    label: 'Self-tape Files',
    component: <SelfTapeFiles />,
  },
  {
    name: 'size_card',
    label: 'Size Card',
    component: <SizeCard />,
  },
  {
    name: 'q_and_a',
    label: 'Questions & Answers',
    component: <QuestionsAnswers />,
  },
];

const ExpandedDiv = () => {
  const classes = useStyles();
  const [tab, setTab] = useState('self_tape_files');

  const getActiveTab = useMemo(() => {
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab]);

  return (
    <ExpandedContainer>
      <LeftContainer>
        <TabsContainer>
          {tabs.map((i) => (
            <TabButton
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => setTab(i.name)}
              className={tab === i.name ? classes.active : ''}
            >
              {i.label}
            </TabButton>
          ))}
        </TabsContainer>
        <TabContainer>{getActiveTab}</TabContainer>
      </LeftContainer>
      <RightContainer>
        <Typography fontSize={13}>Agent / Talent Notes:</Typography>
        <TextInput
          className={classes.textInputCls}
          multiline
          margin="dense"
          placeholder={'Type note here…'}
          rows={2}
          InputProps={{
            endAdornment: (
              <SendButton fullWidth variant="contained" color="primary">
                Send
              </SendButton>
            ),
          }}
        />
        <HistoryLabelContainer>
          <Typography fontSize={13}>Notes History</Typography>
          <Typography fontSize={13} color="mainGrey">
            3 Total Notes
          </Typography>
        </HistoryLabelContainer>
        <MessagesContainer>
          {mockMessages.map((i) => (
            <Message>
              <Typography fontSize={13} color="primary" className={i.name === 'You' ? classes.text__orange : ''}>
                {i.name}
              </Typography>
              <Typography fontSize={13} color="mainGrey">
                {i.date} - {i.time}
              </Typography>
              <Typography fontSize={14}>{i.message}</Typography>
            </Message>
          ))}
        </MessagesContainer>
        <ActionsContainer>
          <CustomButton fullWidth className="reject" variant="contained">
            Reject
          </CustomButton>
          <CustomButton fullWidth className="submit" variant="contained">
            Submit
          </CustomButton>
        </ActionsContainer>
      </RightContainer>
    </ExpandedContainer>
  );
};

export default ExpandedDiv;
