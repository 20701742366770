import { Box, Button, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, UploadIcon } from '@room-match/shared-ui-components';

import { useStyles } from './FileUpload.styles';

type Props = {
  onFileSelected: (
    name: string,
    type: string,
    image: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => void;
  variant?: 'primaryImage' | 'uploader';
  defaultText: string;
  autoUpload?: boolean;
  img?: string;
};

const FileUpload: React.FC<Props> = ({
  onFileSelected,
  variant = 'primaryImage',
  defaultText = 'Add Logo',
  autoUpload = false,
  img,
}) => {
  const classes = useStyles();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const inputFile = useRef<HTMLInputElement>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        if (file.type.startsWith('image')) {
          const image = new Image();
          reader.onload = () => {
            image.onload = function () {
              onFileSelected(file.name, file.type, reader.result as string, file, {
                width: image.width,
                height: image.height,
              });
            };
            setHasFile(true);
            setFileName(file.name);
            image.src = reader.result as string;
          };
        } else {
          reader.onload = () => {
            onFileSelected(file.name, file.type, reader.result as string, file);
            setHasFile(true);
            setFileName(file.name);
          };
        }

        reader.readAsDataURL(file);
      });
    },
    [onFileSelected],
  );

  useEffect(() => {
    if (autoUpload) {
      if (inputFile.current) {
        inputFile.current.click();
      }
    }
    return () => {};
  }, [autoUpload, inputFile]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Box className={classes.container}>
      <input {...getInputProps()} id="contained-button-file" accept="image/png, image/jpeg, image/jpg, .webp, .heif" />
      <Box {...getRootProps()}>
        {variant === 'uploader' && (
          <Grid className={classes.uploaderContainer}>
            <Grid className={classes.uploaderHeader}>
              <UploadIcon /> &nbsp;
              <Typography fontSize={16}>{defaultText}</Typography>
            </Grid>
            <Typography fontSize={13} color="mainGrey">
              500x500 pixels
            </Typography>
            <Typography fontSize={13} color="mainGrey">
              jpg / png / tif / heic <br />
              (Ideally transparent PNG)
            </Typography>
          </Grid>
        )}
        {variant === 'primaryImage' && (
          <Grid className={classes.uploaderContainer}>
            <img src={img} alt="landscape" className={classes.img} />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default FileUpload;
