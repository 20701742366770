import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const NextIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="17" viewBox="0 0 10 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m.234 8.571 7.195 7.193a.81.81 0 0 0 1.144 0 .806.806 0 0 0 0-1.142L1.948 8l6.624-6.622A.807.807 0 1 0 7.428.236L.233 7.43a.815.815 0 0 0 0 1.142z"
        transform="matrix(-1 0 0 1 9.62 .5)"
        fill={props.htmlColor || '#6B737C'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default NextIcon;
