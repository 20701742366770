import React from 'react';
import { CloseIcon, Input, TextInput, Typography } from '@room-match/shared-ui-components';
import {
  CloseButton,
  CustomDialog,
  CustomDialogContent,
  Heading,
  HistoryLabelContainer,
  Message,
  MessagesContainer,
  SendButton,
  useStyles,
} from './TalentMessageModal.styles';

type Props = {
  data: any;
  open: boolean;
  onClose: (instance: boolean) => void;
};

const mockMessages = [
  {
    name: 'Henry Fox',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
  {
    name: 'You',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
  {
    name: 'Henry Fox',
    date: '26 July 2022',
    time: '08:42 PM',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dapibus et nisl in ultrices. Sed in fringilla risus. Vestibulum ante massa.',
  },
];

const TalentMessageModal: React.FC<Props> = ({ data, open, onClose }) => {
  const classes = useStyles();
  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Heading fontSize={25}>Casting Confirmations / Messages</Heading>
        <Typography fontSize={13}>Add Note:</Typography>
        <TextInput
          className={classes.textInputCls}
          multiline
          margin="dense"
          placeholder={'Type note here…'}
          rows={2}
          InputProps={{
            endAdornment: (
              <SendButton fullWidth variant="contained" color="primary">
                Send
              </SendButton>
            ),
          }}
        />
        <HistoryLabelContainer>
          <Typography fontSize={13}>Notes History</Typography>
          <Typography fontSize={13} color="mainGrey">
            3 Total Notes
          </Typography>
        </HistoryLabelContainer>
        <MessagesContainer>
          {mockMessages.map((i) => (
            <Message>
              <Typography fontSize={13} color="primary" className={i.name === 'You' ? classes.text__orange : ''}>
                {i.name}
              </Typography>
              <Typography fontSize={13} color="mainGrey">
                {i.date} - {i.time}
              </Typography>
              <Typography fontSize={14}>{i.message}</Typography>
            </Message>
          ))}
        </MessagesContainer>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default TalentMessageModal;
