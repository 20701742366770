import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    requests__description__icon: {
      fontSize: '17px !important',
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      border: '1px solid #3e8bff',
      borderRadius: '8px',
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
    },
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      borderRadius: '8px',
    },

    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 15px',
      gap: 10,

      '& > button': {
        padding: 5,
      },
    },

    popoverHeading: {
      marginBottom: 5,
    },
  }),
);

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 15,
}));

export const RequestsDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  alignItems: 'center',
}));

export const RequestsDescriptionItemInformation = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  whiteSpace: 'nowrap',
  justifyContent: 'center',
  height: '100%',

  '& > div:first-child': {
    display: 'flex',
    gap: 5,
  },
}));
