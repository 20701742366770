import { IconButton } from '@material-ui/core';
import { PlayIconTwoTone } from '@room-match/shared-ui-components';
import React from 'react';
import { useStyles } from './TalentMedia.styles';

type Props = {
  isVideo?: boolean;
};
const TalentMedia: React.FC<Props> = ({ isVideo }) => {
  const classes = useStyles();
  return (
    <div>
      {isVideo ? (
        <div className={classes.request__videoPlayerContainer}>
          <img src="/sample_person2.jpg" className={classes.request__videoPlayerThumbnail} />
          <IconButton className={classes.request__videoPlayerButton} size="small">
            <PlayIconTwoTone style={{ fontSize: 30 }} />
          </IconButton>
        </div>
      ) : (
        <div className={classes.request__imageContainer}>
          <img src="/sample_person2.jpg" className={classes.request__image} />
        </div>
      )}
    </div>
  );
};

export default TalentMedia;
