import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '5px 10px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    taskAlertsColored: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '5px',
      height: '113px',
      width: '140px',
      borderRadius: '5px',
      // border: 'solid 1px #d51bff',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
      borderImageSlice: '1',
      backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      '& svg': {
        height: '26px',
        width: '26px',
        marginBottom: '8px',
      },
    },
    taskAlerts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '5px',
      height: '113px',
      width: '140px',
      borderRadius: '5px',
      background: '#0e1319',
      '& svg': {
        height: '26px',
        width: '26px',
        marginBottom: '8px',
      },
    },
  }),
);
