import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { roleDao } from 'shared/dao/roleDao';
import { AuditionType } from 'shared/enums/AuditionType';
import {
  ICreateRoleResponsePayload,
  IRoleCreatePayload,
  IRoleResponsePayload,
  IRoleSideCreatePayload,
  IRoleSideCreateResponsePayload,
  ISingleRoleResponsePayload,
} from 'shared/interfaces/IRole';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getRoles: getRolesDao,
  getSingleRole: getSingleRoleDao,
  createRole: createRoleDao,
  createRoleSide: createRoleSideDao,
} = roleDao();
export const roleService = () => {
  const getRoles = (
    projectId: string,
    auditionType?: AuditionType,
    options?: Omit<
      UseQueryOptions<IRoleResponsePayload, IAxiosErrorResponse, IRoleResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IRoleResponsePayload, IAxiosErrorResponse>(
      ['role', projectId, auditionType],
      () => getRolesDao(projectId, auditionType),
      options,
    );
  };
  const getSingleRole = (projectId: string, roleId: string) => {
    return useQuery<ISingleRoleResponsePayload, IAxiosErrorResponse>(
      ['role', projectId, roleId],
      () => getSingleRoleDao(projectId, roleId),
      {
        enabled: !!roleId,
      },
    );
  };
  const createRole = () => {
    return useMutation<
      ICreateRoleResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IRoleCreatePayload }
    >(({ projectId, payload }) => createRoleDao(projectId, payload));
  };

  const createRoleSide = () => {
    return useMutation<
      IRoleSideCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; roleId: string; payload: IRoleSideCreatePayload }
    >(({ projectId, roleId, payload }) => createRoleSideDao(projectId, roleId, payload));
  };

  return {
    getRoles,
    getSingleRole,
    createRole,
    createRoleSide,
  };
};
