import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        opacity="0.4"
        d="M14.4931 16.7061C14.1026 16.3156 14.1026 15.6824 14.4931 15.2919C14.8837 14.9014 15.5168 14.9014 15.9074 15.2919L19.9074 19.2919C20.2979 19.6824 20.2979 20.3156 19.9074 20.7061C19.5168 21.0967 18.8837 21.0967 18.4931 20.7061L14.4931 16.7061Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20026 10.999C4.20026 14.865 7.33426 17.999 11.2003 17.999C15.0662 17.999 18.2003 14.865 18.2003 10.999C18.2003 7.13303 15.0662 3.99902 11.2003 3.99902C7.33426 3.99902 4.20026 7.13303 4.20026 10.999ZM16.2003 10.999C16.2003 13.7604 13.9617 15.999 11.2003 15.999C8.43883 15.999 6.20026 13.7604 6.20026 10.999C6.20026 8.2376 8.43883 5.99902 11.2003 5.99902C13.9617 5.99902 16.2003 8.2376 16.2003 10.999Z"
        fill="#212121"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
