import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  AirplaneIcon,
  Checkbox,
  CheckedCalendarIcon,
  CheckMarkIconTwoTone,
  ClockCircleIcon,
  DeclineIconTwoTone,
  GradientLogIconTwoTone,
  Input,
  LikeIconTwoTone,
  PaperPlaneIconTwoTone,
  Typography,
} from '@room-match/shared-ui-components';
import React from 'react';
import {
  Body,
  CheckContainer,
  InformationContainer,
  LeftInfo,
  StatusContainer,
  StatusIndicator,
  TalentImage,
  TalentInfo,
  TalentInfoContainer,
  TypeContainer,
  useStyles,
  SmallButton,
  ResponseContainer,
  ResponseButtonsContainer,
  MessageContainer,
  MiniActionsContainer,
  MoreIcon,
  MessageInputContainer,
} from './Item.styles';

type Props = {
  data: any;
};

const Item: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  console.log(data);

  return (
    <Body>
      <StatusIndicator className={'success'}></StatusIndicator>
      <CheckContainer>
        <Checkbox value={false} />
      </CheckContainer>
      <TypeContainer>
        {data.type === 'Booking' ? (
          <CheckedCalendarIcon className="primary" />
        ) : data.type === 'Fitting' ? (
          <LikeIconTwoTone />
        ) : data.type === 'Travel' ? (
          <AirplaneIcon />
        ) : (
          ''
        )}
        <Typography fontSize={14}>{data.type}</Typography>
      </TypeContainer>
      <StatusContainer>
        <Typography fontSize={12} color="mainGrey">
          {data.audition_date}
        </Typography>
        <Typography fontSize={12} color="mainGrey">
          {data.audition_time}
        </Typography>
      </StatusContainer>
      <InformationContainer>
        <LeftInfo>
          <Typography fontSize={14} color="mainGrey">
            {data.agency}
          </Typography>
          <Typography fontSize={14}>{data.project}</Typography>
          <Typography fontSize={14} color="primary">
            {data.role}
          </Typography>
        </LeftInfo>
        <TalentInfoContainer>
          <TalentImage src="/sample_person2.jpg" />
          <TalentInfo>
            <Typography fontSize={15}>{data.name}</Typography>
            <Typography fontSize={14} color="mainGrey">
              {data.number}
            </Typography>
            <Typography fontSize={14} color="mainGrey" className={classes.limit__characters}>
              {data.email}
            </Typography>
          </TalentInfo>
        </TalentInfoContainer>
        <ResponseContainer>
          <ResponseButtonsContainer>
            {data.status === 'Forward To Talent' && (
              <SmallButton variant="contained" color="secondary" fullWidth className="borderGradient">
                Forward
              </SmallButton>
            )}
            {data.type === 'ST Request' && (
              <SmallButton variant="contained" color="secondary" fullWidth className="borderGradient">
                View ST
              </SmallButton>
            )}
            <SmallButton variant="contained" color="secondary" fullWidth className="primary">
              Message
            </SmallButton>
          </ResponseButtonsContainer>
          {data.message && (
            <MessageContainer>
              <Typography fontSize={14} className={classes.limit__characters__long}>
                {data.message}
              </Typography>
            </MessageContainer>
          )}
        </ResponseContainer>
        <MessageInputContainer>
          <Input
            name="general_company_email"
            variant="outlined"
            type="email"
            placeholder="Write a message here…"
            margin={'normal'}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ tabIndex: 1 }}
            InputProps={{ endAdornment: <PaperPlaneIconTwoTone style={{ fontSize: 14 }} /> }}
            style={{ margin: 0, height: '40px', minWidth: '200px' }}
            size="small"
          />
          <SmallButton variant="contained" color="secondary" fullWidth className="primary">
            View
          </SmallButton>
        </MessageInputContainer>
        <MiniActionsContainer>
          <IconButton size="small" disableRipple disableFocusRipple edge="end">
            <GradientLogIconTwoTone style={{ fontSize: 18 }} />
          </IconButton>
          <MoreIcon>
            <MoreVert style={{ color: '#6e767f' }} />
          </MoreIcon>
        </MiniActionsContainer>
      </InformationContainer>
    </Body>
  );
};

export default Item;
