import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TaskIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="vnlrz6z0va">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M13.876 0a1.406 1.406 0 0 1 1.39 1.262l.007.134v.786h2.246a2.12 2.12 0 0 1 2.112 1.966l.005.151V13.2a5.455 5.455 0 0 1 2.182 9.709 5.39 5.39 0 0 1-3 1.085l-.273.006H2.116a2.119 2.119 0 0 1-2.11-1.965L0 21.884V4.298a2.119 2.119 0 0 1 1.965-2.11l.151-.006h2.248v-.786A1.406 1.406 0 0 1 5.626.007L5.76 0h8.116zM4.407 3.273h-2.29a1.024 1.024 0 0 0-1.017.89l-.01.135v17.586a1.024 1.024 0 0 0 .892 1.016l.134.009h13.157a5.455 5.455 0 0 1 3.039-9.813l.233-.005V4.299c0-.528-.4-.963-.913-1.02l-.112-.006h-2.291a1.382 1.382 0 0 1-1.224 1.085l-.129.006H5.76a1.382 1.382 0 0 1-1.353-1.091zm14.138 10.909a4.364 4.364 0 1 0 0 8.727 4.364 4.364 0 0 0 0-8.727zM6.386 17.614c.19.19.21.484.063.696l-.063.076-2.182 2.182a.545.545 0 0 1-.706.056l-.066-.056-1.09-1.091a.546.546 0 0 1 .696-.836l.075.064.705.705 1.796-1.796a.546.546 0 0 1 .772 0zm5.069 2.022a.545.545 0 0 1 0 1.091H8.182a.545.545 0 0 1 0-1.09zm7.09-3.818c.302 0 .546.244.546.546v1.956l.931.93a.546.546 0 1 1-.772.772l-1.09-1.09a.545.545 0 0 1-.16-.387v-2.181c0-.302.244-.546.545-.546zm-7.09 1.636a.545.545 0 0 1 0 1.091H8.182a.545.545 0 1 1 0-1.09zM5.614 12.16a.546.546 0 1 1 .772.773l-2.182 2.181a.545.545 0 0 1-.772 0l-1.09-1.09a.546.546 0 0 1 .771-.773l.705.706zm6.931 2.023a.545.545 0 1 1 0 1.09H8.182a.545.545 0 1 1 0-1.09zm0-2.182a.545.545 0 0 1 0 1.09H8.182a.545.545 0 1 1 0-1.09zM5.614 6.705a.546.546 0 0 1 .772.772L4.204 9.66a.545.545 0 0 1-.772 0l-1.09-1.091a.546.546 0 0 1 .771-.772l.705.705zm11.295 2.022a.545.545 0 0 1 0 1.091H8.182a.545.545 0 0 1 0-1.09zm0-2.182a.545.545 0 0 1 0 1.091H8.182a.545.545 0 0 1 0-1.09zm-3.033-5.454H5.76a.312.312 0 0 0-.296.236l-.01.07v1.57a.312.312 0 0 0 .237.296l.069.01h8.116a.312.312 0 0 0 .297-.236l.009-.07v-1.57a.312.312 0 0 0-.306-.306z"
        fill="url(#vnlrz6z0va)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default TaskIcon;
