import { Box, Button, DialogContent, IconButton, TextareaAutosize } from '@material-ui/core';
import {
  CloseIcon,
  HeartIcon,
  HeartIconTwoTone,
  StarGradientIconTwoTone,
  StyledDialog,
  Typography,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTextAreaStyle } from 'shared/theme/styles/textAreaStyle';
import { useStyles } from './RequestSelfTapesModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};
const RequestSelfTapesModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const textarea_classes = useTextAreaStyle();
  const [selectedTapes, setSelectedTapes] = useState<string[]>([]);

  const handleClickTape = (tape: string) => {
    if (!selectedTapes.includes(tape)) {
      setSelectedTapes((curr) => [...curr, tape]);
    } else {
      const filteredTapes = selectedTapes.filter((t) => t !== tape);
      setSelectedTapes(filteredTapes);
    }
  };

  const isActiveTape = (tape: string) => selectedTapes.includes(tape);

  return (
    <StyledDialog open={open} onClose={onClose} classes={{ paper: classes.request__paper }}>
      <DialogContent classes={{ root: classes.request__dialogContent }}>
        <Box className={classes.request__container}>
          {/* Close Button */}
          <Box className={classes.request__closeButtonContainer}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
          <Box className={classes.request__content}>
            <Box>
              {/* Title  */}
              <Typography fontSize={24} className={classes.request__title}>
                Request Self Tapes
              </Typography>
            </Box>
            {/* Actions */}
            <Box className={classes.request__action}>
              <Button
                variant="outlined"
                classes={{
                  root: clsx(classes.request__actionButton, {
                    [classes.request__actionButton__active]: isActiveTape('1'),
                  }),
                }}
                onClick={() => handleClickTape('1')}
              >
                1
              </Button>
              <Button
                variant="outlined"
                classes={{
                  root: clsx(classes.request__actionButton, {
                    [classes.request__actionButton__active]: isActiveTape('2'),
                  }),
                }}
                onClick={() => handleClickTape('2')}
              >
                2
              </Button>
              <Button
                variant="outlined"
                classes={{
                  root: clsx(classes.request__actionButton, {
                    [classes.request__actionButton__active]: isActiveTape('3'),
                  }),
                }}
                onClick={() => handleClickTape('3')}
              >
                3
              </Button>
              <Button
                variant="outlined"
                classes={{
                  root: clsx(classes.request__actionButton, {
                    [classes.request__actionButton__active]: isActiveTape('4'),
                  }),
                }}
                onClick={() => handleClickTape('4')}
              >
                4
              </Button>
              <Button
                variant="outlined"
                classes={{
                  root: clsx(classes.request__actionButton, {
                    [classes.request__actionButton__active]: isActiveTape('5'),
                  }),
                }}
                onClick={() => handleClickTape('5')}
              >
                5
              </Button>
              <IconButton
                classes={{
                  root: clsx(classes.request__actionIconButton, {
                    [classes.request__actionButton__active]: isActiveTape('star'),
                  }),
                }}
                onClick={() => handleClickTape('star')}
              >
                <StarGradientIconTwoTone style={{ fontSize: 18 }} viewBox="0 0 17 17" />
              </IconButton>
              <IconButton
                classes={{
                  root: clsx(classes.request__actionIconButton, {
                    [classes.request__actionButton__active]: isActiveTape('heart'),
                  }),
                }}
                onClick={() => handleClickTape('heart')}
              >
                <HeartIconTwoTone style={{ fontSize: 18 }} />
              </IconButton>
            </Box>
            {/* Agency */}
            <Box className={classes.request__agencyContainer}>
              <Box className={classes.request__agency}>
                <Typography fontSize={14} fontWeight={500}>
                  Farmer's Friend
                </Typography>
                <Typography fontSize={14} fontWeight={500}>
                  23
                </Typography>
              </Box>
              <Box className={classes.request__agency}>
                <Typography fontSize={14} fontWeight={500}>
                  Farmer's Wife
                </Typography>
                <Typography fontSize={14} fontWeight={500}>
                  23
                </Typography>
              </Box>
              <Box className={classes.request__agency}>
                <Typography fontSize={14} fontWeight={500}>
                  Total Requests
                </Typography>
                <Typography fontSize={14} fontWeight={500}>
                  23
                </Typography>
              </Box>
            </Box>

            {/* Form */}
            <Box className={classes.request__form}>
              <Typography fontSize={12} style={{ color: '#c2ccd8' }}>
                Message for Selections
              </Typography>
              <TextareaAutosize
                minRows={8}
                placeholder="Type here..."
                className={clsx(textarea_classes.textAreaCls, classes.request__textArea)}
              />
            </Box>

            {/* Send Request */}
            <Box className={classes.request__sendContainer}>
              <Button color="primary" variant="contained" fullWidth classes={{ root: classes.request__sendButton }}>
                Send Request
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default RequestSelfTapesModal;
