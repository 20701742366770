import { Grid } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import {
  CalendarIconTwoTone,
  ExpandSquareIcon,
  GridViewIcon,
  HistoryIcon,
  ImageIcon,
  MessengerIcon,
  MicrophoneIcon,
  ResumeIcon,
  VideoPlayIcon,
  Typography,
  InstagramOutlinedIcon,
  TiktokOutlinedIcon,
  YoutubeOutlinedIcon,
  FacebookOutlinedIcon,
  TwitterOutlinedIcon,
  ViewedIcon,
} from '@room-match/shared-ui-components';
import MessageTalentModal from 'components/MessageTalentModal/MessageTalentModal';
import { TalentProfile } from 'components/TalentProfile';
import React, { useEffect, useState } from 'react';
import {
  Body,
  CardContainer,
  CardContent,
  CardFooter,
  DragButton,
  ExpandedIcon,
  ExpandedIconsContainer,
  ExpandedInfo,
  ExpandedInfoContainer,
  ExpandedOption,
  ExpandedTwoColumnInfo,
  MoreIcon,
  SelectCheckboxContainer,
  SelectCustomCheckbox,
  SelectTalentImage,
  SelectTalentImageContainer,
  Sidebar,
  SidebarButton,
  TalentImage,
  TalentImageContainer,
  useStyles,
} from './TalentCard.styles';

const talentData = {
  talent: {
    first_name: 'Natalia',
    last_name: 'Ashley M.',
    statistics: {},
  },
  primary_medium_attachment: {
    modified_attachment: '',
  },
};

type Props = {
  isBeingAssignedToDivision: boolean;
};

const TalentCard: React.FC<Props> = ({ isBeingAssignedToDivision }) => {
  const classes = useStyles();
  const [messageTalentModalOpen, setMessageTalentModalOpen] = useState<boolean>(false);
  const [addTalentModalOpen, setAddTalentModalOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const SidebarButtons = [
    {
      name: 'resume',
      icon: <ResumeIcon style={{ fontSize: 15 }} />,
      component: '',
    },
    {
      name: 'image',
      icon: <ImageIcon style={{ fontSize: 16 }} />,
      component: '',
    },
    {
      name: 'video',
      icon: <VideoPlayIcon style={{ fontSize: 16 }} />,
      component: '',
    },
    {
      name: 'microphone',
      icon: <MicrophoneIcon style={{ fontSize: 16 }} className={classes.white} />,
      component: '',
    },
    {
      name: 'history',
      icon: <HistoryIcon style={{ fontSize: 16 }} />,
      component: '',
    },
    {
      name: 'schedule',
      icon: <CalendarIconTwoTone style={{ fontSize: 16 }} className={classes.white} />,
      component: '',
    },
    {
      name: 'message',
      icon: <MessengerIcon style={{ fontSize: 16 }} />,
      component: '',
    },
  ];

  const expand = () => {};

  useEffect(() => {
    if (isBeingAssignedToDivision) {
      setIsExpanded(false);
    }
  }, [isBeingAssignedToDivision, setIsExpanded]);

  return (
    <CardContainer className={`${isExpanded && `${classes.expandedCard}`}`}>
      <CardContent container>
        {isBeingAssignedToDivision ? (
          <SelectTalentImageContainer>
            <SelectCheckboxContainer>
              <SelectCustomCheckbox checked={false} value={'false'} />
            </SelectCheckboxContainer>
            <SelectTalentImage src="/sample_person2.jpg" />
          </SelectTalentImageContainer>
        ) : (
          <>
            <TalentImageContainer
              className={`${isExpanded && `${classes.expandedTalentImageContainer} ${classes.expandedHeight}`}`}
            >
              <DragButton color="secondary">
                <GridViewIcon viewBox={'0 0 18 18'} />
              </DragButton>
              <TalentImage
                src="/sample_person2.jpg"
                className={`${isExpanded && `${classes.expandedTalentImage} ${classes.expandedHeight}`}`}
              />
            </TalentImageContainer>
            {isExpanded && (
              <ExpandedInfoContainer container>
                <ExpandedInfo>
                  <Typography fontSize={20} style={{ lineHeight: 1.2 }}>
                    Natalia Ashley M.
                  </Typography>
                  <Typography color="primary">Rep: James</Typography>
                </ExpandedInfo>
                <ExpandedTwoColumnInfo container>
                  <Typography fontSize={14}>Age:</Typography>
                  <Typography fontSize={14} color="primary">
                    18-25
                  </Typography>
                </ExpandedTwoColumnInfo>
                <ExpandedTwoColumnInfo container>
                  <Typography fontSize={14}>Height:</Typography>
                  <Typography fontSize={14} color="primary">
                    5'7" / 175cm
                  </Typography>
                </ExpandedTwoColumnInfo>
                <ExpandedTwoColumnInfo container>
                  <Typography fontSize={14}>Weight:</Typography>
                  <Typography fontSize={14} color="primary">
                    100lbs / 49kg
                  </Typography>
                </ExpandedTwoColumnInfo>
                <ExpandedInfo>
                  <Typography fontSize={14} color="mainGrey" style={{ lineHeight: 1.2 }}>
                    Phone Number:
                  </Typography>
                  <Typography fontSize={14} color="primary">
                    (123) 456-7890
                  </Typography>
                </ExpandedInfo>
                <ExpandedInfo>
                  <Typography fontSize={14} color="mainGrey" style={{ lineHeight: 1.2 }}>
                    Email Address:
                  </Typography>
                  <Typography fontSize={14} color="primary">
                    hillary@gmail.com
                  </Typography>
                </ExpandedInfo>
                <ExpandedTwoColumnInfo container>
                  <Typography fontSize={14} color="mainGrey">
                    Social Reach:
                  </Typography>
                  <Typography fontSize={14} color="primary">
                    32k total
                  </Typography>
                </ExpandedTwoColumnInfo>
                <ExpandedIconsContainer container>
                  <ExpandedIcon>
                    <InstagramOutlinedIcon />
                  </ExpandedIcon>
                  <ExpandedIcon>
                    <TiktokOutlinedIcon />
                  </ExpandedIcon>
                  <ExpandedIcon>
                    <YoutubeOutlinedIcon />
                  </ExpandedIcon>
                  <ExpandedIcon>
                    <FacebookOutlinedIcon />
                  </ExpandedIcon>
                  <ExpandedIcon>
                    <TwitterOutlinedIcon />
                  </ExpandedIcon>
                </ExpandedIconsContainer>
              </ExpandedInfoContainer>
            )}
            <Sidebar container className={`${isExpanded && `${classes.expandedSidebar} ${classes.expandedHeight}`}`}>
              {SidebarButtons.map((i) => (
                <SidebarButton
                  onClick={() => {
                    if (i.name === 'message') setMessageTalentModalOpen(true);
                    if (i.name === 'resume') setAddTalentModalOpen(true);
                  }}
                >
                  {i.icon}
                </SidebarButton>
              ))}
              {isExpanded ? (
                <ExpandedOption container className={`${classes.expandIcon}`}>
                  <SidebarButton onClick={() => setIsExpanded(false)}>
                    <ExpandSquareIcon style={{ fontSize: 18 }} />
                  </SidebarButton>
                  <MoreIcon>
                    <MoreVert />
                  </MoreIcon>
                </ExpandedOption>
              ) : (
                <SidebarButton
                  className={`${classes.expandIcon} ${classes.isExpandedIcon}`}
                  onClick={() => setIsExpanded(true)}
                >
                  <ExpandSquareIcon style={{ fontSize: 18 }} />
                </SidebarButton>
              )}
            </Sidebar>
          </>
        )}
      </CardContent>
      {!isExpanded && (
        <CardFooter>
          <Typography fontSize={15}>Natalia Ashley M.</Typography>
          <Typography color="mainGrey" fontSize={13}>
            Rep: James
          </Typography>
          {!isBeingAssignedToDivision && (
            <MoreIcon className={classes.moreIcon}>
              <MoreVert />
            </MoreIcon>
          )}
        </CardFooter>
      )}
      <MessageTalentModal open={messageTalentModalOpen} onClose={() => setMessageTalentModalOpen(false)} />
      <TalentProfile open={addTalentModalOpen} data={talentData} onClose={() => setAddTalentModalOpen(false)} />
    </CardContainer>
  );
};

export default TalentCard;
