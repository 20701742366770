import { styled, Button, Tabs, MenuItem } from '@material-ui/core';
import { Typography } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  background: theme.palette.type === 'dark' ? '#06090c' : '#FFFFFF',

  '&:not(:last-child)': {
    marginBottom: 20,
  },
}));

export const ItemHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: '10px 15px',
  borderBottom: 'solid 1px #242b35',
  [theme.breakpoints.down(920)]: {
    flexWrap: 'wrap',
    '& > *:last-child': {
      marginLeft: 'auto',
    },
  },
}));

export const ProjectDetails = styled('div')(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: '10px',
  // minWidth: '200px',

  '& > *': {
    whiteSpace: 'nowrap',
  },
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    minWidth: 'unset',
    padding: '6px 25px',
  },
  '& .MuiTabs-indicator': {
    background: 'none',
  },
  '& .Mui-selected span': {
    color: '#3e8bff',
  },
}));

export const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
}));

export const ProjectDetailsButton = styled(Button)(({ theme }) => ({
  background: '#10181f',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  marginRight: '10px',
  minWidth: '150px',
  '& svg': {
    width: '18px',
    marginRight: '10px',
  },
}));

export const LightBulb = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  marginLeft: 10,
  '& > *:first-child': {
    marginRight: '10px',
  },
  '& svg': {
    width: '18px',
  },
}));

export const ActiveText = styled(Typography)(({ theme }) => ({
  color: COLORS.GREEN,
}));

export const MoreIcon = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  marginLeft: 20,

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,

    '& path': {
      fill: COLORS.MAIN_GRAY,
    },
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: '4px 0',
  transition: 'none !important',
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
}));

export const CustomMenuItemChildContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
}));
