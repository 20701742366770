import { Login } from 'pages/Login';
import { Registration } from 'pages/Registration';
import { SendVerification } from 'pages/SendVerification';
import { Signup } from 'pages/Signup';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const AsyncDashboard = React.lazy(() => import('pages/Dashboard'));
const AsyncSettings = React.lazy(() => import('pages/Settings'));
const AsyncTalent = React.lazy(() => import('pages/Talent'));

const AsyncMyProjects = React.lazy(() => import('pages/MyProjects'));
const AsyncProject = React.lazy(() => import('pages/MyProjects/Project'));
const AsyncAddNewRole = React.lazy(() => import('pages/MyProjects/Project/Roles/AddNewRole'));
const AsyncAddNewSTRole = React.lazy(() => import('pages/MyProjects/Project/SelfTapes/AddNewSTRole'));
const AsyncCreateProject = React.lazy(() => import('pages/MyProjects/CreateProject'));
const AsyncCreateSession = React.lazy(() => import('pages/MyProjects/CreateSession/CreateSession'));
const AsyncImportSession = React.lazy(() => import('pages/MyProjects/CreateSession/ImportSession'));

const AsyncWorksheet = React.lazy(() => import('pages/Worksheet'));

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={() => <Redirect to={ROUTES.LOGIN} />} />
        <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
        <PublicRoute exact path={ROUTES.SIGNUP} component={Signup} />
        <PublicRoute exact path={ROUTES.SEND_VERIFICATION} component={SendVerification} />
        <PublicRoute exact path={ROUTES.REGISTRATION} component={Registration} />
        <PrivateRoute exact path={ROUTES.AGENCY.DASHBOARD} component={AsyncDashboard} />
        <PrivateRoute exact path={ROUTES.AGENCY.SETTINGS} component={AsyncSettings} />
        <PrivateRoute exact path={ROUTES.AGENCY.TALENT} component={AsyncTalent} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.MY_PROJECTS} component={AsyncMyProjects} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.CREATE_PROJECT} component={AsyncCreateProject} />
        <PrivateRoute
          exact
          path={ROUTES.AGENCY.PROJECTS.SESSION.CREATE_SESSION_MANUAL}
          component={AsyncCreateSession}
        />
        <PrivateRoute
          exact
          path={ROUTES.AGENCY.PROJECTS.SESSION.CREATE_SESSION_IMPORT}
          component={AsyncImportSession}
        />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.EDIT_PROJECT} component={AsyncCreateProject} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.PROJECT_TAB_DETAIL} component={AsyncProject} />

        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.ROLE.CREATE_ROLE} component={AsyncAddNewRole} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.ROLE.CREATE_ST_ROLE} component={AsyncAddNewSTRole} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.ROLE.EDIT_ROLE} component={AsyncAddNewRole} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.PROJECT_CHILDTAB_DETAIL} component={AsyncProject} />
        <PrivateRoute exact path={ROUTES.AGENCY.PROJECTS.PROJECT_DETAIL_CHILDTAB_DETAIL} component={AsyncProject} />
        <PrivateRoute exact path={ROUTES.AGENCY.WORKSHEET.WORKSHEET} component={AsyncWorksheet} />
        <PrivateRoute exact path={ROUTES.AGENCY.WORKSHEET.WORKSHEET_CHILDTAB} component={AsyncWorksheet} />
        <Route path="*" exact component={() => <h1>Not Found</h1>} />
      </Switch>
    </>
  );
};

export default Routes;
