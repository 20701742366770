import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MessageRoundIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="xso5qjpvha">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16 0C7.157 0 0 7.156 0 16c0 2.806.732 5.547 2.122 7.965L.06 30.367a1.25 1.25 0 0 0 1.573 1.573l6.402-2.062A15.963 15.963 0 0 0 16 32c8.843 0 16-7.156 16-16 0-8.843-7.156-16-16-16zm0 29.5a13.46 13.46 0 0 1-7.146-2.045 1.25 1.25 0 0 0-1.046-.13l-4.621 1.488 1.488-4.621a1.25 1.25 0 0 0-.13-1.046A13.463 13.463 0 0 1 2.5 16C2.5 8.556 8.556 2.5 16 2.5S29.5 8.556 29.5 16 23.444 29.5 16 29.5zM17.563 16a1.563 1.563 0 1 1-3.126 0 1.563 1.563 0 0 1 3.126 0zm6.25 0a1.563 1.563 0 1 1-3.126 0 1.563 1.563 0 0 1 3.125 0zm-12.5 0a1.563 1.563 0 1 1-3.126 0 1.563 1.563 0 0 1 3.126 0z"
        fill="url(#xso5qjpvha)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default MessageRoundIcon;
