import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { userLogout } from 'shared/redux/slicers/user.slicer';

import { CustomIconButton, UserContainerButton, useStyles } from './Header.style';
import { ROUTES } from 'shared/constants/ROUTES';
import { NotificationsAlertWhiteIcon, SettingsIconTwoTone, SignOutIconTwoTone } from '@room-match/shared-ui-components';
import { useAppSelector } from 'shared/redux/store';
import { navigateToRoute } from 'shared/utils/navigateToRoute';

type Props = {
  isDrawerCollapse?: boolean;
  handleDrawerToggle: () => void;
};

const Header: React.FC<Props> = ({ isDrawerCollapse, handleDrawerToggle }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { user: userState, app: appState } = useAppSelector((state) => state);
  const { user } = userState;
  const { headerTitle } = appState;

  const handleLogOut = async () => {
    dispatch(userLogout());
    queryClient.clear();
    window.location.href = '/login';
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !isDrawerCollapse,
      })}
    >
      <Toolbar style={{ minHeight: '60px', padding: '0px 13px' }}>
        <CustomIconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, {})}
        >
          <MenuIcon />
        </CustomIconButton>
        <Typography variant="body1">{headerTitle}</Typography>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.sectionDesktop} style={{ minHeight: '60px' }}>
          <UserContainerButton onClick={() => history.push(ROUTES.CASTING.PROFILE)}>
            <Typography variant="body2">John Doe</Typography>
            <div />
          </UserContainerButton>
          <CustomIconButton>
            <NotificationsAlertWhiteIcon />
          </CustomIconButton>
          <CustomIconButton onClick={() => history.push(navigateToRoute.toSetting('company_information'))}>
            <SettingsIconTwoTone />
          </CustomIconButton>
          <CustomIconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIconTwoTone />
          </CustomIconButton>
        </div>
        <div className={classes.sectionMobile} style={{ minHeight: '60px' }}>
          <CustomIconButton
            aria-label="show more"
            aria-controls="primary-search-account-menu-mobile"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIconTwoTone />
          </CustomIconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
