import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    profileDetail: {
      marginTop: theme.spacing(2),

      '& .MuiTabs-flexContainer': {
        background: '#121820',
      },
    },
    profileDetailTabContainer: {
      minHeight: 500,
      backgroundColor: theme.palette.customBlack.dark,
      // padding: theme.spacing(4, 3),
      padding: '20px',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  }),
);
