import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';

import { ContactFlex, Container, TitleContainer, useStyles } from './ProjectDetailsCreativeTeam.styles';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { UserCircleIconTwoTone } from '@room-match/shared-ui-components';
import { sharedWithValueParser } from '@room-match/shared-utils';

type Props = {
  project: IProjectAttributes | undefined;
  worksheet?: boolean;
};
const ProjectDetailsCreativeTeam: React.FC<Props> = ({ project, worksheet }) => {
  const classes = useStyles();
  return (
    <div>
      <TitleContainer>
        <Typography variant="h5">Creative Team</Typography>
      </TitleContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">{project?.producer?.name || 'Juan Dela Cruz'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Producer
                </Typography>
                {!worksheet && (
                  <Typography variant="body2" className={classes.darkText}>
                    Shared with:
                    <Typography variant="body2" className={classes.blueText}>
                      &nbsp;
                      {project?.producer?.show_to && project?.producer.show_to.length > 0
                        ? project?.producer.show_to.map((sharedValue) => sharedWithValueParser(sharedValue)).join(', ')
                        : 'Agents, CastingApp Notice Board'}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">{project?.director?.name || 'Bruce Johnson'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Director
                </Typography>
                {!worksheet && (
                  <Typography variant="body2" className={classes.darkText}>
                    Shared with:
                    <Typography variant="body2" className={classes.blueText}>
                      &nbsp;
                      {project?.director?.show_to && project?.director.show_to.length > 0
                        ? project?.director.show_to.map((sharedValue) => sharedWithValueParser(sharedValue)).join(', ')
                        : 'Agents, CastingApp Notice Board'}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">{project?.production_company?.name || 'Neon Oven Media'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Production Company
                </Typography>
                {!worksheet && (
                  <Typography variant="body2" className={classes.darkText}>
                    Shared with:
                    <Typography variant="body2" className={classes.blueText}>
                      &nbsp;
                      {project?.production_company?.show_to && project?.production_company.show_to.length > 0
                        ? project?.production_company.show_to
                            .map((sharedValue) => sharedWithValueParser(sharedValue))
                            .join(', ')
                        : 'Agents, CastingApp Notice Board'}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </ContactFlex>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContactFlex>
              <UserCircleIconTwoTone />
              <Box>
                <Typography variant="body2">{project?.ad_agency?.name || 'Activations'}</Typography>
                <Typography variant="body2" className={classes.darkText}>
                  Ad Agency
                </Typography>
                {!worksheet && (
                  <Typography variant="body2" className={classes.darkText}>
                    Shared with:
                    <Typography variant="body2" className={classes.blueText}>
                      &nbsp;
                      {project?.ad_agency?.show_to && project?.ad_agency.show_to.length > 0
                        ? project?.ad_agency.show_to.map((sharedValue) => sharedWithValueParser(sharedValue)).join(', ')
                        : 'Agents, CastingApp Notice Board'}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </ContactFlex>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProjectDetailsCreativeTeam;
