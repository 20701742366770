export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  SEND_VERIFICATION: '/send_verification',
  REGISTRATION: '/registration',
  COMPLETE_PROFILE: '/complete_profile',
  AGENCY: {
    DASHBOARD: '/agency/dashboard',
    SETTINGS: '/agency/settings/:tab',
    TALENT: '/agency/talent/:tab',
    PROJECTS: {
      MY_PROJECTS: '/agency/my_projects/:tab',
      PROJECT_TAB_DETAIL: '/agency/my_projects/:projectId/:tab',
      PROJECT_CHILDTAB_DETAIL: '/agency/my_projects/:projectId/:tab/:childTab',
      PROJECT_DETAIL_CHILDTAB_DETAIL: '/agency/my_projects/:projectId/:tab/:projectDetailId/:childTab',
      CREATE_PROJECT: '/agency/my_projects/create/:type',
      EDIT_PROJECT: '/agency/my_projects/:projectId/edit',
      SESSION: {
        CREATE_SESSION_MANUAL: '/agency/my_projects/session/create/manual',
        CREATE_SESSION_IMPORT: '/agency/my_projects/session/create/import',
      },
      ROLE: {
        CREATE_ROLE: '/agency/my_projects/:projectId/role/create',
        CREATE_ST_ROLE: '/agency/my_projects/:projectId/role/create/st-role',
        EDIT_ROLE: '/agency/my_projects/:projectId/role/:roleId/edit',
      },
    },
    WORKSHEET: {
      WORKSHEET: '/agency/worksheet/:tab',
      WORKSHEET_CHILDTAB: '/agency/worksheet/:tab/:childTab',
    },
  },
  CASTING: {
    PROFILE: '/casting/profile',
    SETTINGS: '/casting/settings',
    MY_PROJECTS: '/casting/my_projects',
    CREATE_PROJECT: '/casting/my_projects/create',
    CREATE_SESSION: '/casting/my_sessions/create',
    PROJECT: '/casting/my_projects/project',
    PROJECTS: {
      ROLES: {
        ROLES: '/roles',
        CREATE_NEW_ROLE: '/roles/create_new_role',
      },
      SELFTAPES: {
        CREATE_NEW_ST_ROLE: '/self_tapes/create_new_role',
      },
    },
    DASHBOARD: '/casting/dashboard',
    MESSAGES: '/casting/messages',
    SCHEDULES_SESSIONS: '/casting/schedules_sessions',
    TALENTS: '/casting/talents',
    COMPANIES: '/casting/companies',
    CONTACTS: '/casting/contacts',
  },
};
