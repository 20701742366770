import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MessengerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="147.119%" y1=".886%" x2="-228.617%" y2="148.699%" id="51zifr82ra">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M7.467 0A7.463 7.463 0 0 0 0 7.467c0 1.31.342 2.588.99 3.717L.028 14.17a.583.583 0 0 0 .734.734l2.988-.962a7.45 7.45 0 0 0 3.717.99 7.463 7.463 0 0 0 7.466-7.466A7.463 7.463 0 0 0 7.467 0zm0 13.767a6.282 6.282 0 0 1-3.335-.955.584.584 0 0 0-.488-.06l-2.157.694.695-2.157a.584.584 0 0 0-.06-.488 6.283 6.283 0 0 1-.955-3.334c0-3.474 2.826-6.3 6.3-6.3 3.473 0 6.3 2.826 6.3 6.3 0 3.473-2.827 6.3-6.3 6.3zm.729-6.3a.73.73 0 1 1-1.459 0 .73.73 0 0 1 1.459 0zm2.917 0a.73.73 0 1 1-1.459 0 .73.73 0 0 1 1.459 0zm-5.834 0a.73.73 0 1 1-1.458 0 .73.73 0 0 1 1.458 0z"
        transform="translate(.94 .407)"
        fill="url(#51zifr82ra)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default MessengerIcon;
