import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import { PlayIconTwoTone, Typography } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './TalentSkillList.styles';

const TalentSkillList = () => {
  const classes = useStyles();
  return (
    <div className={classes.skillsListContainer}>
      <Card className={classes.card}>
        <CardContent>
          {/* Header */}
          <div className={classes.skillListHeader}>
            <Typography variant="subHeading" color="primary">
              Skills
            </Typography>
            {/* Legends */}
            <div className={classes.skillListLegend}>
              <div className={classes.skillListLegend__item}>
                <div className={classes.legendCircle} style={{ backgroundColor: '#b4c5df' }}></div>
                <Typography fontSize={12}>Basic</Typography>
              </div>
              <div className={classes.skillListLegend__item}>
                <div className={classes.legendCircle}></div>
                <Typography fontSize={12}>Intermerdiate</Typography>
              </div>
              <div className={classes.skillListLegend__item}>
                <div
                  className={classes.legendCircle}
                  style={{ backgroundImage: 'linear-gradient(to bottom, #209378, #0b6353)' }}
                ></div>
                <Typography fontSize={12}>Expert / Professional</Typography>
              </div>
            </div>
          </div>
          {/* Skill List Items */}
          <div className={classes.skillsListItemsContainer}>
            {/* Skill Item */}
            <div className={classes.skillsList__item}>
              <Typography>Dance</Typography>
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Skill Item */}
            <div className={classes.skillsList__item}>
              <Typography>Dialects</Typography>
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Skill Item */}
            <div className={classes.skillsList__item}>
              <Typography>Gym Sports</Typography>
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Skill Item */}
            <div className={classes.skillsList__item}>
              <Typography>Driving, Boating & Piloting</Typography>
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__inter]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__expert]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item lg={2}>
                  <div
                    className={clsx(classes.skillList__playerItem, {
                      [classes.skillList__playerItem__basic]: true,
                    })}
                  >
                    <Typography fontSize={12}>Ballroom</Typography>
                    <IconButton size="small">
                      <PlayIconTwoTone fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TalentSkillList;
