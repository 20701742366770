import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      gap: '20px',

      '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
        display: 'none',
        webkitAppearance: 'none',
      },
    },
    sectionContainer: {
      border: 'solid 1px #282f3a',
      background: '#1d232c',
      padding: '25px',
      borderRadius: '8px',

      '& > .MuiGrid-root': {
        marginTop: '10px',
      },

      '&.description': {
        textAlign: 'center',
        '& .MuiTypography-root': {
          maxWidth: '1008px',
          margin: '0 auto',
        },
      },

      '& .heading': {
        paddingBottom: '25px',
        marginBottom: '20px',
        borderBottom: '2px solid #2d343e',
        position: 'relative',

        '&::before': {
          content: '""',
          background: '#3e8bff',
          height: '2px',
          position: 'absolute',
          width: '50px',
          bottom: '-2px',
        },
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },

    fullWidth: {
      width: '100%',
    },

    twoColumn: {
      display: 'flex',
      gap: '30px',
      width: '100%',
      flexWrap: 'nowrap',

      '& > div': {
        width: '50%',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& > div': {
          width: '100%',
        },
      },
    },
    radioFormRoot: {
      '& span': {
        marginTop: '0px !important',
      },
    },

    radioFormLabel: {
      fontSize: 12,
      marginTop: '0px !important',
      '& span': {
        marginTop: '0px !important',
      },
    },
    twoColumnInline: {
      display: 'flex',
      gap: '20px',
      width: '100%',
      flexWrap: 'nowrap',
      alignItems: 'center',

      '& > div': {
        width: '50%',
      },

      '& span': {
        marginTop: '12px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: '0',
        '& > div': {
          width: '100%',
        },

        '& span': {
          margin: '0 0 -10px',
        },
      },
    },

    textAreaCls: {
      background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
      border: theme.palette.type === 'dark' ? 'solid 1px #2d343e' : 'solid 1px #d5dbe3',
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'default',
      width: '100%',
      fontSize: '14px',
      marginTop: '10px',
      padding: '18.5px 14px',
      borderRadius: '4px',
      resize: 'none',
      outline: 'none',
      fontFamily: "'Roboto', sans-serif",
      '&:hover': {
        border: theme.palette.type === 'dark' ? 'solid 1px #FFFFFF' : 'solid 1px #0e1319',
      },
      '&:focus': {
        border: theme.palette.type === 'dark' ? 'solid 1px #3e8bff' : 'solid 1px #0e1319',
      },

      '&input::placeholder': {
        color: '#6e767f',
        letterSpacing: '1px',
        fontWeight: '500',
      },
    },

    notFullElements: {
      '& > div:last-child > div': {
        marginTop: '15px',
        '& > div': {
          marginTop: '10px',
        },
      },
    },

    roleEthnicAppearance: {
      '& > div': {
        marginTop: '10px',
        '& > div > div:not(:first-child)': {
          marginTop: '10px',
        },
      },
    },

    otherRoleEthnicAppearance: {
      '& .MuiInputBase-root': {
        padding: '5px 9px 9px',
      },
    },

    twoColumnChipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      '& > div': {
        flex: '0 49.25%',

        [theme.breakpoints.down('md')]: {
          flex: '100%',
        },
      },
    },

    customChip: {
      width: '100%',
      borderRadius: '5px',
      background: '#2e3540',
      border: 'none',
      justifyContent: 'space-between',

      '& .MuiChip-deleteIcon': {
        height: '15px',
        width: '20px',
      },
    },

    customCard: {
      background: '#1a2029',
      border: '1px solid #2d343e',
      borderRadius: '5px',

      '& .MuiCardHeader-root': {
        background: '#131920',
        marginTop: '0!important',
        borderBottom: '1px solid #2d343e',
        padding: '20px',

        '& .MuiCardHeader-title': {
          fontSize: '16px',
        },
      },

      '& .MuiCardContent-root': {
        marginTop: '0!important',
        padding: '20px',
      },
    },

    scheduleActions: {
      marginTop: '20px',
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '20px',

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        '& > div': {
          flex: '100%',
        },
      },

      '& > div': {
        flex: '33.33%',

        '& .MuiButton-root': {
          background: '#0e1319',
          color: '#fff',
          width: '100%',
          padding: '15px',
          height: '50px',

          '&:hover': {
            background: '#3e8bff',

            '& svg path': {
              transition: 'background-color 250ms',
              fill: '#fff',
            },
          },
        },
      },
    },

    showToContainer: {
      display: 'flex',

      '& > span': {
        margin: '-2px 14px 0 0',
        fontSize: '13px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',

        '& > span': {
          marginBottom: '10px',
        },
      },
    },

    scheduleChips: {
      marginTop: '20px',
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      '& .MuiChip-root': {
        width: 'auto',

        '& .MuiSvgIcon-root': {
          marginLeft: '20px',
        },
      },
    },

    publicationCheckBox: {
      display: 'flex',
      margin: '40px 0 20px',
      flexWrap: 'wrap',

      '& > div:not(:last-child)': {
        marginRight: '40px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& > div:not(:last-child)': {
          marginRight: '0',
        },
        '& > div': {
          marginTop: '10px',
        },
      },
    },

    sectionActionButtons: {
      display: 'flex',
      gap: '20px',
      '& .MuiButton-root': {
        width: '270px',
        height: '50px',
      },

      '& .back': {
        background: '#303946',
        color: '#fff',
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        '& .MuiButton-root': {
          width: '100%',
        },
      },
    },

    fullButton: {
      width: '100%',
      height: '50px',
      marginTop: '10px',
      background: '#0e1319',
      color: '#fff',

      '&:hover': {
        background: '#3e8bff',
      },
    },

    representativeList: {
      '& .MuiListItem-container:not(:last-child)': {
        marginBottom: '15px',
      },

      marginTop: '5px',
      '& .MuiListItem-root': {
        background: '#2e3540',
        borderRadius: '5px',

        '& .MuiListItemText-multiline': {
          display: 'flex',
          gap: '5px',
          fontSize: '13px',

          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '0',
          },

          '& p': {
            color: '#3e8bff',
          },
        },

        '& .MuiListItemIcon-root': {
          minWidth: '30px',
        },

        '& .MuiSvgIcon-root:not(.document)': {
          width: '15px',
          height: '12px',
        },

        '& .MuiSvgIcon-root': {
          width: '21px',
          height: '16px',
          '& path': {
            fill: '#fff',
          },
        },
      },

      '& .MuiListItemSecondaryAction-root': {
        '& .MuiSvgIcon-root': {
          width: '18px',
          height: '18px',
        },
      },
    },
    genderContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    genderAccordionError: {
      borderColor: 'red',
    },
    genderTextError: {
      color: 'red',
    },
    prevsideContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    prevSideItemContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    prevSideItem__detailContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },

    publicationCard: {
      background: '#14181e',

      '& .MuiCardContent-root': {
        padding: '35px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        '& .MuiGrid-root': {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },

    imageList: {
      display: 'flex',
      gap: '10px',
      marginTop: '15px!important',

      '& .MuiImageListItem-root': {
        flexBasis: '20%',
        height: '147px!important',
        maxWidth: '142px',
        flexGrow: '1',

        '& .MuiImageListItem-item': {
          borderRadius: '5px',
          maxWidth: '147px',
        },

        '& .MuiImageListItemBar-root': {
          background: 'none',
          height: 'auto',
          top: '3px',
          right: '3px',

          '& .MuiSvgIcon-root': {
            height: '20px',
            width: '20px',
          },

          '& .MuiButtonBase-root': {
            background: 'rgba(0,0,0,0.4)',
            borderRadius: '8px',
            padding: '3px',
          },
        },
      },
    },
  }),
);
