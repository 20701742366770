import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DownloadIconTwoTone, PlayIconTwoTone, Typography } from '@room-match/shared-ui-components';

import React from 'react';
import { useDispatch } from 'react-redux';
import { onOpenResumeGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { useStyles } from './ResumeContent.styles';

const ResumeContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  function createData(year: string, title: string, role: string, director: string, media: boolean) {
    return { year, title, role, director, media };
  }

  const rows = [
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', false),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', true),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', true),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', false),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', true),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', false),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', true),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', false),
    createData('2010', 'The Wannabes (Feature)', 'Andrew', 'Ian Brown', true),
  ];

  const handleResumeGalleryOpen = () => {
    dispatch(onOpenResumeGallery());
  };

  return (
    <Box className={classes.resumeContainer}>
      {/* Resume List */}
      <Box className={classes.resumeList}>
        {/* Resume Item */}
        <Box className={classes.resumeList__item}>
          <Box className={classes.resumeList__titleContainer}>
            <Typography color="primary">FILM</Typography>
            <Button
              startIcon={<DownloadIconTwoTone style={{ fontSize: 18, marginTop: 3 }} />}
              variant="contained"
              color="secondary"
              disableElevation
              style={{ backgroundColor: '#2c3643' }}
            >
              Download Resume
            </Button>
          </Box>

          <TableContainer component={Box} className={classes.resumeList__tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Director</TableCell>
                  <TableCell>Media</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.resumeList__tableData} width="10%">
                      {row.year}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} scope="row" width="35%">
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="20%">
                      {row.role}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="20%">
                      {row.director}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="15%">
                      {row.media ? (
                        <Box className={classes.resumeList__mediaContainer}>
                          <IconButton size="small" onClick={() => handleResumeGalleryOpen()}>
                            <PlayIconTwoTone style={{ fontSize: 16 }} />
                          </IconButton>
                          <Typography fontSize={14}>View Media</Typography>
                        </Box>
                      ) : (
                        <Box style={{ marginLeft: 3 }}>
                          <Typography>—</Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* Resume Item */}
        <Box className={classes.resumeList__item}>
          <Box className={classes.resumeList__titleContainer}>
            <Typography color="primary">FILM</Typography>
          </Box>

          <TableContainer component={Box} className={classes.resumeList__tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Director</TableCell>
                  <TableCell>Media</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.resumeList__tableData} width="10%">
                      {row.year}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} scope="row" width="35%">
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="20%">
                      {row.role}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="20%">
                      {row.director}
                    </TableCell>
                    <TableCell className={classes.resumeList__tableData} width="15%">
                      {row.media ? (
                        <Box className={classes.resumeList__mediaContainer}>
                          <IconButton size="small" onClick={() => handleResumeGalleryOpen()}>
                            <PlayIconTwoTone style={{ fontSize: 16 }} />
                          </IconButton>
                          <Typography fontSize={14}>View Media</Typography>
                        </Box>
                      ) : (
                        <Box style={{ marginLeft: 3 }}>
                          <Typography>—</Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default ResumeContent;
