import React, { useState } from 'react';
import { DialogContent, Typography } from '@material-ui/core';
import { useStyles } from './ProjectDetails.styles';
import { useDispatch } from 'react-redux';

import ProjectDetailsHeader from './ProjectDetailsHeader/ProjectDetailsHeader';
import ProjectDetailsQr from './ProjectDetailsQr/ProjectDetailsQr';
import ProjectDetailsConflictsAndUsage from './ProjectDetailsConflictsAndUsage/ProjectDetailsConflictsAndUsage';
import ProjectDetailsCCI from './ProjectDetailsCCI/ProjectDetailsCCI';
import ProjectDetailsCreativeTeam from './ProjectDetailsCreativeTeam/ProjectDetailsCreativeTeam';
import ProjectDetailsAdditional from './ProjectDetailsAdditional/ProjectDetailsAdditional';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { StyledDialog } from '@room-match/shared-ui-components';
import ProjectDetailsSummary from './ProjectDetailsSummary/ProjectDetailsSummary';
import ProjectDetailsActions from './ProjectDetailsActions/ProjectDetailsActions';

type Props = {
  open: boolean;
  onClose: () => void;
  project: IProjectAttributes | undefined;
  worksheet?: boolean;
};
const ProjectDetails: React.FC<Props> = ({ open, onClose, project, worksheet }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      className={classes.profileDialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <ProjectDetailsHeader onClose={onClose} project={project} worksheet={worksheet} />
        {!worksheet && <ProjectDetailsSummary project={project} />}
        <br />
        {/* <ProjectDetailsQr /> */}
        <ProjectDetailsConflictsAndUsage project={project} />
        <ProjectDetailsCCI project={project} />
        <br />
        <ProjectDetailsCreativeTeam project={project} worksheet={worksheet} />
        <ProjectDetailsAdditional project={project} />
        {worksheet && <ProjectDetailsActions />}
      </DialogContent>
    </StyledDialog>
  );
};

export default ProjectDetails;
