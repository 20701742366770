import { styled, Button, MenuItem, createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles((theme) =>
  createStyles({
    greenText: {
      color: '#6edc11',
      marginRight: '10px',
      textTransform: 'capitalize',
    },
    orangeText: {
      color: '#ddb252',
      marginRight: '10px',
      textTransform: 'capitalize',
    },
    blueFill: {
      '& svg path': {
        fill: '#3e8bff',
      },
    },
  }),
);

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  // width: '412px',
  background: theme.palette.type === 'dark' ? '#06090c' : '#FFFFFF',
}));

export const ItemHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: '10px 15px',
  borderBottom: 'solid 1px #242b35',
  [theme.breakpoints.down(920)]: {
    flexWrap: 'wrap',
    '& > *:last-child': {
      marginLeft: 'auto',
    },
  },
}));

export const ProjectDetails = styled('div')(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: '10px',
  // minWidth: '200px',
  '& > *:last-child': {
    color: '#6e767f',
  },
  '& > *': {
    whiteSpace: 'nowrap',
  },
}));

export const MoreIcon = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  marginLeft: 20,

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,

    '& path': {
      fill: COLORS.MAIN_GRAY,
    },
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: '4px 0',
  transition: 'none !important',
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
}));

export const CustomMenuItemChildContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
}));

export const GridBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px',
}));

export const LightBulb = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  '& > *:first-child': {
    marginRight: '10px',
  },
  '& svg': {
    width: '18px',
  },
}));
