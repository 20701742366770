import React, { useState } from 'react';
import { Checkbox, CloseIcon, Typography } from '@room-match/shared-ui-components';
import {
  AddButton,
  CheckboxContainer,
  CloseButton,
  CustomCheckbox,
  CustomDialog,
  CustomDialogContent,
  DivisionItem,
  DivisionList,
  Header,
  LabelsContainer,
  TalentImage,
  TalentInformation,
  TextButton,
} from './AssignTalentToDivisionModal.styles';

type Props = {
  open: boolean;
  onClose: (instance: boolean) => void;
};
const AssignTalentToDivisionModal: React.FC<Props> = ({ open, onClose }) => {
  const divisionList = ['My Talent', 'Commercial', 'Print', 'Voice-Over'];

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Header>
          <TalentImage src="/sample_person2.jpg" />
          <TalentInformation>
            <Typography fontSize={25}>Natalia Ashley M.</Typography>
            <Typography fontSize={18}>Divisions:</Typography>
            <Typography fontSize={14} color="primary" style={{ marginTop: -3 }}>
              Uncategorized
            </Typography>
          </TalentInformation>
        </Header>
        <LabelsContainer>
          <Typography fontSize={18}>Select Divisions:</Typography>
          <TextButton>Add New Division</TextButton>
        </LabelsContainer>
        <DivisionList>
          {divisionList.map((i) => (
            <DivisionItem>
              <Typography>{i}</Typography>
              <CheckboxContainer>
                <CustomCheckbox />
              </CheckboxContainer>
            </DivisionItem>
          ))}
        </DivisionList>
        <AddButton fullWidth variant="contained" color="primary">
          Add Talent to Division(s)
        </AddButton>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default AssignTalentToDivisionModal;
