import React, { useState } from 'react';
import {
  AddProfileIcon,
  CloseIcon,
  InputSearchTwoTone,
  PlusIconTwoTone,
  SelectArrowDownIcon,
  Typography,
} from '@room-match/shared-ui-components';
import {
  AddButton,
  CloseButton,
  CustomAutocomplete,
  CustomButton,
  CustomDashContainer,
  CustomDialog,
  CustomDialogContent,
  CustomPaper,
  CustomSelect,
  CustomTextField,
  CustomTextInput,
  Form,
  Header,
  IconButton,
  LastGridItem,
  ListImage,
  TalentImageContainer,
  TwoColumn,
  TwoColumnWithIconButton,
  useStyles,
} from './AddTalentModal.styles';
import { Grid, ListItem, TextField } from '@material-ui/core';

import gender from 'data/Gender.json';
import { ContactInput } from 'shared/elements';

type Props = {
  data: [];
  open: boolean;
  onClose: (instance: boolean) => void;
};

const AddTalentModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const [isAddNewTalent, setIsAddNewTalent] = useState<boolean>(false);

  const talents = [
    {
      name: 'James Bruno',
      height: `4'2"`,
      weight: '110lbs',
      company: 'SAG',
    },
    {
      name: 'James Mark',
      height: `4'2"`,
      weight: '110lbs',
      company: 'SAG',
    },
  ];

  const talentTypes = [
    { key: 'My Talent', value: 'My Talent' },
    { key: 'Represented', value: 'Represented' },
  ];

  const agencies = [
    {
      name: 'Agency Example Name',
      description: 'Short description should go here...',
    },
  ];

  // const handleSetTalentType = (e: any) => {
  //   const { value } = e.target;
  //   setTalentType(value);
  // };

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-dialog-title"
    >
      <CustomDialogContent>
        <CloseButton>
          <CloseIcon style={{ width: '14px' }} onClick={() => onClose(false)} />
        </CloseButton>
        <Header>
          <TalentImageContainer>
            <AddProfileIcon />
          </TalentImageContainer>
          <Typography fontSize={20}>Add or Create New Talent</Typography>
        </Header>
        <Grid item>
          <CustomAutocomplete
            options={talents}
            getOptionLabel={(option: any) => option.name}
            fullWidth
            noOptionsText={null}
            onChange={(e, value) => {}}
            renderOption={(option: any) => (
              <ListItem>
                <ListImage src="/sample_person2.jpg" />
                <div>
                  <Typography fontSize={14}>{option.name}</Typography>
                  <Typography
                    color="mainGrey"
                    fontSize={13}
                  >{`${option.name} / ${option.height} / ${option.weight} / ${option.company}`}</Typography>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <PlusIconTwoTone />
                </div>
              </ListItem>
            )}
            PaperComponent={({ children }) => <CustomPaper>{children}</CustomPaper>}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                name="search"
                placeholder="Search talent by name..."
                fullWidth
                margin={'normal'}
                InputProps={{
                  ...params.InputProps,
                  tabIndex: 1,
                  startAdornment: <InputSearchTwoTone style={{ fontSize: 24, marginTop: 5, marginLeft: 5 }} />,
                  endAdornment: '',
                }}
              />
            )}
          />
        </Grid>
        {isAddNewTalent ? (
          <Form>
            <Grid item>
              <CustomSelect
                variant="outlined"
                fullWidth
                data={[]}
                placeHolder="Select Role"
                IconComponent={SelectArrowDownIcon}
              />
            </Grid>
            <TwoColumn>
              <Grid item>
                <CustomTextInput placeholder="First Name" />
              </Grid>
              <Grid item>
                <CustomTextInput placeholder="Last Name" />
              </Grid>
            </TwoColumn>
            <TwoColumn>
              <Grid item>
                <CustomSelect
                  variant="outlined"
                  fullWidth
                  data={gender}
                  placeHolder="Gender"
                  IconComponent={SelectArrowDownIcon}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  variant="outlined"
                  fullWidth
                  data={[
                    { key: 'Adult', value: 'Adult' },
                    { key: 'Minor', value: 'Minor' },
                  ]}
                  placeHolder="Adult"
                  IconComponent={SelectArrowDownIcon}
                />
              </Grid>
            </TwoColumn>
            <TwoColumn>
              <Grid item>
                <CustomSelect
                  variant="outlined"
                  fullWidth
                  data={[]}
                  placeHolder="Age Range From"
                  IconComponent={SelectArrowDownIcon}
                />
              </Grid>
              <CustomDashContainer>-</CustomDashContainer>
              <Grid item>
                <CustomSelect
                  variant="outlined"
                  fullWidth
                  data={[]}
                  placeHolder="Age Range To"
                  IconComponent={SelectArrowDownIcon}
                />
              </Grid>
            </TwoColumn>
            <Grid item>
              <CustomTextInput placeholder="Website" />
            </Grid>
            <TwoColumn>
              <Grid item className={classes.customContactInput}>
                <ContactInput
                  country={'ua'}
                  name="contact_no"
                  variant="outlined"
                  placeholder="Type here..."
                  dropdownStyle={{ height: '50px' }}
                  showLabel={false}
                />
              </Grid>
              <Grid item>
                <CustomTextInput placeholder="Zip Code" />
              </Grid>
            </TwoColumn>
            <TwoColumnWithIconButton>
              <Grid item>
                <CustomAutocomplete
                  options={agencies}
                  getOptionLabel={(option: any) => option.name}
                  fullWidth
                  noOptionsText={null}
                  onChange={(e, value) => {}}
                  renderOption={(option: any) => (
                    <ListItem>
                      <ListImage src="/sample_person2.jpg" />
                      <div>
                        <Typography fontSize={14}>{option.name}</Typography>
                        <Typography color="mainGrey" fontSize={13}>
                          {option.description}
                        </Typography>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        <PlusIconTwoTone />
                      </div>
                    </ListItem>
                  )}
                  PaperComponent={({ children }) => <CustomPaper>{children}</CustomPaper>}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      variant="outlined"
                      name="search"
                      placeholder="Search agency..."
                      fullWidth
                      margin={'normal'}
                      InputProps={{
                        ...params.InputProps,
                        tabIndex: 1,
                        startAdornment: <InputSearchTwoTone style={{ fontSize: 24, marginTop: 5, marginLeft: 5 }} />,
                        endAdornment: '',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <IconButton fullWidth>
                  <PlusIconTwoTone />
                </IconButton>
              </Grid>
            </TwoColumnWithIconButton>
          </Form>
        ) : (
          <Grid item>
            <CustomButton variant="contained" color="primary" fullWidth onClick={(e) => setIsAddNewTalent(true)}>
              Not found? Add new talent
            </CustomButton>
          </Grid>
        )}

        <LastGridItem>
          <AddButton fullWidth variant="contained" color="primary" disabled={true}>
            Add Talent
          </AddButton>
        </LastGridItem>
      </CustomDialogContent>
    </CustomDialog>
  );
};

export default AddTalentModal;
