import { Box, Button, Grid } from '@material-ui/core';
import {
  CustomVideoPlayer,
  DeclineIconTwoTone,
  ImageCarousel,
  ITab,
  LikeIconTwoTone,
  MagnifyingGlassIconTwoTone,
  PlayIconTwoTone,
  ShadedStarIconTwoTone,
  ShadedStartBlueIconTwoTone,
  StickyNoteBlueIconTwoTone,
  StickyNoteIconTwoTone,
  StyledTab,
  StyledTabs,
  Typography,
  UploadIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';

import React, { useMemo, useState } from 'react';
import { CommentTab } from './CommentTab';
import { ImageSlider } from './ImageSlider';
import { useStyles } from './SelfTapesExpandedDiv.styles';

type Props = {
  isShowActionButtons?: boolean;
  isImageSlider?: boolean;
};
const SelfTapesExpandedDiv: React.FC<Props> = ({ isShowActionButtons = true, isImageSlider }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('resume');

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: 'resume',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
        component: <CommentTab />,
      },
      {
        name: 'audio',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
        component: <CommentTab />,
      },
    ];
  }, []);

  const getActiveTab = useMemo(() => {
    if (!tab) {
      return tabs[0].component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab, tabs]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.expandedDiv}>
      {/* Content */}
      <Grid container spacing={4}>
        {/* Left Player */}
        <Grid item lg={7}>
          <div className={classes.expandedDiv__leftContainer}>
            {isImageSlider ? (
              <ImageCarousel images={[]} />
            ) : (
              <div className={classes.expandedDiv__videoPlayerContainer}>
                <CustomVideoPlayer
                  url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  startTime={10}
                  endTime={20}
                  thumbnail="/sample_person2.jpg"
                  playIcon={
                    <PlayIconTwoTone style={{ fontSize: 48, position: 'absolute', right: '2%', bottom: '3%' }} />
                  }
                />
              </div>
            )}

            {/* Scene Action */}
            <div className={classes.expandedDiv__sceneActionContainer}>
              <Button
                variant={!isImageSlider ? 'outlined' : 'contained'}
                color="primary"
                style={{ color: '#fff' }}
                startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
              >
                Images
              </Button>
              <Button
                variant={isImageSlider ? 'outlined' : 'contained'}
                color="primary"
                startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
              >
                Scene 1
              </Button>

              <Button
                variant="outlined"
                color="primary"
                style={{ color: '#fff' }}
                startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
              >
                Scene 1 Take 200
              </Button>

              <Button
                variant="outlined"
                color="primary"
                style={{ color: '#fff' }}
                startIcon={<PlayIconTwoTone style={{ fontSize: 16 }} />}
              >
                Scene 2
              </Button>

              <Button
                variant="outlined"
                color="primary"
                style={{ color: '#fff' }}
                startIcon={<PlayIconTwoTone style={{ fontSize: 16 }} />}
              >
                Slate
              </Button>
            </div>
            {/* Actions */}
            {isShowActionButtons && (
              <div className={classes.expandedDiv__actionContainer}>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<MagnifyingGlassIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Mark for Edit
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<DeclineIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<LikeIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </Grid>
        {/* Comments Section */}
        <Grid item lg={5}>
          <div className={classes.rightContainer}>
            {/* Talent Profie */}
            <div className={classes.expandedDiv__talentContainer}>
              <div className={classes.expandedDiv__imageContainer}>
                <img src="/sample_person2.jpg" className={classes.expandedDiv__image} />
              </div>
              {/* Talent Profile Detail  */}
              <div className={classes.expandedDiv__detailContainer}>
                <Typography fontWeight={500}>Leonardo DiCaprio</Typography>
                {/* Talent Profile Icons */}
                <div className={classes.expandedDiv__iconsContainer}>
                  <div className={classes.expandedDiv__iconItem}>
                    <StickyNoteIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>4</Typography>
                  </div>
                  <div className={classes.expandedDiv__iconItem}>
                    <ShadedStarIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>4</Typography>
                  </div>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: true,
                    })}
                  >
                    <StickyNoteBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>4</Typography>
                  </div>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: true,
                    })}
                  >
                    <ShadedStartBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>4</Typography>
                  </div>
                </div>
              </div>
            </div>
            {/* Comment Section */}
            <div className={classes.expandedDiv__commentSectionContainer}>
              <StyledTabs
                value={!tab ? 'resume' : tab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                variant="fullWidth"
              >
                {tabs.map((tab, index) => (
                  <StyledTab
                    key={index}
                    label={tab.header}
                    value={tab.name}
                    disabled={tab.disabled}
                    {...a11yProps(index)}
                  />
                ))}
              </StyledTabs>
              <div className={classes.expandedDiv__commentSection__content}>{getActiveTab}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelfTapesExpandedDiv;
