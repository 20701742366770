import { createStyles, makeStyles } from '@material-ui/core';

export const useTextAreaStyle = makeStyles((theme) =>
  createStyles({
    textAreaCls: {
      background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
      border: theme.palette.type === 'dark' ? 'solid 1px #2d343e' : 'solid 1px #d5dbe3',
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'default',
      width: '100%',
      marginTop: '8px',
      padding: '8px',
      borderRadius: '4px',
      resize: 'none',
      outline: 'none',
      fontFamily: "'Roboto', sans-serif",
      '&:hover': {
        border: theme.palette.type === 'dark' ? 'solid 1px #FFFFFF' : 'solid 1px #0e1319',
      },
      '&:focus': {
        border: theme.palette.type === 'dark' ? 'solid 1px #3e8bff' : 'solid 1px #0e1319',
      },
    },
  }),
);
