import { styled, Button, DialogContent } from '@material-ui/core';
import { Checkbox, StyledDialog } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer',
  minWidth: 'unset',
  borderRadius: '100%',
  width: 30,
  height: 30,
}));

export const CustomDialog = styled(StyledDialog)(({ theme }) => ({
  '&.paper': {
    minWidth: '85%',
    minHeight: '90%',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '605px !important',
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 40px!important',
  background: '#0d1117',
}));

export const Header = styled('div')(({ theme }) => ({
  gap: 15,
  display: 'flex',
}));

export const TalentImage = styled('img')(({ theme }) => ({
  height: 100,
  width: 73,
  borderRadius: 5,
  objectFit: 'cover',
}));

export const TalentInformation = styled('div')(({ theme }) => ({}));

export const LabelsContainer = styled('div')(({ theme }) => ({
  marginTop: 10,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
}));

export const TextButton = styled(Button)(({ theme }) => ({
  background: 'none',
  fontWeight: 'normal',
  color: COLORS.PRIMARY,
  padding: 0,
}));

export const DivisionList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 15,
}));

export const DivisionItem = styled('div')(({ theme }) => ({
  display: 'flex',
  background: COLORS.DARK,
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  height: 49,
  padding: '0 15px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CheckboxContainer = styled('div')(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    margin: '0 -9px 0 0 !important',
  },
}));

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '& span': {
    height: 24,
    width: 24,
    '& > span': {
      background: COLORS.PRIMARY,
    },
    '& .MuiSvgIcon-root': {
      height: '16px !important',
      width: '16px !important',
      left: '13px !important',
      '& path': {
        fill: '#fff',
      },
    },
  },
}));

export const AddButton = styled(Button)(({ theme }) => ({
  height: 50,
  marginTop: 30,
  fontSize: 16,
  fontWeight: 'normal',
}));
