import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Statistics } from 'components/Statistics';
import { useStyles } from './Tabs.styles';
import { IProject, IProjectAttributes } from 'shared/interfaces/IProject';
import {
  CallBackIcon,
  CheckedCalendarIcon,
  CheckedCircleIcon,
  DocumentIcon,
  MagnifyIcon,
  NotificationAlertIcon,
  NotificationsAlertWhiteIcon,
  OnAvailIcon,
  PointerIcon,
  SelectedIcon,
  ThumbsUpIcon,
  TimesCircleIcon,
  UserBoxIcon,
  UserIcon,
  ViewedIcon,
} from '@room-match/shared-ui-components';

import { useParams } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { readableAggregates, sumArrayOfNumbers } from '@room-match/shared-utils';

type Props = {
  data: IProjectAttributes;
};

const Summary: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  // const { submissionsAuditions, selfTapes, decisions } = data;

  const countTaskAlerts =
    sumArrayOfNumbers(Object.values(data.aggregates.decisions)) +
    sumArrayOfNumbers(Object.values(data.aggregates.self_tapes)) +
    sumArrayOfNumbers(Object.values(data.aggregates.submissions));

  const submissionsAuditions = Object.entries(data.aggregates.submissions).reduce((acc: any, curr) => {
    const [name, count] = curr;
    const result = acc;
    let icon;
    if (name === 'new') {
      icon = <UserIcon />;
    } else if (name === 'viewed') {
      icon = <ViewedIcon />;
    } else if (name === 'selected') {
      icon = <PointerIcon />;
    } else {
      icon = <CheckedCircleIcon />;
    }
    const newObject: any = {
      count,
      label: readableAggregates(name),
      icon,
    };

    return [...result, newObject];
  }, []);

  const generateTo = (tab: string) => `${ROUTES.CASTING.PROJECT}/${data.id}/self_tapes`;

  const selfTapes = Object.entries(data.aggregates.self_tapes).reduce((acc: any, curr) => {
    const [name, count] = curr;
    const result = acc;

    let newObject: any | undefined;
    if (name === 'approved') {
      newObject = {
        count,
        label: readableAggregates(name),
        icon: <UserBoxIcon />,
        to: generateTo('approved'),
      };
    } else if (name === 'for_review') {
      newObject = {
        count,
        label: readableAggregates(name),
        icon: <MagnifyIcon />,
        to: generateTo('cd-approval'),
      };
    } else if (name === 'requested') {
      newObject = {
        count,
        label: readableAggregates(name),
        icon: <PointerIcon />,
        to: generateTo('requested'),
      };
    }

    return newObject ? [...result, newObject] : result;
  }, []);
  const decisions = Object.entries(data.aggregates.decisions).reduce((acc: any, curr) => {
    const [name, count] = curr;
    const result = acc;
    let icon;
    if (name === 'called_back') {
      icon = <CallBackIcon />;
    } else if (name === 'selects') {
      icon = <SelectedIcon />;
    } else if (name === 'on_avail') {
      icon = <OnAvailIcon />;
    } else if (name === 'released') {
      icon = <TimesCircleIcon />;
    } else if (name === 'booked') {
      icon = <CheckedCalendarIcon />;
    } else {
      icon = <DocumentIcon />;
    }
    const newObject = {
      count,
      label: readableAggregates(name),
      icon,
    };

    return [...result, newObject];
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={countTaskAlerts > 0 ? classes.taskAlertsColored : classes.taskAlerts}>
        {countTaskAlerts === 0 && <NotificationAlertIcon />}
        {countTaskAlerts > 0 && <NotificationsAlertWhiteIcon />}
        <Typography variant="body1" style={{ color: data.status === 'active' ? '#FFFFFF' : '#6e767f' }}>
          {countTaskAlerts}
        </Typography>
        {data.status === 'active' && <Typography variant="caption">Task Alerts</Typography>}
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box style={{ margin: '5px' }}>
            <Statistics title="Submissions / Auditions" data={submissionsAuditions} status={data.status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Box style={{ margin: '5px' }}>
            <Statistics title="Self-Tapes" data={selfTapes} status={data.status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Box style={{ margin: '5px' }}>
            <Statistics title="Decisions" data={decisions} status={data.status} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
