import { Box, Button, DialogContent, Grid, IconButton, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseIcon, CopyIconTwoTone, Typography } from '@room-match/shared-ui-components';
import clsx from 'clsx';

import React from 'react';

import useCopyClipboard from 'react-use-clipboard';

import { useStyles } from './Reason.styles';
import { useReportPreviewDialog } from 'shared/theme/styles/reportPreviewDialog';

type Props = {
  key?: string;
};
const Reason: React.FC<Props> = ({ key }) => {
  const classes = useStyles();
  const sectionClasses = useReportPreviewDialog();

  return (
    <Grid item>
      <Grid item className={sectionClasses.heading}>
        <Typography fontSize={25} color="primary">
          Reason
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={15}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales facilisis massa vitae porttitor.
          Curabitur consectetur leo eget eros molestie, sodales iaculis orci vestibulum. Donec vulputate, nibh et
          sodales ultricies, nisi lorem interdum enim, sed porttitor augue libero blandit nibh. Fusce consequat felis ut
          est ultrices convallis. Ut sit amet orci lacus. Praesent non consequat ligula. Fusce erat quam, sollicitudin
          eu tellus quis, ullamcorper euismod elit. Integer in metus non est elementum hendrerit ut quis urna.
        </Typography>
      </Grid>
      <Grid item className={sectionClasses.one__column}>
        <Grid item>
          <Typography fontSize={18} color="primary">
            Shooting / Recording Location
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={15}>Beverley Hills, California</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reason;
