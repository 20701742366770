import { Box } from '@material-ui/core';
import { getImageWidth } from '@room-match/shared-utils';
import React, { useState } from 'react';
import { useStyles } from './ImageGallery.styles';
const images = [
  {
    src: '/sample_person2.jpg',
    width: 1920,
    height: 2401,
  },
  {
    src: '/landscape.jpg',
    width: 1980,
    height: 1280,
  },
];
const items = Array.from({ length: 20 }).map((_, i) => {
  return images[i % 2 === 0 ? 0 : 1];
});

const BOX_HEIGHT = 100;
const ImageGallery = () => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState<{
    src: string;
    width: number;
    height: number;
  }>(items[0]);
  return (
    <Box className={classes.imageGalleryContainer}>
      {/* Large Image */}
      <Box className={classes.largeImageContainer}>
        <img src={selectedImage.src} className={classes.largeImage} />
      </Box>
      {/* Image List */}
      <Box className={classes.imageListContainer}>
        {items.map((item, i) => (
          <Box
            onClick={() => setSelectedImage(item)}
            className={classes.imageItemContainer}
            style={{
              maxWidth: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT),
              height: BOX_HEIGHT,
            }}
            key={i}
          >
            <Box style={{ width: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT) / 2 }}>
              <img src={item.src} className={classes.imageItem} style={{ height: BOX_HEIGHT, width: '100%' }} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageGallery;
