import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    darkText: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      color: '#6e767f',
    },
  }),
);

export const Container = styled('div')(({ theme }) => ({
  margin: '20px 0',
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  padding: '20px 10px',
  borderBottom: 'solid 1px #2d343e',
  marginLeft: '-10px',
  marginRight: '-10px',
}));

export const ContactFlex = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  padding: '10px 20px',
  background: '#151b24',
  borderRadius: '8px',
  overflowWrap: 'anywhere',
  '& > *:first-child': {
    marginRight: '20px',
  },
  '& > svg:first-child': {
    width: '25px',
    height: '25px',
  },
  '& > svg:last-child': {
    width: '20px',
    height: '20px',
  },
  '& > *:last-child': {
    marginLeft: 'auto',
  },
}));
