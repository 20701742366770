import { Button, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { Datepicker as MobiScrollDatePicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useStyles } from './DatePickerComp.styles';
import { StyledDialog, Typography } from '@room-match/shared-ui-components';

type Props = {
  open: boolean;
  onClose: () => void;
  onChooseDate?: (value: Date[] | Date, valueText?: string) => void;
  type?: 'range' | 'date' | 'preset-range';
  selectMultiple?: boolean;
};
const DatePickerComp: React.FC<Props> = ({ open, onClose, onChooseDate, type, selectMultiple }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [valueDate, setValueDate] = useState<Date[] | Date | undefined>(undefined);
  const [valueText, setValueText] = useState<string>('');

  const handleChooseDate = () => {
    if (valueDate) {
      if (onChooseDate) {
        if (type === 'range') {
          if (Array.isArray(valueDate)) {
            if (valueDate[1]) {
              onChooseDate(valueDate as Date[], valueText);
              onClose();
            }
          }
        } else {
          onChooseDate(valueDate as Date, valueText);
          onClose();
        }
      }
    }
  };

  return (
    <StyledDialog classes={{ paper: classes.paper }} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={classes.dialogContent}>
        {isLoaded && (
          <div className={classes.titleContainer}>
            <Typography fontSize={20}>Select Date Range</Typography>
          </div>
        )}

        <MobiScrollDatePicker
          onPageLoaded={() => setIsLoaded(true)}
          theme="material"
          display="inline"
          controls={['calendar']}
          select={type}
          selectMultiple={selectMultiple}
          showRangeLabels={false}
          onChange={(e) => {
            const { value, valueText } = e as { value: Date[] | Date; valueText: string };

            setValueDate(value);
            setValueText(valueText);
          }}
          inputProps={{
            placeholder: 'Please Select...',
          }}
          themeVariant="dark"
          className={classes.datePicker}
        />
        {isLoaded && (
          <div className={classes.buttonContainer}>
            <Button color="primary" variant="contained" onClick={() => handleChooseDate()}>
              Choose Date Range
            </Button>
          </div>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default React.memo(DatePickerComp, (prevProps, nextProps) => prevProps.open === nextProps.open);
