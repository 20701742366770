import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 970,
      width: '100%',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    header: {
      marginBottom: 80,
      textAlign: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },

    oneRow: {
      display: 'flex',
      width: '100%',
      gap: '1em',
    },

    oneRowField: {
      width: '100%',
    },

    oneRowSmallField: {
      maxWidth: 120,
      width: '100%',
    },

    trashIcon: {
      fontSize: 20,
    },

    iconButton: {
      height: 50,
      width: 50,
      borderRadius: 5,
      background: '#0e1319',
      border: '1px solid #2d343e',
    },

    buttonCls: {
      border: 'solid 1px #3e8bff',
      textTransform: 'capitalize',
      height: '40px',
      fontSize: '14px',
    },

    switchCls: {
      marginTop: 30,
    },
  }),
);
