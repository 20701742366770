import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Link } from 'shared/elements';
import React from 'react';
import { TextContainer, useStyles } from './ProjectDetailsConflictsAndUsage.styles';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { TitleContainer } from '../ProjectDetailsAdditional/ProjectDetailsAdditional.styles';

type Props = {
  project: IProjectAttributes | undefined;
};
const ProjectDetailsConflictsAndUsage: React.FC<Props> = ({ project }) => {
  const classes = useStyles();
  return (
    <div>
      <TitleContainer>
        <Typography variant="h5">Conflicts and Usage</Typography>
      </TitleContainer>
      <TextContainer>
        <Typography variant="body1" className={classes.textTitle}>
          Conflict One
        </Typography>
        <Typography variant="body2" style={{ paddingRight: '30px' }}>
          {project?.conflicts ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales facilisis massa vitae porttitor. Curabitur consectetur leo eget eros molestie, sodales iaculis orci vestibulum. Donec vulputate, nibh et sodales ultricies, nisi lorem interdum enim, sed porttitor augue libero blandit nibh. Fusce consequat felis ut est ultrices convallis. Ut sit amet orci lacus. Praesent non consequat ligula. Fusce erat quam, sollicitudin eu tellus quis, ullamcorper euismod elit. Integer in metus non est elementum hendrerit ut quis urna.'}
        </Typography>
      </TextContainer>
      <TextContainer>
        <Typography variant="body1" className={classes.textTitle}>
          Usage / Run
        </Typography>
        <Typography variant="body2" style={{ paddingRight: '30px' }}>
          {project?.usage ||
            'National Network, Regional, Cable, Spot, Test Market, Dealer, Internet, New Media, Theatrical/Industrial, Simulcast, Behind the Scenes.'}
        </Typography>
      </TextContainer>
      <TextContainer>
        <Typography variant="body1" className={classes.textTitle}>
          Usage / Additional Provisions
        </Typography>
        <Typography variant="body2" style={{ paddingRight: '30px' }}>
          {project?.additional_provisions ||
            'Additional Editing Rights: One session fee to be paid at time of exercise, covering the right to an unlimited amount of additional edits for both the Internet and New Media.'}
        </Typography>
      </TextContainer>
    </div>
  );
};

export default ProjectDetailsConflictsAndUsage;
