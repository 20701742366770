import { ShadedStarIconTwoTone, Typography, UnshadedStarIconTwoTone } from '@room-match/shared-ui-components';
import React from 'react';
import { useStyles } from './CommentTab.styles';

const CommentTab = () => {
  const classes = useStyles();
  return (
    <div className={classes.commentTab}>
      {Array.from({ length: 10 }).map((_, i) => (
        <div className={classes.commentTab__item} key={i}>
          <div className={classes.commentTab__personContainer}>
            <div className={classes.commentTab__imageContainer}>
              <img src="/sample_person2.jpg" className={classes.commentTab__image} />
            </div>
            <div className={classes.commentTab__detailContainer}>
              <div className={classes.commentTab__personDetail}>
                <Typography fontSize={14} fontWeight={500}>
                  Jimmy Hart
                </Typography>
                <Typography fontSize={14} fontWeight={500} color="mainGrey">
                  (10 Mins Ago)
                </Typography>
              </div>
              <div className={classes.commentTab__startIconContainer}>
                <ShadedStarIconTwoTone classes={{ root: classes.commentTab__startIcon }} />
                <ShadedStarIconTwoTone classes={{ root: classes.commentTab__startIcon }} />
                <ShadedStarIconTwoTone classes={{ root: classes.commentTab__startIcon }} />
                <UnshadedStarIconTwoTone classes={{ root: classes.commentTab__startIcon }} />
                <UnshadedStarIconTwoTone classes={{ root: classes.commentTab__startIcon }} />
              </div>
            </div>
          </div>

          <div className={classes.commentTab__commentContainer}>
            <Typography fontSize={14} fontWeight={500} style={{ lineHeight: 1.2 }}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis ipsam eius tenetur eveniet iusto,
              dolores similique ratione ut temporibus perspiciatis.
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentTab;
