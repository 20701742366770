import { CheckInIcon } from '@room-match/shared-ui-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { useSetHeaderTitle } from 'shared/hooks/useSetHeader';
import Item from './Item/Item';
import { Body, Header, ListContainer } from './EventsList.styles';

type Props = {
  data: any;
  tab: string;
};

const EventsList: React.FC<Props> = ({ data, tab }) => {
  return (
    <Body>
      {tab === 'action_items' && <Header></Header>}
      <ListContainer>
        {data.map((i: any) => (
          <Item data={i} />
        ))}
      </ListContainer>
    </Body>
  );
};

export default EventsList;
