import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {},
    headerWrapper: {
      display: 'flex',
      gap: '1rem',
    },
    header__middle: {
      backgroundColor: theme.palette.customBlack.dark,
      padding: theme.spacing(0.25, 0.75),
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header__center: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
    cb: {
      backgroundColor: theme.palette.customBlack.main,
      padding: 2,
      borderRadius: 6,
      cursor: 'pointer',
    },
    paginationRoot: {
      gap: '20px !important',
    },
    shareProfileContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    rightIconsContainer: {},
  }),
);
