import { Box, Button, Grid, InputAdornment, InputLabel, TextField } from '@material-ui/core';

import {
  Input,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  SocialMediaSelect,
  TextInput,
  Typography,
  PlusIconTwoTone,
  TrashErrorIconTwoTone,
} from '@room-match/shared-ui-components';
import { Fragment, useEffect, useState } from 'react';

import { useStyles } from './CompanyInformation.styles';
import { useDebounce } from 'use-debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage } from '@room-match/shared-utils';
import { ContactInput } from 'shared/elements';
import FileUpload from 'components/FileUpload/FileUpload';
import { ICompanySocialMedia } from 'shared/interfaces/ICompany';

type Props = {
  form?: any;
};

const CompanyInformation: React.FC<Props> = ({ form }) => {
  const [query, setQuery] = useState<string>('');

  const socialMedias = [
    { key: 'Facebook', value: 'Facebook' },
    { key: 'Instagram', value: 'Instagram' },
    { key: 'SoundCloud', value: 'SoundCloud' },
    { key: 'Tiktok', value: 'Tiktok' },
    { key: 'Twitter', value: 'Twitter' },
    { key: 'Youtube', value: 'Youtube' },
  ];

  const handleUploadLogo = async (
    name: string,
    type: string,
    url: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => {};

  const [queryValue] = useDebounce(query, 100);
  const [representativesLabel, setRepresentativesLabel] = useState<string[]>([]);

  const [socialMediaToAdd, setSocialMediaToAdd] = useState<ICompanySocialMedia>({
    platform: '',
    url: '',
  });

  const addSocialMedia = () => {
    setSocialMediaToAdd({ platform: '', url: '' });
  };

  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Company Information</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item>
          <InputLabel id="representationStatus" style={{ marginBottom: 10 }}>
            Company Logo
          </InputLabel>
          <FileUpload
            onFileSelected={handleUploadLogo}
            variant={'uploader'}
            defaultText="Add Logo"
            // img={data?.data.attributes.logo ? data?.data.attributes.logo : ''}
          />
        </Grid>
        <Grid item>
          <TextInput label={'Company Name'} placeholder="Enter Company Name..." />
        </Grid>
        <Grid item style={{ marginBottom: 5 }}>
          <InputLabel id="representationStatus" style={{ marginBottom: '-15px' }}>
            Cell Number
          </InputLabel>
          <ContactInput
            country={'us'}
            name="contact_no"
            variant="outlined"
            placeholder="Type here..."
            dropdownStyle={{ height: '50px' }}
            showLabel={false}
          />
        </Grid>
        <Grid item>
          <TextInput label={'General Company Email'} placeholder="Enter General Company Email..." />
        </Grid>
        <Grid item>
          <TextInput label={'Website'} placeholder="Enter Website..." />
        </Grid>
        <Grid item>
          <InputLabel id="socialMedia">Social Media</InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={4} lg={4}>
              <SocialMediaSelect
                className={classes.socialMediaSelect}
                variant="outlined"
                fullWidth
                data={socialMedias}
                onChange={(e) => {
                  const { value } = e.target as { value: string };
                  setSocialMediaToAdd({
                    ...socialMediaToAdd,
                    platform: value,
                  });
                }}
                value={socialMediaToAdd.platform}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={8}>
              <Input
                variant="outlined"
                type="text"
                margin={'normal'}
                fullWidth
                value={socialMediaToAdd.url}
                InputLabelProps={{ shrink: true }}
                inputProps={{ tabIndex: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                      <Typography fontSize={14} color="mainGrey">{`${
                        socialMediaToAdd.platform ? socialMediaToAdd.platform.toLowerCase() + '.com/' : ''
                      }`}</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const { value } = e.target as { value: string };
                  setSocialMediaToAdd({
                    ...socialMediaToAdd,
                    url: value,
                  });
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && socialMediaToAdd.platform && socialMediaToAdd.url) {
                    addSocialMedia();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextInput label={'Location Name'} placeholder="Enter Location Name..." />
        </Grid>
        <Grid item>
          <TextInput label={'Address 1'} placeholder="Enter Address..." />
        </Grid>
        <Grid item>
          <TextInput label={'Address 2'} placeholder="Enter Address..." />
        </Grid>
        <Grid item className={classes.twoColumn}>
          <Grid item>
            <InputLabel id="representationStatus">Country</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[
                { key: 'Agency Representation', value: 'agency_representation' },
                { key: 'Non-Represented', value: 'Non-Represented' },
              ]}
              IconComponent={SelectArrowDownIcon}
              name="representation"
            />
          </Grid>
          <Grid item>
            <InputLabel id="representationStatus">State</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[
                { key: 'Agency Representation', value: 'agency_representation' },
                { key: 'Non-Represented', value: 'Non-Represented' },
              ]}
              IconComponent={SelectArrowDownIcon}
              name="representation"
            />
          </Grid>
        </Grid>
        <Grid item className={classes.twoColumn}>
          <Grid item>
            <TextInput label={'City'} placeholder="Enter City..." />
          </Grid>
          <Grid item>
            <TextInput label={'Zip/Post'} placeholder="Enter Zip/Post..." />
          </Grid>
        </Grid>
        <Grid item className={classes.twoColumn}>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              className={classes.addLocationButton}
              // onClick={addLocation}
            >
              <PlusIconTwoTone style={{ width: '16px' }} /> &nbsp;Add a New Location
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              className={`${classes.addLocationButton} remove`}
              // onClick={addLocation}
            >
              <TrashErrorIconTwoTone style={{ width: '16px' }} /> &nbsp;Remove Location
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 15 }}>
          <TextInput
            label={'Company Description'}
            placeholder="Enter Company Description..."
            textArea={true}
            textAreaMinRows={8}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyInformation;
