import { Box, Grid } from '@material-ui/core';

import React from 'react';

import useCopyClipboard from 'react-use-clipboard';

import { useStyles } from './UnionSecurityInformation.styles';
import { useReportPreviewDialog } from 'shared/theme/styles/reportPreviewDialog';
import { Typography } from '@room-match/shared-ui-components';

type Props = {
  key?: string;
};
const UnionSecurityInformation: React.FC<Props> = ({ key }) => {
  const classes = useStyles();
  const sectionClasses = useReportPreviewDialog();

  return (
    <Grid item>
      <Grid item className={sectionClasses.heading}>
        <Typography fontSize={25}>UNION SECURITY INFORMATION</Typography>
      </Grid>
      <Grid item>
        <Grid item className={`${sectionClasses.two__column} ${classes.two__column__fix}`}>
          <Grid item>
            <Grid item>
              <Typography fontSize={18} color="primary">
                EMPLOYEE INFORMATION
              </Typography>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Full Name
                </Typography>
                <Typography fontSize={15}>Helen Stevenson</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  SS#
                </Typography>
                <Typography fontSize={15}>-</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  City
                </Typography>
                <Typography fontSize={15}>Beverly Hills</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  State
                </Typography>
                <Typography fontSize={15}>California</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Zip Code
                </Typography>
                <Typography fontSize={15}>123456</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Address
                </Typography>
                <Typography fontSize={15}>123 Street Name Example</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Date of Birth
                </Typography>
                <Typography fontSize={15}>-</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Phone Number
                </Typography>
                <Typography fontSize={15}>+1 310-234-5678</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Email Address
                </Typography>
                <Typography fontSize={15}>contact@example.com</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Work Date
                </Typography>
                <Typography fontSize={15}>-</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography fontSize={18} color="primary">
                EMPLOYER INFORMATION
              </Typography>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Signatory Company
                </Typography>
                <Typography fontSize={15}>Company Name Example</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Phone Number
                </Typography>
                <Typography fontSize={15}>+1 310-234-5678</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Address
                </Typography>
                <Typography fontSize={15}>123 Street Name Demo</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Zip Code
                </Typography>
                <Typography fontSize={15}>123456</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  City
                </Typography>
                <Typography fontSize={15}>Beverly Hills</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  State
                </Typography>
                <Typography fontSize={15}>California </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Email Address
                </Typography>
                <Typography fontSize={15}>contact@employer.com</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Contact Person
                </Typography>
                <Typography fontSize={15}>contact@example.com</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Advertiser
                </Typography>
                <Typography fontSize={15}>—</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={sectionClasses.two__column}>
                <Typography fontSize={15} color="mainGrey">
                  Product
                </Typography>
                <Typography fontSize={15}>—</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={sectionClasses.one__column}>
        <Grid item>
          <Typography fontSize={18} color="primary">
            Category
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={15}>Voice Over, Stunt Performer</Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={14}>
            <Box component="span" className={sectionClasses.span}>
              PREFERENCE OF EMPLOYMENT INFORMATION
            </Box>{' '}
            (Waivers for a non-member hired for his/her special skill/unusual appearance or for giving a testimonial or
            endorsement are conditioned upon screening of the final edit of the commercial.)
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnionSecurityInformation;
