import { Typography } from '@room-match/shared-ui-components';
import React from 'react';
import { Body, FlexRow, MiniRow, QuickSubmitButton, DismissedButton } from './Summary.styles';

type Props = {
  data: any;
};

const Summary: React.FC<Props> = ({ data }) => {
  return (
    <Body>
      <FlexRow style={{ width: 500 }}>
        <Typography fontSize={15}>Sypnosis:</Typography>
        <Typography color="mainGrey" fontSize={14} style={{ lineHeight: 1.2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel ultrices nunc. Vestibulum vel odio
          faucibus, volutpat diam vel, hendrerit nisi. Susdisse facilisis, purus quis condime hendrerit, magna letus
          eleifend erat, et rutru Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </FlexRow>
      <FlexRow style={{ width: 200 }}>
        <MiniRow>
          <Typography fontSize={15}>Submissions Due Date:</Typography>
          <Typography color="primary" fontSize={14}>
            24 June 2022
          </Typography>
        </MiniRow>
        <MiniRow>
          <Typography fontSize={15}>Audition Date:</Typography>
          <Typography color="primary" fontSize={14}>
            24 June 2022
          </Typography>
        </MiniRow>
      </FlexRow>
      <FlexRow style={{ width: 400 }}>
        <Typography fontSize={15} color="primary">
          Usage / Run
        </Typography>
        <Typography fontSize={14}>
          National Network, Regional, Cable, Spot, Test Market, Dealer, Internet, New Media, Theatrical/Industrial,
          Simulcast, Behind the Scenes.
        </Typography>
      </FlexRow>
      <FlexRow style={{ width: 231 }}>
        <Typography fontSize={15} color="primary">
          Rate:
        </Typography>
        <Typography fontSize={14}>Overscale</Typography>
      </FlexRow>
      <FlexRow style={{ justifyContent: 'center' }}>
        <DismissedButton>Dismissed</DismissedButton>
      </FlexRow>
    </Body>
  );
};

export default Summary;
