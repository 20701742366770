import { styled, Button } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const Body = styled('div')(({ theme }) => ({}));

export const Header = styled('div')(({ theme }) => ({
  height: 39,
  background: '#0f151c',
  border: '1px solid',
  borderColor: COLORS.DARK_BORDER,
  borderRadius: 5,
  marginBottom: 20,
}));

export const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));
