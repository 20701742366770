import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './Statistics.styles';
import { useHistory } from 'react-router-dom';

type Props = {
  title?: string;
  data?: any;
  disabled?: boolean;
  status?: 'active' | 'pending' | 'archived';
  to?: string;
};

const Statistics: React.FC<Props> = ({ title, data = [], status, disabled }) => {
  const classes = useStyles();
  const history = useHistory();
  const total = data.reduce((a: any, b: any) => {
    return a + b.count;
  }, 0);

  const handleRedirect = (to: string) => {
    history.push(to);
  };

  return (
    <Box className={classes.submissionsAuditions}>
      <Box
        className={`${classes.subAudHeader} ${
          disabled ? classes.empty : data.length && total > 0 ? '' : total === 0 ? classes.empty : classes.empty
        }`}
      >
        <Typography variant="caption">{title}</Typography>
      </Box>
      <Box className={classes.subAudBody}>
        {status === 'pending' && <div className={classes.overlay} />}
        {data.map((item: any, index: any) => (
          <Box key={index} className={classes.statistics} onClick={() => item.to && handleRedirect(item.to)}>
            {item.icon}
            <Typography variant="body1" style={{ color: status === 'active' ? '#FFFFFF' : '#6e767f' }}>
              {item.count || 0}
            </Typography>
            {status === 'active' && <Typography variant="caption">{item.label || 'No Label'}</Typography>}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Statistics;
