import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    resumeDescription: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tabContainer: {
      marginTop: theme.spacing(3),
    },
    activeTabContainer: {
      minHeight: 500,
      padding: theme.spacing(2, 0),
    },
  }),
);
