import { Box, Grid, Card, CardContent, IconButton } from '@material-ui/core';

import clsx from 'clsx';
import {
  ArrowDownIcon,
  FacebookOutlinedIcon,
  InstagramOutlinedIcon,
  TiktokOutlinedIcon,
  YoutubeOutlinedIcon,
  Typography,
  PlayIconTwoTone,
} from '@room-match/shared-ui-components';
import React, { useState } from 'react';
import { useStyles } from './TalentProfileCard.styles';

const TalentProfileCard = () => {
  const classes = useStyles();
  const [isSocialFullHeight, setIsSocialFullHeight] = useState<boolean>(false);
  const [isAgencyFullHeight, setIsAgencyFullHeight] = useState<boolean>(false);
  return (
    <Box className={classes.cardsContainer}>
      <Grid container spacing={2} alignContent="stretch">
        {/* Social */}
        <Grid item lg={6} md={6}>
          <Card
            className={clsx(classes.card, {
              [classes.cardFullHeight]: isSocialFullHeight,
            })}
          >
            <CardContent>
              <Box className={classes.flexBetween}>
                <Typography variant="subHeading">
                  Social Reach : <span className={classes.textPrimary}>32k</span>
                </Typography>
                <Box className={classes.socialIconContainer}>
                  <IconButton size="small">
                    <InstagramOutlinedIcon className={classes.socialIcon} />
                  </IconButton>
                  <IconButton size="small">
                    <TiktokOutlinedIcon className={classes.socialIcon} />
                  </IconButton>
                  <IconButton size="small">
                    <YoutubeOutlinedIcon className={classes.socialIcon} />
                  </IconButton>
                  <IconButton size="small">
                    <FacebookOutlinedIcon className={classes.socialIcon} />
                  </IconButton>
                  <IconButton size="small" onClick={() => setIsSocialFullHeight((curr) => !curr)}>
                    <ArrowDownIcon className={classes.socialIcon} />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Agency */}
        <Grid item lg={6} md={6}>
          <Card
            className={clsx(classes.card, {
              [classes.cardFullHeight]: isAgencyFullHeight,
            })}
          >
            <CardContent>
              <Box className={classes.flexBetween}>
                <Typography variant="subHeading">Smith Agency LA</Typography>
                <Box className={classes.flexBetween} gridGap={16}>
                  <Typography variant="subHeading">(310) 123-4567</Typography>
                  <IconButton size="small" onClick={() => setIsAgencyFullHeight((curr) => !curr)}>
                    <ArrowDownIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Resume Highlights */}
        <Grid item lg={6} md={6}>
          <Card className={classes.card} style={{ height: '100%' }}>
            <CardContent>
              <Typography color="primary" fontWeight={500}>
                Resume Highlights
              </Typography>
              <Box className={classes.resumeContainer}>
                <Box className={classes.resumeItemContainer}>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                  <Box>
                    <Typography fontSize={12} color="primary">
                      2010 - The Wannabes
                    </Typography>
                    <Typography fontSize={12} style={{ lineHeight: 1 }}>
                      Andrew, Ian Brown
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.resumeItemContainer}>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                  <Box>
                    <Typography fontSize={12} color="primary">
                      2010 - The Wannabes
                    </Typography>
                    <Typography fontSize={12} style={{ lineHeight: 1 }}>
                      Andrew, Ian Brown
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.resumeItemContainer}>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                  <Box>
                    <Typography fontSize={12} color="primary">
                      2010 - The Wannabes
                    </Typography>
                    <Typography fontSize={12} style={{ lineHeight: 1 }}>
                      Andrew, Ian Brown
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Skills Highlights */}
        <Grid item lg={6} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="primary" fontWeight={500}>
                Skills Highlights
              </Typography>
              <Box className={classes.skillsContainer}>
                <Box className={classes.skillsItemContainer}>
                  <Box>
                    <Typography fontSize={12}>Ballroom</Typography>
                    <Typography fontSize={12} color="mainGrey">
                      Dance
                    </Typography>
                  </Box>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                </Box>
                <Box className={classes.skillsItemContainer}>
                  <Box>
                    <Typography fontSize={12}>Contemporary</Typography>
                    <Typography fontSize={12} color="mainGrey">
                      Dance
                    </Typography>
                  </Box>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                </Box>
                <Box className={classes.skillsItemContainer}>
                  <Box>
                    <Typography fontSize={12}>Contemporary</Typography>
                    <Typography fontSize={12} color="mainGrey">
                      Dance
                    </Typography>
                  </Box>
                  <IconButton size="small">
                    <PlayIconTwoTone style={{ fontSize: 16 }} />
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalentProfileCard;
