import { Box, Button, DialogContent, Grid, IconButton, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  CloseIcon,
  CopyIconTwoTone,
  FormTwoToneIcon,
  StyledDialog,
  Typography,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';

import React from 'react';

import useCopyClipboard from 'react-use-clipboard';

import { useStyles } from './ReportPreviewDialog.styles';
import { useReportPreviewDialog } from 'shared/theme/styles/reportPreviewDialog';

import { UnionSecurityInformation } from './UnionSecurityInformation';
import { Reason } from './Reason';
import { ContractualReason } from './ContractualReason';
import { TrainingExperience } from './TrainingExperience';
import { Signature } from './Signature';
import { Actions } from './Actions';

type Props = {
  open: boolean;
  onClose: () => void;
};
const ReportPreviewDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const sectionClasses = useReportPreviewDialog();

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth className={classes.dialog__container}>
      <DialogContent>
        <Grid container spacing={0} className={classes.header}>
          <Grid item>
            <Grid item>
              <FormTwoToneIcon style={{ fontSize: 60 }} />
            </Grid>
            <Grid item>
              <Typography fontSize={25}>TAFT-HARLEY-REPORT</Typography>
              <Typography color="mainGrey">
                <Box component="span" className={sectionClasses.span}>
                  Helen Stevenson
                </Box>{' '}
                | Type:{' '}
                <Box component="span" className={sectionClasses.span}>
                  Commercial
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <CloseIcon style={{ width: '14px' }} onClick={onClose} />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.content}>
          <UnionSecurityInformation />
          <Reason />
          <ContractualReason />
          <TrainingExperience />
          <Signature />
          <Actions onClose={onClose} />
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default ReportPreviewDialog;
