import { IconButton, styled, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#080c12',
      boxShadow: 'none',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 100,
        width: 'calc(100% - 100px)',
      },
    },
    appBarShift: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 100,
        width: 'calc(100% - 100px)',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    search: {
      position: 'relative',
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  height: '60px',
  minWidth: '60px',
  borderLeft: 'solid 1px #1b2027',
  borderRadius: 0,
  '& svg': {
    width: '21px',
  },
}));

export const UserContainerButton = styled(IconButton)(({ theme }) => ({
  padding: '0 15px',
  height: '60px',
  minWidth: '60px !important',
  borderRadius: 0,
  borderLeft: 'solid 1px #1b2027',
  '& > span:first-child': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#EEEEEE',
    '& > div': {
      marginLeft: '10px',
      height: '34px',
      width: '34px',
      overflow: 'hidden',
      borderRadius: '5px',
      background: '#808080',
    },
  },
}));
