import { Grid } from '@material-ui/core';
import { SelectArrowDownIcon, ViewedIcon } from '@room-match/shared-ui-components';
import MessageTalentModal from 'components/MessageTalentModal/MessageTalentModal';
import { TalentProfile } from 'components/TalentProfile';
import React, { useEffect, useState } from 'react';
import { TalentCard } from './TalentCard';
import {
  Body,
  CustomInputAdornment,
  CustomSelect,
  MarkPageAsViewed,
  NewHeader,
  useStyles,
} from './TalentLibrary.styles';

const talentData = {
  talent: {
    first_name: 'Natalia',
    last_name: 'Ashley M.',
    statistics: {},
  },
  primary_medium_attachment: {
    modified_attachment: '',
  },
};

type Props = {
  tabName: string;
  division: boolean;
  isBeingAssignedToDivision: boolean;
  setBeingAssignedToDivision: (isBeingAssignedToDivision: boolean) => void;
};

const TalentLibrary: React.FC<Props> = ({
  tabName,
  division,
  isBeingAssignedToDivision,
  setBeingAssignedToDivision,
}) => {
  const classes = useStyles();
  const [messageTalentModalOpen, setMessageTalentModalOpen] = useState<boolean>(false);
  const [addTalentModalOpen, setAddTalentModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (tabName) {
      if (tabName === 'new' || tabName === 'uncategorized') setBeingAssignedToDivision(true);
    }
  }, [tabName, setBeingAssignedToDivision]);

  return (
    <Body container>
      {tabName === 'new' && (
        <NewHeader container>
          <Grid item>
            <MarkPageAsViewed fullWidth>
              <ViewedIcon /> Mark Page As Viewed
            </MarkPageAsViewed>
          </Grid>
          <Grid item className={classes.bypassSelectCls}>
            <CustomSelect
              variant="outlined"
              fullWidth
              data={[
                {
                  key: '100',
                  value: '100',
                },
              ]}
              value={'100'}
              IconComponent={SelectArrowDownIcon}
              name="representation"
              startAdornment={<CustomInputAdornment position="start">Talent Per Page: </CustomInputAdornment>}
            />
          </Grid>
        </NewHeader>
      )}
      {Array.from({ length: 12 }, () => (
        <TalentCard isBeingAssignedToDivision={isBeingAssignedToDivision} />
      ))}

      <MessageTalentModal open={messageTalentModalOpen} onClose={() => setMessageTalentModalOpen(false)} />
      <TalentProfile open={addTalentModalOpen} data={talentData} onClose={() => setAddTalentModalOpen(false)} />
    </Body>
  );
};

export default TalentLibrary;
