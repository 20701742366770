import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 573,
      width: '100%',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    header: {
      marginBottom: 80,
      textAlign: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
    autocompleteFix: {
      '& .MuiInputBase-input': {
        paddingTop: '8px!important',
      },
    },

    socialMediaSelect: {
      maxWidth: 178,
      width: '100%',

      '& .MuiSelect-iconOutlined': {
        right: '10px',
        top: 'unset',
      },

      '& .MuiSelect-iconOpen': {
        top: 'unset',
        right: '10px',
      },
    },

    socialMediaSelectContainer: {
      display: 'flex',
      gap: '1em',
    },

    socialMediaPrefix: {
      color: COLORS.GRAY,
    },

    twoColumn: {
      display: 'flex',
      gap: '1em',
      '& > div': {
        width: '100%',
      },
    },
    addLocationButton: {
      border: 'solid 1px #3e8bff',
      textTransform: 'capitalize',
      height: '40px',
      fontSize: '14px',
      '&.remove': {
        borderColor: COLORS.DANGER,
      },
    },
  }),
);
