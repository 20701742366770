import { styled, Grid, Button, createStyles, makeStyles } from '@material-ui/core';
import { Checkbox } from '@room-match/shared-ui-components';
import { COLORS } from 'shared/constants/COLORS';

export const useStyles = makeStyles(() =>
  createStyles({
    white: {
      '& path': {
        fill: '#fff',
      },
    },
    expandIcon: {
      position: 'absolute',
      bottom: 7,
    },
    isExpandedIcon: {
      transform: 'rotate(180deg)',
    },
    moreIcon: {
      position: 'absolute',
      right: 5,
      top: 15,
    },
    expandedHeight: {
      height: '383px!important',
    },
    expandedTalentImageContainer: {
      width: '48.5%!important',
      borderBottomLeftRadius: 5,
    },
    expandedTalentImage: {
      borderBottomLeftRadius: 5,
    },
    expandedSidebar: {
      borderBottomRightRadius: 5,
      width: '6%!important',
    },
    expandedCard: {
      maxWidth: '558px!important',
    },
  }),
);

export const Body = styled(Grid)(({ theme }) => ({
  gap: 15,
  justifyContent: 'space-around',
}));

export const CardContainer = styled(Grid)(({ theme }) => ({
  maxWidth: 270,
  width: '100%',
  height: 383,
  background: COLORS.CARD_DARK_GRAY,
  borderRadius: 5,
}));

export const CardContent = styled(Grid)(({ theme }) => ({}));

export const TalentImageContainer = styled(Grid)(({ theme }) => ({
  width: '89%',
  height: 330,
  position: 'relative',
}));

export const TalentImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 330,
  objectFit: 'cover',
  borderTopLeftRadius: 5,
}));

export const DragButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: 7,
  top: 7,
  minWidth: 'unset',
  padding: 7,
  borderRadius: 5,
  backgroundColor: 'rgba(0,0,0,0.2)',

  '& .MuiSvgIcon-root': {
    width: 14,
    height: 14,

    '& path': {
      fill: '#fff',
    },
  },
}));

export const Sidebar = styled(Grid)(({ theme }) => ({
  width: '11%',
  height: 330,
  background: COLORS.CARD_LIGHT_GRAY,
  borderTopRightRadius: 5,
  flexDirection: 'column',
  gap: 15,
  padding: '10px 0',
  alignItems: 'center',
  position: 'relative',
}));

export const SidebarButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
}));

export const CardFooter = styled(Grid)(({ theme }) => ({
  padding: '8px 10px',
  position: 'relative',

  '& .MuiTypography-root': {
    lineHeight: 1.4,
  },
}));

export const MoreIcon = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',

  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,

    '& path': {
      fill: COLORS.MAIN_GRAY,
    },
  },
}));

export const SelectCardContainer = styled(Grid)(({ theme }) => ({
  maxWidth: 270,
  width: '100%',
  height: 383,
  background: COLORS.CARD_DARK_GRAY,
  borderRadius: 5,
}));

export const SelectTalentImageContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: 330,
  position: 'relative',
}));

export const SelectTalentImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 330,
  objectFit: 'cover',
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
}));

export const SelectCheckboxContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  '& .MuiFormControlLabel-root': {
    margin: '0 -9px 0 0 !important',
  },
}));

export const SelectCustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '& span': {
    height: 24,
    width: 24,

    '&.MuiTouchRipple-root': {
      height: 40,
      width: 40,
    },
    '&.Mui-checked span > span': {
      background: COLORS.PRIMARY,
    },
    '& > span': {
      background: 'rgba(0, 0, 0, 0.5)',
      borderColor: COLORS.PRIMARY,
    },

    '& .MuiSvgIcon-root': {
      height: '16px !important',
      width: '16px !important',
      left: '13px !important',
      '& path': {
        fill: '#fff',
      },
    },
  },
}));

export const SelectCardFooter = styled(Grid)(({ theme }) => ({
  padding: '8px 10px',
  position: 'relative',

  '& .MuiTypography-root': {
    lineHeight: 1.4,
  },
}));

export const ExpandedOption = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  gap: 20,
}));

export const ExpandedInfoContainer = styled(Grid)(({ theme }) => ({
  width: '45.5%',
  padding: 20,
  gap: 15,
  flexDirection: 'column',
}));

export const ExpandedInfo = styled(Grid)(({ theme }) => ({}));

export const ExpandedTwoColumnInfo = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
}));

export const ExpandedIconsContainer = styled(Grid)(({ theme }) => ({
  gap: 20,
  marginTop: -5,
}));

export const ExpandedIcon = styled(Grid)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 17,
  },
}));
