import { ROUTES } from 'shared/constants/ROUTES';

export const navigateToRoute = {
  toSetting: (tab: string) => `${ROUTES.AGENCY.SETTINGS}`.replace(':tab', tab),
  toTalent: (tab: string) => `${ROUTES.AGENCY.TALENT}`.replace(':tab', tab),
  toProjectList: (tab: string) => `${ROUTES.AGENCY.PROJECTS.MY_PROJECTS}`.replace(':tab', tab),
  toProjectTab: (projectId: string, tab: string) =>
    `${ROUTES.AGENCY.PROJECTS.PROJECT_TAB_DETAIL}`.replace(':projectId', projectId).replace(':tab', tab),
  toProjectChildTab: (projectId: string, tab: string, childTab: string) =>
    `${ROUTES.AGENCY.PROJECTS.PROJECT_CHILDTAB_DETAIL}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':childTab', childTab),
  toProjectChildTabDetailTab: (projectId: string, tab: string, projectDetailId: string, childTab: string) =>
    `${ROUTES.AGENCY.PROJECTS.PROJECT_DETAIL_CHILDTAB_DETAIL}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':projectDetailId', projectDetailId)
      .replace(':childTab', childTab),
  toEditProject: (projectId: string) => `${ROUTES.AGENCY.PROJECTS.EDIT_PROJECT}`.replace(':projectId', projectId),
  toCreateProject: (type: string) => `${ROUTES.AGENCY.PROJECTS.CREATE_PROJECT}`.replace(':type', type),
  toCreateRole: (projectId: string) => `${ROUTES.AGENCY.PROJECTS.ROLE.CREATE_ROLE}`.replace(':projectId', projectId),
  toCreateStRole: (projectId: string) =>
    `${ROUTES.AGENCY.PROJECTS.ROLE.CREATE_ST_ROLE}`.replace(':projectId', projectId),
  toEditRole: (projectId: string, roleId: string) =>
    `${ROUTES.AGENCY.PROJECTS.ROLE.EDIT_ROLE}`.replace(':projectId', projectId).replace(':roleId', roleId),
  toWorksheet: (tab: string) => `${ROUTES.AGENCY.WORKSHEET.WORKSHEET}`.replace(':tab', tab),
  toWorksheetChildTab: (tab: string, childTab: string) =>
    `${ROUTES.AGENCY.WORKSHEET.WORKSHEET_CHILDTAB}`.replace(':tab', tab).replace(':childTab', childTab),
};
