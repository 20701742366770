import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    request__videoPlayerContainer: {
      width: 180,
      height: 120,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        width: 150,
        height: 110,
      },
    },
    request__videoPlayerThumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    request__videoPlayerButton: {
      position: 'absolute',
      right: 5,
      bottom: 5,

      zIndex: 999,
    },
    request__imageContainer: {
      width: 80,
      height: '100%',
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    request__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);
