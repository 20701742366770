import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.customBlack.dark,
    },
    skillsListContainer: {
      marginTop: theme.spacing(2),
    },
    skillListHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    skillListLegend: {
      display: 'flex',
      alignItems: 'center',
      gap: 30,
      paddingRight: theme.spacing(2),
    },
    skillListLegend__item: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    legendCircle: {
      height: 12,
      width: 12,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
    skillsListItemsContainer: {
      marginTop: theme.spacing(2),
    },
    skillsList__item: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(4),
      },
    },
    skillList__playerItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.customBlack.light,
      padding: theme.spacing(0.5, 2),
      paddingRight: theme.spacing(1),
      borderRadius: 6,
      position: 'relative',

      '&::before': {
        content: '""',
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        width: 4,
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
      },
    },
    skillList__playerItem__inter: {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    skillList__playerItem__expert: {
      '&::before': {
        backgroundImage: 'linear-gradient(to bottom, #209378, #0b6353)',
      },
    },
    skillList__playerItem__basic: {
      '&::before': {
        backgroundColor: '#b4c5df',
      },
    },
  }),
);
