import {
  CalendarIconTwoTone,
  CompanyIcon,
  ContactsIcon,
  DashboardIcon,
  DocumentFileIcon,
  MessagesIcon,
  ProjectsIcon,
  TaskIcon,
  UsersListIcon,
} from '@room-match/shared-ui-components';
import React from 'react';
import { navigateToRoute } from 'shared/utils/navigateToRoute';

import { ROUTES } from './ROUTES';

interface ISidebarItems {
  name: string;
  icon: React.ReactNode;
  link?: string;
}
export const SIDEBAR_ITEMS: ISidebarItems[] = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    link: ROUTES.AGENCY.DASHBOARD,
  },
  {
    name: 'Projects',
    icon: <ProjectsIcon />,
    link: navigateToRoute.toProjectList('castings'),
  },
  {
    name: 'Worksheet',
    icon: <TaskIcon fill="#ffffff" />,
    link: navigateToRoute.toWorksheetChildTab('action_items', 'all'),
  },
  // {
  //   name: 'Schedules Sessions',
  //   icon: <DocumentFileIcon />,
  //   link: ROUTES.CASTING.SCHEDULES_SESSIONS,
  // },
  // {
  //   name: 'Messages',
  //   icon: <MessagesIcon />,
  //   link: ROUTES.CASTING.MESSAGES,
  // },
  // {
  //   name: 'Companies',
  //   icon: <CompanyIcon />,
  //   link: ROUTES.CASTING.COMPANIES,
  // },
  {
    name: 'Talent',
    icon: <UsersListIcon />,
    link: navigateToRoute.toTalent('talent_library'),
  },
  // {
  //   name: 'Contacts',
  //   icon: <ContactsIcon />,
  //   link: `${ROUTES.CASTING.CONTACTS}/all`,
  // },
];
